import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';

interface ListingFeeRowMenuProps {
  row: { id?: number };
}

const ListingFeeRowMenu: FunctionComponent<ListingFeeRowMenuProps> = (props: ListingFeeRowMenuProps) => {
  const { row } = props;

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={() => console.log(row)}>See Notes</MenuItem>
      <MenuItem onClick={() => null}>Edit Goal</MenuItem>
      <MenuItem onClick={() => null}>Destroy Goal</MenuItem>
    </CustomMaterialMenu>
  );
};

export default ListingFeeRowMenu;
