import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, TextField, Select } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { _translateEach } from '../utils/translation.utils';
import { SelectOption } from '../common.model';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      backgroundColor: 'white',
    },
    formControl: {
      width: '100%',
      background: 'white',
      height: '38px',
    },
  }),
);

const StyledSelectFormControl = styled(FormControl)`
  .MuiSelect-select {
    padding: 10px;
  }
  .MuiInputLabel-root {
    margin-top: -6px;
  }
  .MuiInputLabel-shrink {
    margin-top: 0;
  }
`;

export interface FieldProps {
  label: string;
  selector: string;
  type: string;
  options?: SelectOption[];
}

export interface FilterProps {
  label: string;
  selector: string;
  type: string;
  options?: SelectOption[];
}

interface SearchBarProps {
  fields?: FieldProps[];
  filters?: FilterProps[];
  store: any;
}

const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
  const { filters, fields, store } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { filterValues } = useStoreObservable(store);
  const classes = useStyles();

  const handleClick = () => setOpen(!open);
  const handleSearch = (event, field: string, type = 'string') => {
    store.liveSearch(field, event.target.value.toLowerCase(), type);
  };

  const handleFilterInputChange = (event, field: string) => {
    store.setFilterValues({ ...filterValues, [field]: event.target.value });
  };

  const formattedFilterParams = () => {
    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  };

  const handleFilter = () => {
    store.fetchRecords(formattedFilterParams());
  };

  const t = _translateEach({
    hide: 'searchBar.filter.hide',
    show: 'searchBar.filter.show',
  });

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <form noValidate autoComplete="off">
          <Grid container spacing={1} alignItems="center">
            {fields.map((field) => (
              <Grid item xs={2}>
                {field.options ? (
                  <StyledSelectFormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">{field.label}</InputLabel>
                    <Select
                      variant="outlined"
                      placeholder={field.label}
                      label={field.label}
                      type={field.type}
                      onChange={(e) => handleSearch(e, field.selector, 'select')}
                    >
                      {field.options.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </StyledSelectFormControl>
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder={field.label}
                    label={field.type === 'date' ? field.label : null}
                    type={field.type}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleSearch(e, field.selector)}
                    InputProps={{
                      className: classes.inputRoot,
                      startAdornment: field.type === 'text' && (
                        <InputAdornment position="start">
                          <SearchIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Grid>
            ))}
            {filters && filters.length > 0 && (
              <Grid item>
                <Button
                  onClick={handleClick}
                  color={open ? 'secondary' : 'primary'}
                  startIcon={open ? <VisibilityOffIcon /> : <FilterListIcon />}
                >
                  {open ? t.hide : t.show}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>

      {open && (
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            {filters.map((filter) => (
              <Grid item xs={2}>
                {filter.options ? (
                  <StyledSelectFormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">{filter.label}</InputLabel>
                    <Select
                      variant="outlined"
                      placeholder={filter.label}
                      label={filter.label}
                      type={filter.type}
                      value={filterValues[filter.selector]}
                      onChange={(e) => handleFilterInputChange(e, filter.selector)}
                    >
                      {filter.options.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </StyledSelectFormControl>
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={filter.type === 'date' ? filter.label : null}
                    type={filter.type}
                    value={filterValues[filter.selector]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={filter.label}
                    onBlur={(e) => handleFilterInputChange(e, filter.selector)}
                    onChange={(e) => handleFilterInputChange(e, filter.selector)}
                    InputProps={{
                      className: classes.inputRoot,
                    }}
                  />
                )}
              </Grid>
            ))}
            <Grid item>
              <Button variant="contained" color="primary" disableElevation onClick={(e) => handleFilter()}>
                Apply
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="default" disableElevation onClick={() => store.resetFilterValues()}>
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SearchBar;
