import React, { FunctionComponent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../student.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
});

const NewStudentForm: FunctionComponent = () => {
  const classes = useStyles();

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formValue: formValues,
    gradeLevelChoices,
    locationChoices,
    schoolChoices,
  } = useStoreObservable<StudentState>(studentStore);

  const t = _translateEach({
    firstName: 'students.form.firstName',
    lastName: 'students.form.lastName',
    school: 'students.form.school',
    teacherName: 'students.form.teacherName',
    roomNumber: 'students.form.roomNumber',
    dateOfBirth: 'students.form.dateOfBirth',
    preferredLocation: 'students.form.preferredLocation',
    gradeLevel: 'students.form.gradeLevel',
    defaultSelectOption: 'selectOption.defaultSelectOption',
  });

  const handleFormValueChange = (key: string, value: string) => {
    studentStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    studentStore.fetchChoices();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => studentStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.firstName}
        errorMessage={errorValues?.first_name}
        value={formValues?.first_name}
        valueChanged={(value) => handleFormValueChange('first_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.lastName}
        errorMessage={errorValues?.last_name}
        value={formValues?.last_name}
        valueChanged={(value) => handleFormValueChange('last_name', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.school}
        errorMessage={errorValues?.payer_id}
        value={formValues?.payer_id}
        choices={schoolChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('payer_id', e.target.value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.teacherName}
        errorMessage={errorValues?.teacher}
        value={formValues?.teacher}
        valueChanged={(value) => handleFormValueChange('teacher', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.roomNumber}
        errorMessage={errorValues?.room_number}
        value={formValues?.room_number}
        valueChanged={(value) => handleFormValueChange('room_number', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label={t.dateOfBirth}
        errorMessage={errorValues?.birthdate}
        value={formValues?.birthdate}
        valueChanged={(value) => handleFormValueChange('birthdate', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.preferredLocation}
        errorMessage={errorValues?.location_id}
        value={formValues?.location_id}
        choices={locationChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('location_id', e.target.value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.gradeLevel}
        errorMessage={errorValues?.grade_level_id}
        value={formValues?.grade_level_id}
        choices={gradeLevelChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('grade_level_id', e.target.value)}
      />
    </form>
  );
};

export default NewStudentForm;
