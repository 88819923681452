import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserDetail from '../UserDetail';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { UserState } from '../../user.store';
import { userStore } from '../../../shared/singletons';
import { UserRouteParams } from '../../model';

export type UserShowPageProps = Record<string, unknown>;

const UserShowPage: FunctionComponent<UserShowPageProps> = () => {
  const userState = useStoreObservable<UserState>(userStore);
  const { user, userLoading } = userState;
  const { userId } = useParams<UserRouteParams>();

  useEffect(() => {
    userStore.fetchUser(userId);
  }, [userId]);

  return <UserDetail user={user} userLoading={userLoading} />;
};

export default UserShowPage;
