import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Renewal } from '../model';

interface RenewalInformationProps {
  renewal: Renewal;
  renewalLoading: boolean;
}

const RenewalInformation: FunctionComponent<RenewalInformationProps> = (props) => {
  const { renewal, renewalLoading } = props;

  return renewalLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Renewal Information
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label="Status" value={renewal?.status} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Type" value={renewal?.type} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Stage" value={renewal?.stage} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Renewal Date" value={renewal?.renewal_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Days Until Due" value={renewal?.timeline} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Owner" value={renewal?.owner} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Supervisors" value={renewal?.supervisors} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RenewalInformation;
