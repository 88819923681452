import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from './modules/shared/contexts/userContext';
import AppointmentsListPage from './modules/appointments/components/pages/AppointmentsListPage';
import AppointmentDetailPage from './modules/appointments/components/pages/AppointmentDetailPage';
import NewSessionNotePage from './modules/sessionNotes/components/pages/NewSessionNotePage';
import RenewalsListPage from './modules/renewals/components/pages/RenewalsListPage';
import RenewalDetailPage from './modules/renewals/components/pages/RenewalDetailPage';
import SchoolsListPage from './modules/schools/components/pages/SchoolsListPage';
import SchoolDetailPage from './modules/schools/components/pages/SchoolDetailPage';
import ServicesListPage from './modules/services/components/pages/ServicesListPage';
import ServiceDetailPage from './modules/services/components/pages/ServiceDetailPage';
import StudentsListPage from './modules/students/components/pages/StudentsListPage';
import StudentDetailPage from './modules/students/components/pages/StudentDetailPage';
import UsersListPage from './modules/users/components/pages/UsersListPage';
import UserDetailPage from './modules/users/components/pages/UserDetailPage';
import Account from './modules/account';
import Login from './modules/login';
import GradeLevelsListPage from './modules/admin/gradeLevels/components/GradeLevelsListPage';
import ListingServices from './modules/admin/listingServices';
import Locations from './modules/admin/locations';
import RenewalStages from './modules/admin/renewalStages';
import RenewalTypes from './modules/admin/renewalTypes';
import Roles from './modules/admin/roles';
import ReferralStagesListPage from './modules/admin/referralStages/components/pages/ReferralStagesListPage';

const App: React.FC = () => {
  const { user } = useContext(UserContext);

  const ProtectedRoute = ({ component: Component, path, exact }) => (
    <Route
      exact
      path={path}
      render={(props) => (user.id ? <Component props={props} component /> : <Redirect to="/login" />)}
    />
  );

  const AuthRoute = ({ component: Component, path }) => (
    <Route
      path={path}
      render={(props) => (user.id === undefined ? <Component props={props} component /> : <Redirect to="/students" />)}
    />
  );

  return user ? (
    <Router forceRefresh>
      <Switch>
        <ProtectedRoute
          exact
          path="/students/:studentId/appointments/create"
          component={() => <AppointmentsListPage />}
        />
        <ProtectedRoute
          exact
          path="/students/:studentId/appointments/:appointmentId/session_notes/new"
          component={() => <NewSessionNotePage />}
        />
        <ProtectedRoute
          exact
          path="/students/:studentId/appointments/:appointmentId/:tab"
          component={() => <AppointmentDetailPage />}
        />
        <ProtectedRoute
          exact
          path="/students/:studentId/appointments/:appointmentId"
          component={() => <AppointmentDetailPage />}
        />
        <ProtectedRoute exact path="/appointments" component={() => <AppointmentsListPage />} />

        <ProtectedRoute exact path="/students/:studentId/services/create" component={() => <ServicesListPage />} />
        <ProtectedRoute
          exact
          path="/students/:studentId/services/:serviceId/:tab"
          component={() => <ServiceDetailPage />}
        />
        <ProtectedRoute exact path="/students/:studentId/services/:serviceId" component={() => <ServiceDetailPage />} />
        <ProtectedRoute exact path="/services" component={() => <ServicesListPage />} />

        <ProtectedRoute exact path="/students/:studentId/renewals/create" component={() => <RenewalsListPage />} />
        <ProtectedRoute exact path="/students/:studentId/renewals/:renewalId" component={() => <RenewalDetailPage />} />
        <ProtectedRoute exact path="/renewals" component={() => <RenewalsListPage />} />

        <ProtectedRoute exact path="/schools/:schoolId/:tab" component={() => <SchoolDetailPage />} />
        <ProtectedRoute exact path="/schools/:schoolId" component={() => <SchoolDetailPage />} />
        <ProtectedRoute exact path="/schools" component={() => <SchoolsListPage />} />

        <ProtectedRoute exact path="/users/:users" component={() => <UserDetailPage />} />
        <ProtectedRoute exact path="/users" component={() => <UsersListPage />} />

        <ProtectedRoute exact path="/students/:studentId/:tab" component={() => <StudentDetailPage />} />
        <ProtectedRoute exact path="/students/:studentId" component={() => <StudentDetailPage />} />
        <ProtectedRoute exact path="/students" component={() => <StudentsListPage />} />

        <ProtectedRoute exact path="/grade_levels" component={() => <GradeLevelsListPage />} />

        <ProtectedRoute exact path="/listing_services" component={() => <ListingServices />} />

        <ProtectedRoute exact path="/locations" component={() => <Locations />} />

        <ProtectedRoute exact path="/referral_stages" component={() => <ReferralStagesListPage />} />
        <ProtectedRoute exact path="/renewal_stages" component={() => <RenewalStages />} />

        <ProtectedRoute exact path="/renewal_types" component={() => <RenewalTypes />} />

        <ProtectedRoute exact path="/roles" component={() => <Roles />} />

        <ProtectedRoute exact path="/account" component={() => <Account />} />

        <AuthRoute path="/login" component={() => <Login />} />

        <Route exact path="/" component={() => <StudentsListPage />} />
      </Switch>
    </Router>
  ) : (
    <div />
  );
};

export default App;
