import React, { FunctionComponent } from 'react';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import { BaseModalProps } from '../../../shared/components/modal/modal.model';
import TextInput from '../../../shared/components/form/TextInput';
import { _translateEach } from '../../../shared/utils/translation.utils';

interface AddReferralStageModalProps extends BaseModalProps {
  onSubmit: (stage: string) => void;
}

const AddReferralStageModal: FunctionComponent<AddReferralStageModalProps> = (props) => {
  const { isOpen, openStatusChanged, onSubmit } = props;
  const [newStageName, setNewStageName] = React.useState<string>(null);

  const handleSubmit = (): void => {
    if (!newStageName) return;
    onSubmit(newStageName);
  };

  const t = _translateEach({
    title: 'referralStageList.createModal.title',
    confirmLabel: 'referralStageList.createModal.confirmLabel',
    inputPlaceholder: 'referralStageList.createModal.inputPlaceholder',
  });

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title={t.title}
      confirmLabel={t.confirmLabel}
      openStatusChanged={openStatusChanged}
      onConfirm={handleSubmit}
    >
      <TextInput value={newStageName} label={t.inputPlaceholder} valueChanged={(value) => setNewStageName(value)} />
    </ConfirmCancelModal>
  );
};

export default AddReferralStageModal;
