import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { goalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { GoalState } from '../goal.store';

const ReviewNewGoal: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<GoalState>(goalStore);

  const t = _translateEach({
    title: 'goals.reviewGoal.title',
  });

  return (
    <div>
      <Typography variant="h2">{t.title}</Typography>
      {Object.keys(formValues).map((keyName, _index: number) => (
        <Typography key={keyName}>
          <strong>{`${keyName}:`}</strong>
          {formValues[keyName]}
        </Typography>
      ))}
    </div>
  );
};

export default ReviewNewGoal;
