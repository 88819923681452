import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudentDetail from '../StudentDetail';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { StudentState } from '../../student.store';
import { studentStore } from '../../../shared/singletons';
import { StudentRouteParams } from '../../model';

export type StudentDetailPageProps = Record<string, unknown>;

const StudentDetailPage: FunctionComponent<StudentDetailPageProps> = () => {
  const studentState = useStoreObservable<StudentState>(studentStore);
  const { student, studentLoading } = studentState;
  const { studentId, tab } = useParams<StudentRouteParams>();

  useEffect(() => {
    studentStore.fetchStudent(studentId);
  }, [studentId]);

  return <StudentDetail student={student} tab={tab} studentLoading={studentLoading} />;
};

export default StudentDetailPage;
