import React, { FunctionComponent, useEffect } from 'react';
import GradeLevelsList from './GradeLevelsList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { GradeLevelState } from '../gradeLevel.store';
import { gradeLevelStore } from '../../../shared/singletons';

export type GradeLevelsListPageProps = Record<string, unknown>;

const GradeLevelsListPage: FunctionComponent<GradeLevelsListPageProps> = () => {
  const gradeLevelState = useStoreObservable<GradeLevelState>(gradeLevelStore);
  const { records, isLoading } = gradeLevelState;

  useEffect(() => {
    gradeLevelStore.fetchRecords();
  }, []);

  return <GradeLevelsList records={records} isLoading={isLoading} />;
};

export default GradeLevelsListPage;
