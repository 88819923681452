import React, { FunctionComponent, ChangeEvent } from 'react';
import { Grid, Button, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import AppointmentDetailsTab from './AppointmentDetailsTab';
import AppointmentServiceDetails from './AppointmentServiceDetails';
import GoalsList from '../../goals/components/GoalsList';
import SessionNotesList from '../../sessionNotes/components/SessionNotesList';
import { Appointment } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface AppointmentDetailProps {
  appointment: Appointment;
  appointmentLoading: boolean;
  tab: string;
}

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const AppointmentDetail: FunctionComponent<AppointmentDetailProps> = (props: AppointmentDetailProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { appointment, appointmentLoading, tab } = props;
  const [value, setValue] = React.useState(0);
  const links: BreadcrumbLink[] = [
    {
      href: '/appointments',
      text: 'APPOINTMENTS',
    },
    {
      href: null,
      text: `${appointment.service_type} ${appointment.formatted_schedule_date} ${appointment.start_time}`,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (tab) {
      setValue(['details', 'service', 'session_notes'].indexOf(tab));
    }
  }, [tab]);

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {`${appointment.service_type} ${appointment.formatted_schedule_date} ${appointment.start_time}`}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {appointment.status === 'incomplete' && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/session_notes/new`)
              }
            >
              Add Session Notes
            </Button>
          )}
        </Grid>
      </Grid>

      <div>
        <TabLabels value={value} onChange={handleChange} aria-label="student tabs">
          <TabLabel label="Details" other={a11yProps(0)} />
          <TabLabel label="Service" other={a11yProps(1)} />
          <TabLabel label="Session Note" other={a11yProps(2)} />
        </TabLabels>
        <TabPanel value={value} index={0} className={classes.root}>
          <AppointmentDetailsTab appointment={appointment} appointmentLoading={appointmentLoading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AppointmentServiceDetails appointment={appointment} appointmentLoading={appointmentLoading} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GoalsList
            goals={appointment.goals}
            serviceId={appointment.service?.id}
            isLoading={appointmentLoading}
            showCreateButton={false}
          />
          <br />
          <SessionNotesList sessionNotes={appointment.session_notes} isLoading={appointmentLoading} />
        </TabPanel>
      </div>
    </div>
  );
};

export default AppointmentDetail;
