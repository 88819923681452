import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';

interface ReferralStageRowMenuProps {
  row: { id?: number };
}

const ReferralStageRowMenu: FunctionComponent<ReferralStageRowMenuProps> = (props: ReferralStageRowMenuProps) => {
  const { row } = props;

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={() => console.log(row)}>Edit Referral Stage</MenuItem>
      <MenuItem onClick={() => null}>Destroy Referral Stage</MenuItem>
    </CustomMaterialMenu>
  );
};

export default ReferralStageRowMenu;
