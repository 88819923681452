import React from 'react';

export type InputElementEvent = React.ChangeEvent<HTMLInputElement>;

export interface HistoryItem {
  formatted_before_name?: string;
  formatted_after_name?: string;
  date_modified?: string;
  status_from?: string;
  status_to?: string;
  phase_from?: string;
  phase_to?: string;
  user?: string;
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface ErrorValues {
  data: {
    key: string;
    value: string | number;
  };
}

export enum ComponentTestId {
  // keep in alphabetical order
  ConfirmCancelModal = 'confirm-cancel-modal',
  ConfirmCancelModalContent = 'confirm-cancel-modal-content',
}
