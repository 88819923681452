import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import CommunicationFormModal from './CommunicationFormModal';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Communication } from '../model';
import { communicationStore } from '../../shared/singletons';
import { _translateEach } from '../../shared/utils/translation.utils';

interface CommunicationsListProps {
  communications: Communication[];
  isLoading: boolean;
  parentType: string;
  parentId: string;
  showCreateButton?: boolean;
}

const useStyles = makeStyles({
  content: {
    lineHeight: '1.4em',
  },
  commentSpacer: {
    margin: '15px 0',
  },
});

const CommunicationsList: FunctionComponent<CommunicationsListProps> = (props) => {
  const { communications, isLoading, parentType, parentId, showCreateButton = true } = props;
  const classes = useStyles();

  const t = _translateEach({
    createButton: 'communicationsList.createButton',
    title: 'communicationsList.title',
  });

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CommunicationFormModal parentType={parentType} parentId={parentId} />
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h2" component="h2">
              {t.title}
            </Typography>
          </Grid>
          <Grid item>
            {showCreateButton && (
              <Button variant="contained" color="primary" onClick={() => communicationStore.setModalOpen(true)}>
                {t.createButton}
              </Button>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {communications?.map((communication: Communication, _index: number) => (
              <div key={communication.id}>
                {console.log(communication)}
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <strong>{`${communication.contact_method} - ${communication.contact_name}`}</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <strong>Date Created: </strong>
                      {communication.date_created}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <strong>Created By: </strong>
                      {communication.created_by}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <strong>Contact Reason: </strong>
                      {communication.contact_reason}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="subtitle1" className={classes.content}>
                      {communication.content}
                    </Typography>
                  </Grid>
                </Grid>
                <hr className={classes.commentSpacer} />
              </div>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CommunicationsList;
