import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Attachment } from '../model';
// import AttachmentRowMenu from './AttachmentRowMenu';

const columns: IDataTableColumn<Attachment>[] = [
  {
    name: 'FILE URL',
    selector: 'file_name',
    sortable: true,
    cell: (row) => (
      <a target="_blank" rel="noopener noreferrer" href={row.file_url}>
        Click to open file
      </a>
    ),
  },
  {
    name: 'name',
    selector: 'file_name',
    sortable: true,
  },
  {
    name: 'DATE CREATED',
    selector: 'date_created',
    sortable: true,
  },
  // {
  //   cell: (row) => <AttachmentRowMenu row={row} />,
  //   name: 'ACTIONS',
  //   allowOverflow: true,
  //   button: true,
  //   selector: 'actions',
  // },
];

interface AttachmentsListProps {
  attachments: Attachment[];
  isLoading: boolean;
}

const AttachmentsList: FunctionComponent<AttachmentsListProps> = (props) => {
  const { attachments, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Attachments
        </Typography>
        <DataTable columns={columns} noHeader data={attachments} striped highlightOnHover pointerOnHover />
      </CardContent>
      <CardActions>
        <Button size="small">Show Archives</Button>
      </CardActions>
    </Card>
  );
};

export default AttachmentsList;
