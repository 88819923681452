import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SchoolDetail from '../SchoolDetail';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../../school.store';
import { schoolStore } from '../../../shared/singletons';
import { SchoolRouteParams } from '../../model';

export type SchoolShowPageProps = Record<string, unknown>;

const SchoolShowPage: FunctionComponent<SchoolShowPageProps> = () => {
  const schoolState = useStoreObservable<SchoolState>(schoolStore);
  const { school, schoolLoading } = schoolState;
  const { schoolId, tab } = useParams<SchoolRouteParams>();

  useEffect(() => {
    schoolStore.fetchSchool(schoolId);
  }, [schoolId]);

  return <SchoolDetail school={school} tab={tab} schoolLoading={schoolLoading} />;
};

export default SchoolShowPage;
