import React, { FunctionComponent, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button, { ButtonFlavor } from '../shared/components/Button';
import TextInput from '../shared/components/form/TextInput';
import { UserContext } from '../shared/contexts/userContext';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const LoginForm: FunctionComponent = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(UserContext);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleLogin}>
      <TextInput
        styleOverrides={classes.textInput}
        label="Email"
        value={email}
        valueChanged={(value: string) => setEmail(value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="password"
        label="Password"
        value={password}
        valueChanged={(value: string) => setPassword(value)}
      />
      <Grid container spacing={3}>
        <Grid item>
          <Button text="Login" disabled={false} flavor={ButtonFlavor.Primary} handleClick={(e) => handleLogin(e)} />
        </Grid>
        <Grid item>
          <Button
            text="Register"
            disabled={false}
            flavor={ButtonFlavor.Secondary}
            handleClick={() => console.log('Click')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
