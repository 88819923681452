import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FilledInput, FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { InputElementEvent } from '../../common.model';

interface TextInputProps {
  value: string;
  label: string;
  onChange?: (element: React.ChangeEvent) => void;
  valueChanged?: (value: string) => void;
  styleOverrides?: string;
  error?: boolean;
  errorMessage?: string;
  isFilled?: boolean;
  shrinkLabel?: boolean;
  multiline?: boolean;
  type?: string;
  rows?: number;
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    height: '30px',
  },
  helperText: {
    fontSize: '12px',
  },
}));

export default function TextInput(props: TextInputProps) {
  const {
    value,
    label,
    onChange,
    valueChanged,
    styleOverrides,
    errorMessage,
    type,
    shrinkLabel,
    isFilled = true,
    multiline,
    rows,
  } = props;

  const classes = useStyles();

  const handleChange = (event: InputElementEvent): void => {
    if (onChange) {
      onChange(event);
    }
    if (valueChanged) {
      valueChanged(event.target.value);
    }
  };

  const formattedInput = isFilled ? (
    <FilledInput multiline={multiline} rows={rows} onChange={handleChange} type={type} value={value} />
  ) : (
    <Input onChange={handleChange} type={type} value={value} />
  );

  return (
    <FormControl variant="filled" error={errorMessage?.length > 0} className={`${styleOverrides} ${classes.textField}`}>
      <InputLabel shrink={shrinkLabel} htmlFor="{label}">
        {label}
      </InputLabel>
      {formattedInput}
      {errorMessage && <FormHelperText className={classes.helperText}>{`${label} ${errorMessage}`}</FormHelperText>}
    </FormControl>
  );
}
