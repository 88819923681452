import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { renewalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../renewal.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const RenewalForm: FunctionComponent = () => {
  const classes = useStyles();

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formValue: formValues,
    formStudentId: studentId,
    renewalTypeChoices,
    renewalStageChoices,
    supervisorChoices,
  } = useStoreObservable<RenewalState>(renewalStore);

  const handleFormValueChange = (key: string, value: string) => {
    renewalStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    renewalStore.fetchChoices(studentId);
  }, [studentId]);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => renewalStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Renewal Type"
        errorMessage={errorValues?.renewal_type}
        value={formValues?.renewal_type_id}
        valueChanged={(value: string) => handleFormValueChange('renewal_type_id', value)}
        choices={renewalTypeChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label="Renewal Date"
        errorMessage={errorValues?.renewal_date}
        value={formValues?.renewal_date}
        valueChanged={(value: string) => handleFormValueChange('renewal_date', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Renewal Stage"
        errorMessage={errorValues?.renewal_stage}
        value={formValues?.renewal_stage_id}
        valueChanged={(value: string) => handleFormValueChange('renewal_stage_id', value)}
        choices={renewalStageChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Renewal Supervisor"
        errorMessage={errorValues?.user}
        value={formValues?.user_id}
        valueChanged={(value: string) => handleFormValueChange('user_id', value)}
        choices={supervisorChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
    </form>
  );
};

export default RenewalForm;
