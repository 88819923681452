import React, { FunctionComponent, useEffect } from 'react';
import StudentsList from '../StudentsList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { StudentState } from '../../student.store';
import { studentStore } from '../../../shared/singletons';

export type StudentsListPageProps = Record<string, unknown>;

const StudentsListPage: FunctionComponent<StudentsListPageProps> = () => {
  const studentState = useStoreObservable<StudentState>(studentStore);
  const { filteredRecords, records, recordsLoading } = studentState;

  useEffect(() => {
    studentStore.fetchRecords();
  }, []);

  return <StudentsList records={filteredRecords || records} recordsLoading={recordsLoading} showFilterBar />;
};

export default StudentsListPage;
