import React, { useState, createContext, useEffect } from 'react';
import { axios } from '../singletons';

const UserContext = createContext({
  user: undefined,
  setUser: undefined,
  login: undefined,
  logout: undefined,
  currentUserHasRole: undefined,
});
const UserContextConsumer = UserContext.Consumer;
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  const login = async (email: string, password: string) => {
    try {
      const result = await axios.post(`sessions`, { email, password });
      setUser(result.data.user);
    } catch {
      setUser({});
    }
  };

  const logout = async () => {
    try {
      await axios.get(`sessions/sign_out`);
      setUser({});
    } catch {
      setUser({});
    }
  };

  const currentUserHasRole = (role: string) => {
    if (user?.roles?.find((user_role: string) => user_role === role)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        const result = await axios(`sessions`);
        setUser(result.data.user || {});
      } catch (error) {
        setUser({});
      }
    }

    if (user === undefined) {
      fetchUser();
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, currentUserHasRole }}>{children}</UserContext.Provider>
  );
};
export { UserContextProvider, UserContextConsumer, UserContext };
