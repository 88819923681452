import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../student.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
});

const StudentAddressForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible: showErrorAlert, formErrors: errorValues, formValue: formValues } = useStoreObservable<
    StudentState
  >(studentStore);

  const t = _translateEach({
    title: 'students.form.addressTitle',
    address1: 'students.form.address1',
    address2: 'students.form.address2',
    state: 'students.form.state',
    city: 'students.form.city',
    zip: 'students.form.zip',
  });

  const handleFormValueChange = (key: string, value: string) => {
    studentStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    studentStore.fetchChoices();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => studentStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">{t.title}</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.address1}
        errorMessage={errorValues?.address1}
        value={formValues.address1}
        valueChanged={(value) => handleFormValueChange('address_line1', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.address2}
        errorMessage={errorValues?.address2}
        value={formValues.address2}
        valueChanged={(value) => handleFormValueChange('address_line2', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.city}
        errorMessage={errorValues?.city}
        value={formValues.city}
        valueChanged={(value) => handleFormValueChange('address_city', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.state}
        errorMessage={errorValues?.state}
        value={formValues.state}
        valueChanged={(value) => handleFormValueChange('address_state', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.zip}
        errorMessage={errorValues?.zip}
        value={formValues.zip}
        valueChanged={(value) => handleFormValueChange('address_zip', value)}
      />
    </form>
  );
};

export default StudentAddressForm;
