import { AlertStore } from './alert.store';
import { AppointmentStore } from '../appointments/appointment.store';
import { AttachmentStore } from '../attachments/attachment.store';
import { CommentStore } from '../comments/comment.store';
import { CommunicationStore } from '../communications/communication.store';
import { GoalStore } from '../goals/goal.store';
import { GradeLevelStore } from '../admin/gradeLevels/gradeLevel.store';
import { ReferralStagesStore } from '../admin/referralStages/referralStages.store';
import { RenewalStore } from '../renewals/renewal.store';
import { SchoolStore } from '../schools/school.store';
import { ServiceStore } from '../services/service.store';
import { SessionNoteStore } from '../sessionNotes/sessionNote.store';
import { StudentStore } from '../students/student.store';
import { UserStore } from '../users/user.store';
import Axios from './utils/axios.utils';

// instantiate store classes here (and only here)
export const alertStore = new AlertStore();
export const appointmentStore = new AppointmentStore();
export const attachmentStore = new AttachmentStore();
export const commentStore = new CommentStore();
export const communicationStore = new CommunicationStore();
export const goalStore = new GoalStore();
export const gradeLevelStore = new GradeLevelStore();
export const referralStagesStore = new ReferralStagesStore();
export const renewalStore = new RenewalStore();
export const schoolStore = new SchoolStore();
export const serviceStore = new ServiceStore();
export const sessionNoteStore = new SessionNoteStore();
export const studentStore = new StudentStore();
export const userStore = new UserStore();

// other services

export const axios = Axios;
