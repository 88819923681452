import React, { memo } from 'react';
import styled from 'styled-components';
import { Cell } from './Cell';
import { getProperty, getConditionalStyle } from './util';

interface TableCellStyleProps {
  column: {
    truncate: string;
    allowOverflow: boolean;
    style: string;
  };
  extendedCellStyle: string;
}

const TableCellStyle = styled(Cell)`
  font-size: ${(props) => props.theme.rows.fontSize};
  font-weight: 400;

  div:first-child {
    white-space: ${(props: TableCellStyleProps) => (props.column.truncate ? 'nowrap' : 'normal')};
    overflow: ${(props) => (props.column.allowOverflow ? 'visible' : 'hidden')};
    text-overflow: ellipsis;
  }

  ${(props) => props.column.style};
  ${(props) => props.extendedCellStyle};
`;

export interface TableCellProps {
  id: string;
  column: any; // TODO
  row: any;
}

const TableCell = memo((props: TableCellProps) => {
  const { id, column, row } = props;
  if (column.omit) {
    return null;
  }

  // apply a tag that TableRow will use to stop event propagation when TableCell is clicked
  const dataTag = column.ignoreRowClick || column.button ? null : '___react-data-table-allow-propagation___';
  const extendedCellStyle = getConditionalStyle(row, column.conditionalCellStyles);

  return (
    <TableCellStyle
      id={id}
      role="cell"
      column={column}
      data-tag={dataTag}
      className="rdt_TableCell"
      extendedCellStyle={extendedCellStyle}
    >
      {!column.cell && <div data-tag={dataTag}>{getProperty(row, column.selector, column.format)}</div>}
      {column.cell && column.cell(row)}
    </TableCellStyle>
  );
});

/*TableCell.propTypes = {
  id: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};*/

export default TableCell;
