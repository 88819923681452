import React, { FunctionComponent } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import UserFormModal from './UserFormModal';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { User } from '../model';
import UserRowMenu from './UserRowMenu';
import { userStore } from '../../shared/singletons';
import SearchBar, { FieldProps, FilterProps } from '../../shared/components/SearchBar';

const columns: IDataTableColumn<User>[] = [
  {
    name: 'USER NAME',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'ROLE',
    selector: 'roles',
    sortable: true,
  },
  {
    name: 'SUPERVISOR',
    selector: 'supervisor',
    sortable: true,
  },
  {
    name: 'SCHOOL',
    selector: 'school',
    sortable: true,
  },
  {
    name: 'EMAIL',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
  },
  {
    cell: (user) => <UserRowMenu user={user} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

const fields: FieldProps[] = [
  {
    selector: 'name',
    label: 'User Name',
    type: 'text',
  },
];

const filters: FilterProps[] = [
  {
    selector: 'ransack[payer_id_eq]',
    label: 'School',
    type: 'text',
  },
  {
    selector: 'ransack[created_by_eq]',
    label: 'Created By',
    type: 'text',
  },
  {
    selector: 'ransack[status_eq]',
    label: 'Status',
    type: 'text',
  },
];

type UsersListProps = { records: User[]; recordsLoading: boolean; showFilterBar: boolean };

const UsersList: FunctionComponent<UsersListProps> = (props) => {
  const { records = [], recordsLoading = false, showFilterBar = false } = props;
  const history = useHistory();

  return recordsLoading ? (
    <Box>
      <Typography variant="h1" component="h1">
        Users
      </Typography>
      <br />
      <LoadingSpinner />
    </Box>
  ) : (
    <Box>
      <UserFormModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Users
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => userStore.setModalOpen(true)}>
            Create New User +
          </Button>
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <SearchBar fields={fields} filters={filters} store={userStore} />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            striped
            onRowClicked={(user: User) => history.push(`/users/${user.id}`)}
            highlightOnHover
            pointerOnHover
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default UsersList;
