import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider, ListItemIcon, MenuItem, Typography, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LayersIcon from '@material-ui/icons/Layers';
import DescriptionIcon from '@material-ui/icons/Description';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { appointmentStore } from '../../shared/singletons';
import { UserContext } from '../../shared/contexts/userContext';
import { Appointment } from '../model';

interface AppointmentlRowMenuProps {
  appointment: Appointment;
}

const useStyles = makeStyles({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
});

const AppointmentRowMenu: FunctionComponent<AppointmentlRowMenuProps> = (props: AppointmentlRowMenuProps) => {
  const { appointment } = props;
  const history = useHistory();
  const classes = useStyles();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const t = _translateEach({
    viewAppointment: 'appointments.rowMenu.viewAppointment',
    viewService: 'appointments.rowMenu.viewService',
    viewSessionNote: 'appointments.rowMenu.viewSessionNote',
    editAppointment: 'appointments.rowMenu.editAppointment',
    deleteAppointment: 'appointments.rowMenu.deleteAppointment',
    addAppointment: 'appointments.rowMenu.addAppointment',
    deleteConfirm: 'appointments.rowMenu.deleteConfirm',
  });

  const handleDestroyFormSubmit = () => {
    appointmentStore.deleteAppointment(appointment.id);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>
      <MenuItem onClick={() => history.push(`/students/${appointment.student_id}/appointments/${appointment.id}`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DateRangeIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewAppointment}</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/service`)}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <LayersIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewService}</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/session_notes`)}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewSessionNote}</Typography>
      </MenuItem>

      <Divider />

      {currentUserHasRole('admin') && (
        <MenuItem onClick={() => appointmentStore.setEditModalOpen(true, appointment.id)}>
          <ListItemIcon className={classes.listItemIcon}>
            <EditIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">{t.editAppointment}</Typography>
        </MenuItem>
      )}

      <MenuItem
        onClick={() =>
          history.push(`/students/${appointment.student_id}/appointments/${appointment.id}/session_notes/new`)
        }
      >
        <ListItemIcon className={classes.listItemIcon}>
          <EditIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">Add Session Notes</Typography>
      </MenuItem>

      <MenuItem onClick={() => appointmentStore.setRescheduleModalOpen(true, appointment.id)}>
        <ListItemIcon className={classes.listItemIcon}>
          <EditIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">Reschedule</Typography>
      </MenuItem>
      <MenuItem onClick={() => appointmentStore.setEditStatusModalOpen(true, appointment.id)}>
        <ListItemIcon className={classes.listItemIcon}>
          <EditIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">Edit Status</Typography>
      </MenuItem>

      {currentUserHasRole('admin') && (
        <Box>
          <Divider />
          <MenuItem onClick={() => history.push(`/students/${appointment?.student_id}/appointments/create`)}>
            <ListItemIcon className={classes.listItemIcon}>
              <DateRangeIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">{t.addAppointment}</Typography>
          </MenuItem>
        </Box>
      )}

      {currentUserHasRole('admin') && (
        <Box>
          <Divider />
          <MenuItem onClick={() => setDestroyModalOpen(true)}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">{t.deleteAppointment}</Typography>
          </MenuItem>
        </Box>
      )}
    </CustomMaterialMenu>
  );
};

export default AppointmentRowMenu;
