import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Box, Button, CardContent, Grid, Typography } from '@material-ui/core';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import ReferralStageRowMenu from './ReferralStageRowMenu';
import AddReferralStageModal from './AddReferralStageModal';
import DataTable from '../../../shared/components/DataTable';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { referralStagesStore } from '../../../shared/singletons';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { ReferralStagesState } from '../referralStages.store';
import { _translateEach } from '../../../shared/utils/translation.utils';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    cell: (row) => <ReferralStageRowMenu row={row} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

type ReferralStageListProps = {};

const ReferralStageListWrapper = styled.div``;

const ReferralStageList: FunctionComponent<ReferralStageListProps> = () => {
  const state = useStoreObservable<ReferralStagesState>(referralStagesStore);
  const { records, isLoading, createModalOpen } = state;

  const { setModalOpen } = referralStagesStore;

  const t = _translateEach({
    createButton: 'referralStageList.createButton',
    title: 'referralStageList.title',
  });

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ReferralStageListWrapper>
      <Box>
        <AddReferralStageModal
          isOpen={createModalOpen}
          openStatusChanged={setModalOpen}
          onSubmit={(stage: string) => referralStagesStore.addStage(stage)}
        />
        <CardContent>
          <Grid justify="space-between" container spacing={3}>
            <Grid item>
              <Typography variant="h1" component="h1">
                {t.title}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
                {t.createButton}
              </Button>
            </Grid>
          </Grid>
          <br />
          <DataTable columns={columns} noHeader data={records} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Box>
    </ReferralStageListWrapper>
  );
};

export default ReferralStageList;
