import React, { FunctionComponent, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { API_ENDPOINT } from '../../../../config/env';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import EditGradeLevelForm from './EditGradeLevelForm';
import { gradeLevelStore } from '../../../shared/singletons';
import { Colors } from '../../../shared/style/colors';

interface GradeLevelRowMenuProps {
  row: { id?: number };
}

const useStyles = makeStyles({
  errorIcon: {
    color: Colors.Red,
    fontSize: '66px',
    marginBottom: '20px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
});

const GradeLevelRowMenu: FunctionComponent<GradeLevelRowMenuProps> = (props: GradeLevelRowMenuProps) => {
  const { row } = props;
  const [isDestroyModalOpen, setDestroyModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editFormValues, setEditFormValues] = useState();
  const classes = useStyles();

  const handleEditFormSubmit = () => {
    axios({
      method: 'put',
      url: `${API_ENDPOINT}/grade_levels/${row.id}.json`,
      data: { grade_level: editFormValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function success() {
        gradeLevelStore.fetchRecords();
        setEditModalOpen(false);
      })
      .catch(function error() {
        setEditModalOpen(false);
      });
  };

  const handleDestroyFormSubmit = () => {
    axios({
      method: 'delete',
      url: `${API_ENDPOINT}/grade_levels/${row.id}.json`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function success() {
        gradeLevelStore.fetchRecords();
        setDestroyModalOpen(false);
      })
      .catch(function error() {
        setDestroyModalOpen(false);
      });
  };

  return (
    <CustomMaterialMenu size="small">
      <ConfirmCancelModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        width="768px"
        height="350px"
        onConfirm={handleDestroyFormSubmit}
        onCancel={() => setDestroyModalOpen(false)}
        hasTitleBar={false}
        hasFooterBar={false}
        hasCancelButton
        hasConfirmButton
      >
        <Box>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <Typography align="center" className={classes.modalText} variant="h5">
            Are you sure you want to delete this grade level? This action cannot be undone.
          </Typography>
        </Box>
      </ConfirmCancelModal>
      <ConfirmCancelModal
        isOpen={isEditModalOpen}
        title="Edit Grade Level"
        openStatusChanged={setEditModalOpen}
        cancelLabel="Cancel"
        confirmLabel="Update Grade Level"
        width="768px"
        onConfirm={handleEditFormSubmit}
        onCancel={() => setEditModalOpen(false)}
        hasCancelButton
        hasConfirmButton
      >
        <EditGradeLevelForm gradeLevelId={row.id} formValues={editFormValues} onFormValueChanged={setEditFormValues} />
      </ConfirmCancelModal>
      <MenuItem onClick={() => setEditModalOpen(true)}>Edit Grade Level</MenuItem>
      <MenuItem onClick={() => setDestroyModalOpen(true)}>Destroy Grade Level</MenuItem>
    </CustomMaterialMenu>
  );
};

export default GradeLevelRowMenu;
