import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import { User } from '../model';
import { School } from '../../schools/model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const nested_grid_override = {
  margin: 0,
};

interface UserInformationProps {
  user: User;
  userLoading: boolean;
}

const UserInformation: FunctionComponent<UserInformationProps> = (props) => {
  const { user, userLoading } = props;

  return userLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          User Information
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label="Status" value={user?.status} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Name" value={user?.name} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Roles" value={user?.roles[0]} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Contact Email" value={user?.email} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Supervisors" value={user?.supervisor_names} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Supervisors Emails" value={user?.supervisor_emails} />
          </Grid>
          {user?.schools?.map((school: School, index: number) => (
            <Grid key={index} container spacing={3} style={nested_grid_override}>
              <Grid item xs={4}>
                <InformationField label="Name" value={school.name} />
              </Grid>
              <Grid item xs={4}>
                <InformationField label="Location" value={school.primary_location} />
              </Grid>
              <Grid item xs={4}>
                <div />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserInformation;
