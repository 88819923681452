import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ServicesList from '../ServicesList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../../service.store';
import { serviceStore } from '../../../shared/singletons';
import { ServiceRouteParams } from '../../model';

export type ServicesListPageProps = Record<string, unknown>;

const ServicesListPage: FunctionComponent<ServicesListPageProps> = () => {
  const serviceState = useStoreObservable<ServiceState>(serviceStore);
  const { filteredRecords, records, recordsLoading } = serviceState;
  const { studentId } = useParams<ServiceRouteParams>();

  useEffect(() => {
    serviceStore.fetchRecords();
  }, []);

  return (
    <ServicesList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      studentId={studentId}
      showCreateButton={false}
      showFilterBar
    />
  );
};

export default ServicesListPage;
