import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { serviceStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../service.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const ServiceCategoryForm: FunctionComponent = () => {
  const classes = useStyles();

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formValue: formValues,
    phaseChoices,
    serviceTypeChoices,
    stageChoices,
    ownerChoices,
  } = useStoreObservable<ServiceState>(serviceStore);

  const handleFormValueChange = (key: string, value: string) => {
    serviceStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    serviceStore.fetchChoices();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => serviceStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Phase"
        errorMessage={errorValues?.phase}
        value={formValues?.phase}
        valueChanged={(value: string) => handleFormValueChange('phase', value)}
        choices={phaseChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Service Type"
        errorMessage={errorValues?.service}
        value={formValues?.service_id}
        valueChanged={(value: string) => handleFormValueChange('service_id', value)}
        choices={serviceTypeChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Stage"
        errorMessage={errorValues?.referral_stage}
        value={formValues?.referral_stage_id}
        valueChanged={(value: string) => handleFormValueChange('referral_stage_id', value)}
        choices={stageChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Owner"
        errorMessage={errorValues?.user}
        value={formValues?.user_id}
        valueChanged={(value: string) => handleFormValueChange('user_id', value)}
        choices={ownerChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
    </form>
  );
};

export default ServiceCategoryForm;
