import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { userStore } from '../../shared/singletons';
import { User } from '../model';

interface UserRowMenuProps {
  user: User;
}

const useStyles = makeStyles({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
  listItemIcon: {
    minWidth: '24px',
  },
});

const UserRowMenu: FunctionComponent<UserRowMenuProps> = (props: UserRowMenuProps) => {
  const classes = useStyles();
  const { user } = props;
  const history = useHistory();
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const t = _translateEach({
    viewUser: 'users.rowMenu.viewUser',
    editUser: 'users.rowMenu.editUser',
    editRole: 'users.rowMenu.editRole',
    deleteUser: 'users.rowMenu.deleteUser',
    deleteConfirm: 'users.rowMenu.deleteConfirm',
  });

  const handleDestroyFormSubmit = () => {
    userStore.deleteUser(user.id);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>

      <MenuItem onClick={() => history.push(`/users/${user.id}`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <PersonIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewUser}</Typography>
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => userStore.setEditModalOpen(true, user.id)}>
        <ListItemIcon className={classes.listItemIcon}>
          <EditIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.editUser}</Typography>
      </MenuItem>
      <MenuItem onClick={() => console.log('click')}>
        <ListItemIcon className={classes.listItemIcon}>
          <BookmarkBorderIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.editRole}</Typography>
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => setDestroyModalOpen(true)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DeleteIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.deleteUser}</Typography>
      </MenuItem>
    </CustomMaterialMenu>
  );
};

export default UserRowMenu;
