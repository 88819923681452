import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded';
import { BaseModalProps } from './modal.model';
import ConfirmCancelModal from './ConfirmCancelModal';
import { Colors } from '../../style/colors';

interface DeleteModalProps extends BaseModalProps {
  onDelete?: () => void;
}

const useStyles = makeStyles({
  errorIcon: {
    color: Colors.Red,
    fontSize: '66px',
    marginBottom: '20px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
});

const DeleteModalWrapper = styled.div``;

const ModalContent = styled.div``;
const ChildrenContainer = styled.div``;

const DeleteModal: FunctionComponent<DeleteModalProps> = (props: DeleteModalProps) => {
  const { isOpen, children, onDelete = () => null, openStatusChanged = (isOpen: boolean) => null } = props;

  const classes = useStyles();

  return (
    <DeleteModalWrapper>
      <ConfirmCancelModal
        isOpen={isOpen}
        openStatusChanged={openStatusChanged}
        cancelLabel="Cancel"
        confirmLabel="Delete"
        width="768px"
        height="350px"
        onConfirm={onDelete}
        onCancel={() => openStatusChanged(false)}
        hasTitleBar={false}
        hasFooterBar={false}
        hasCancelButton
        hasConfirmButton
      >
        <ModalContent>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <ChildrenContainer>{children}</ChildrenContainer>
        </ModalContent>
      </ConfirmCancelModal>
    </DeleteModalWrapper>
  );
};

export default DeleteModal;
