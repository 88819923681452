import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppointmentsList from '../AppointmentsList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../../appointment.store';
import { appointmentStore } from '../../../shared/singletons';
import { AppointmentRouteParams } from '../../model';

export type AppointmentsListPageProps = Record<string, unknown>;

const AppointmentsListPage: FunctionComponent<AppointmentsListPageProps> = () => {
  const appointmentState = useStoreObservable<AppointmentState>(appointmentStore);
  const { filteredRecords, records, recordsLoading } = appointmentState;
  const { studentId } = useParams<AppointmentRouteParams>();

  useEffect(() => {
    appointmentStore.fetchRecords();
  }, []);

  return (
    <AppointmentsList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      studentId={studentId}
      showCreateButton={false}
      showFilterBar
    />
  );
};

export default AppointmentsListPage;
