import React from 'react';
import { Box, Drawer, Link, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import AppointmentIcon from '@material-ui/icons/DateRange';
import ServiceIcon from '@material-ui/icons/LayersOutlined';
import StudentIcon from '@material-ui/icons/SchoolOutlined';
import RenewalIcon from '@material-ui/icons/AvTimerOutlined';
import UserIcon from '@material-ui/icons/PersonOutlineOutlined';
import SchoolIcon from '@material-ui/icons/Business';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { UserContext } from '../contexts/userContext';
import { Urls } from '../urls';

const drawerWidth = 100;
const useStyles = makeStyles((_theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'fixed',
    top: '65px',
  },
  list: {
    top: '100px',
  },
  link: {
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  listItem: {
    textAlign: 'center',
    padding: '10px',
  },
  logoutItem: {
    textAlign: 'center',
    padding: '10px',
    marginTop: '150px',
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const { logout, currentUserHasRole } = React.useContext(UserContext);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <List className={classes.list}>
        <ListItem button className={classes.listItem} key="students">
          <Link className={classes.link} href={Urls.StudentsRoute}>
            <StudentIcon />
            <Typography variant="subtitle2">Students</Typography>
          </Link>
        </ListItem>
        <ListItem button className={classes.listItem} key="appointments">
          <Link className={classes.link} href={Urls.AppointmentsRoute}>
            <AppointmentIcon />
            <Typography variant="subtitle2">Appointments</Typography>
          </Link>
        </ListItem>
        <ListItem button className={classes.listItem} key="services">
          <Link className={classes.link} href={Urls.ServicesRoute}>
            <ServiceIcon />
            <Typography variant="subtitle2">Services</Typography>
          </Link>
        </ListItem>
        <ListItem button className={classes.listItem} key="renewals">
          <Link className={classes.link} href={Urls.RenewalsRoute}>
            <RenewalIcon />
            <Typography variant="subtitle2">Renewals</Typography>
          </Link>
        </ListItem>
        <Box>
          {currentUserHasRole?.('admin') && (
            <ListItem button className={classes.listItem} key="users">
              <Link className={classes.link} href={Urls.UsersRoute}>
                <UserIcon />
                <Typography variant="subtitle2">Users</Typography>
              </Link>
            </ListItem>
          )}
        </Box>
        <ListItem button className={classes.listItem} key="schools">
          <Link className={classes.link} href={Urls.SchoolsRoute}>
            <SchoolIcon />
            <Typography variant="subtitle2">Schools</Typography>
          </Link>
        </ListItem>
        <ListItem button className={classes.logoutItem} key="sign_out">
          <Link className={classes.link} onClick={logout}>
            <LogoutIcon />
            <Typography variant="subtitle2">Sign Out</Typography>
          </Link>
        </ListItem>
      </List>
    </Drawer>
  );
}
