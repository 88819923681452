import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { Attachment } from './model';
import { API_ENDPOINT } from '../../config/env';

export class AttachmentState {
  records: Attachment[];

  recordsLoading: boolean;

  static create(props: Partial<AttachmentState>): AttachmentState {
    const defaults: AttachmentState = {
      records: [],
      recordsLoading: false,
    };
    return Object.assign(new AttachmentState(), defaults, props || {});
  }
}

export class AttachmentStore extends BaseStore<AttachmentState> {
  constructor() {
    super(AttachmentState.create({}));
  }

  public fetchRecords(service_id: string): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/referral_services/${service_id}/attachments.json`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }
}
