import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Appointment } from '../model';

interface AppointmentInformationProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

const AppointmentInformation: FunctionComponent<AppointmentInformationProps> = (props: AppointmentInformationProps) => {
  const { appointment, appointmentLoading } = props;

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Appointment Information
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label="Status" value={appointment?.status} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Owner" value={appointment?.owner} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Student" value={appointment?.student_id} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Scheduled for" value={appointment?.formatted_schedule_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Start Time" value={appointment?.formatted_start_time} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="End Time" value={appointment?.formatted_end_time} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Location" value={appointment?.location} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="School" value={appointment?.school} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Billing Date" value={appointment?.billing_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Initial Schedule Date" value={appointment?.formatted_original_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Mileage" value={appointment?.mileage} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentInformation;
