import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import StudentInformation from './StudentInformation';
import EmergencyContacts from './StudentEmergencyContacts';
import { Student } from '../model';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import CommunicationsList from '../../communications/components/CommunicationsList';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface StudentDetailsTabProps {
  student: Student;
  studentLoading: boolean;
}

const StudentDetailsTab: FunctionComponent<StudentDetailsTabProps> = (props) => {
  const { student, studentLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <StudentInformation student={student} studentLoading={studentLoading} />
        </Grid>
        <Grid item xs={6}>
          <EmergencyContacts student={student} studentLoading={studentLoading} />
        </Grid>
        <Grid item xs={6}>
          <AttachmentsList attachments={student.attachments} isLoading={studentLoading} />
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            {/* <CommentsList
              comments={student.comments}
              parentType="patients"
              parentId={student.id}
              isLoading={studentLoading}
              showCreateButton={false}
            />
            <br /> */}
            <CommunicationsList
              communications={student.communications}
              parentType="patients"
              showCreateButton={false}
              parentId={student.id}
              isLoading={studentLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentDetailsTab;
