import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import UserLocationsForm from './UserLocationsForm';
import UserRolesForm from './UserRolesForm';
import UserForm from './UserForm';
import ReviewUserForm from './ReviewUserForm';
import { userStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserState } from '../user.store';
import { _translateEach } from '../../shared/utils/translation.utils';

const UserFormModal: FunctionComponent = () => {
  const state = useStoreObservable<UserState>(userStore);
  const { formStepperIndex, formModalOpen, formValue, formUserId } = state;

  const t = _translateEach({
    createTitle: 'users.formModal.createTitle',
    updateTitle: 'users.formModal.updateTitle',
    cancel: 'users.formModal.cancel',
    confirmLabelStep1: 'users.formModal.confirmLabelStep1',
    confirmLabelStep2: 'users.formModal.confirmLabelStep2',
    confirmLabelStep3: 'users.formModal.confirmLabelStep3',
    confirmLabelStep4: 'users.formModal.confirmLabelStep4',
    updateLabelStep1: 'users.formModal.updateLabelStep1',
    updateLabelStep2: 'users.formModal.updateLabelStep2',
    updateLabelStep3: 'users.formModal.updateLabelStep3',
  });

  const handleNextClick = (currentIndex: number): void => {
    switch (currentIndex) {
      case 0:
        userStore.createOrUpdateUser(formValue, formUserId);
        return null;
      case 1:
        return userStore.updateLocations(formValue, formUserId);
      case 2:
        return userStore.updateRoles(formValue, formUserId);
      default:
        return userStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <UserForm />;
      case 1:
        return <UserLocationsForm />;
      case 2:
        return <UserRolesForm />;
      default:
        return <ReviewUserForm />;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={4}
      isOpen={formModalOpen}
      title={formUserId ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => userStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: 'Cancel', confirm: formUserId ? t.updateLabelStep1 : t.confirmLabelStep1 },
        { cancel: 'Cancel', confirm: formUserId ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: 'Cancel', confirm: formUserId ? t.updateLabelStep3 : t.confirmLabelStep3 },
        { cancel: 'Cancel', confirm: t.confirmLabelStep4 },
      ]}
      previousClicked={() => userStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default UserFormModal;
