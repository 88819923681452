import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { schoolStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../school.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const SchoolForm: FunctionComponent = () => {
  const { formErrorAlertVisible: showErrorAlert, formErrors: errorValues, formValue: formValues } = useStoreObservable<
    SchoolState
  >(schoolStore);

  const classes = useStyles();

  const handleFormValueChange = (key: string, value: string) => {
    schoolStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => schoolStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">School Details</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        label="School Name"
        errorMessage={errorValues?.name}
        value={formValues?.name}
        valueChanged={(value: string) => handleFormValueChange('name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Abbreviation"
        errorMessage={errorValues?.abbr}
        value={formValues?.abbr}
        valueChanged={(value: string) => handleFormValueChange('abbr', value)}
      />
      <Typography variant="h2">Contact Information</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        label="Contact Name"
        errorMessage={errorValues?.contact_name}
        value={formValues?.contact_name}
        valueChanged={(value: string) => handleFormValueChange('contact_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Contact Email"
        errorMessage={errorValues?.contact_email}
        value={formValues?.contact_email}
        valueChanged={(value: string) => handleFormValueChange('contact_email', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Address Line 1"
        errorMessage={errorValues?.address1}
        value={formValues?.address1}
        valueChanged={(value: string) => handleFormValueChange('address1', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Address Line 2"
        errorMessage={errorValues?.address2}
        value={formValues?.address2}
        valueChanged={(value: string) => handleFormValueChange('address2', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="City"
        errorMessage={errorValues?.city}
        value={formValues?.city}
        valueChanged={(value: string) => handleFormValueChange('city', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="State"
        errorMessage={errorValues?.state}
        value={formValues?.state}
        valueChanged={(value: string) => handleFormValueChange('state', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Zip"
        errorMessage={errorValues?.zip}
        value={formValues?.zip}
        valueChanged={(value: string) => handleFormValueChange('zip', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Phone"
        errorMessage={errorValues?.phone}
        value={formValues?.phone}
        valueChanged={(value: string) => handleFormValueChange('phone', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Fax"
        errorMessage={errorValues?.fax}
        value={formValues?.fax}
        valueChanged={(value: string) => handleFormValueChange('fax', value)}
      />
    </form>
  );
};

export default SchoolForm;
