import React, { FunctionComponent } from 'react';
import { Card, CardContent, Grid, Button, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Goal } from '../model';
import { goalStore } from '../../shared/singletons';
// import GoalRowMenu from './GoalRowMenu';
import NewGoalModal from './NewGoalModal';
import { Colors } from '../../shared/style/colors';

const columns: IDataTableColumn<Goal>[] = [
  {
    name: 'START DATE',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'END DATE',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'GOAL OBJECTIVES',
    selector: 'objectives',
    sortable: true,
    wrap: true,
    grow: 5,
  },
  {
    name: 'STATUS',
    selector: 'status',
    sortable: true,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: row.status === 'active' ? Colors.Success : Colors.Red,
        }}
      >
        {row.status}
      </div>
    ),
  },
  // {
  //   cell: (goal) => <GoalRowMenu goal={goal} />,
  //   name: 'ACTIONS',
  //   allowOverflow: true,
  //   button: true,
  //   selector: 'actions',
  // },
];

interface GoalsListProps {
  goals: Goal[];
  serviceId?: string;
  studentId?: string;
  isLoading: boolean;
  showCreateButton?: boolean;
}

const GoalsList: FunctionComponent<GoalsListProps> = (props) => {
  const { goals, serviceId, studentId, isLoading, showCreateButton = true } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <NewGoalModal serviceId={serviceId} studentId={studentId} />
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h2" component="h2">
              Goals
            </Typography>
          </Grid>
          <Grid item>
            {showCreateButton && (
              <Button variant="contained" color="primary" onClick={() => goalStore.setModalOpen(true)}>
                Create New Goal +
              </Button>
            )}
          </Grid>
        </Grid>
        <DataTable title="" columns={columns} noHeader data={goals} striped highlightOnHover pointerOnHover />
      </CardContent>
    </Card>
  );
};

export default GoalsList;
