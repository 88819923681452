import React, { FunctionComponent, Dispatch, SetStateAction, useState } from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../../../config/env';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import NewGradeLevelForm from './NewGradeLevelForm';
import { gradeLevelStore } from '../../../shared/singletons';

interface NewGradeLevelModalProps {
  isOpen: boolean;
  openStatusChanged: Dispatch<SetStateAction<boolean>>;
}

const NewGradeLevelModal: FunctionComponent<NewGradeLevelModalProps> = (props) => {
  const { isOpen, openStatusChanged } = props;
  const [formValues, setFormValues] = useState();

  const handleFormSubmit = () => {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/grade_levels.json`,
      data: { grade_level: formValues },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function success() {
        gradeLevelStore.fetchRecords();
        openStatusChanged(false);
      })
      .catch(function error() {
        openStatusChanged(false);
      });
  };

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title="New Grade Level"
      openStatusChanged={openStatusChanged}
      cancelLabel="Cancel"
      confirmLabel="Create Grade Level"
      hasCancelButton
      hasConfirmButton
      width="768px"
      onConfirm={handleFormSubmit}
      onCancel={() => openStatusChanged(false)}
    >
      <NewGradeLevelForm formValues={formValues} onFormValueChanged={setFormValues} />
    </ConfirmCancelModal>
  );
};

export default NewGradeLevelModal;
