import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ServiceDetail from '../ServiceDetail';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../../service.store';
import { serviceStore } from '../../../shared/singletons';
import { ServiceRouteParams } from '../../model';

export type ServiceDetailsPageProps = Record<string, unknown>;

const ServiceDetailsPage: FunctionComponent<ServiceDetailsPageProps> = () => {
  const serviceState = useStoreObservable<ServiceState>(serviceStore);
  const { service, serviceLoading } = serviceState;
  const { studentId, serviceId, tab } = useParams<ServiceRouteParams>();

  useEffect(() => {
    serviceStore.fetchService(studentId, serviceId);
  }, [studentId, serviceId]);

  return <ServiceDetail service={service} tab={tab} serviceLoading={serviceLoading} />;
};

export default ServiceDetailsPage;
