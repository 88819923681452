import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../../students/student.store';
import { studentStore } from '../../shared/singletons';
import { Student } from '../../students/model';

interface StudentSearchProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      width: '350px',
    },
  }),
);

const StudentSearch: FunctionComponent<StudentSearchProps> = (props: StudentSearchProps) => {
  const studentState = useStoreObservable<StudentState>(studentStore);
  const { records } = studentState;
  const classes = useStyles();

  useEffect(() => {
    studentStore.fetchRecords();
  }, []);

  return (
    <Autocomplete
      className={classes.inputRoot}
      options={records}
      getOptionLabel={(option) => option.slug}
      onChange={(event, student: Student) => window.location.replace(`/students/${student.id}/profile`)}
      onInputChange={(event, newValue) => studentStore.fetchRecords(`ransack[slug_cont]=${newValue}`)}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          size="small"
          fullWidth
          placeholder={'Student ID'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default StudentSearch;
