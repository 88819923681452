import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import {
  Appointment,
  AppointmentChoicesResponse,
  AppointmentFormValues,
  AppointmentFormErrors,
  AppointmentResponse,
} from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';
import { alertStore } from '../shared/singletons';
import { Colors } from '../shared/style/colors';

export class AppointmentState {
  records: Appointment[];
  appointment: Appointment;
  recordsLoading: boolean;
  appointmentLoading: boolean;
  filteredRecords: Appointment[];

  formValue: AppointmentFormValues;
  formErrors: AppointmentFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  rescheduleModalOpen: boolean;
  editStatusModalOpen: boolean;
  formStudentId: string;
  formAppointmentId: string;

  serviceTypeChoices: SelectOption[];
  ownerChoices: SelectOption[];
  locationChoices: SelectOption[];
  recurrenceChoices: SelectOption[];
  studentChoices: SelectOption[];
  statusChoices: SelectOption[];
  statusColors: Record<string, string>;
  filterValues: Record<string, string>;

  static create(props: Partial<AppointmentState>): AppointmentState {
    const defaults: AppointmentState = {
      records: [],
      appointment: {},
      recordsLoading: false,
      appointmentLoading: false,
      filteredRecords: null,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      rescheduleModalOpen: false,
      editStatusModalOpen: false,
      formStudentId: '',
      formAppointmentId: '',

      serviceTypeChoices: [],
      locationChoices: [],
      ownerChoices: [],
      recurrenceChoices: [],
      studentChoices: [],
      statusChoices: [
        { label: 'Incomplete', value: 'incomplete' },
        { label: 'Needs Rescheduled', value: 'needs_rescheduled' },
        { label: 'Complete', value: 'complete' },
      ],
      statusColors: {
        complete: Colors.Success,
        incomplete: Colors.Red,
        needs_rescheduled: Colors.PurpleThree,
        late_cancel: Colors.Orange,
        early_cancel: Colors.BabyBlue,
        no_show: Colors.GreyText,
      },
      filterValues: {},
    };
    return Object.assign(new AppointmentState(), defaults, props || {});
  }
}

export class AppointmentStore extends BaseStore<AppointmentState> {
  constructor() {
    super(AppointmentState.create({}));
  }

  public fetchRecords(params = ''): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/appointments.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchAppointment(studentId: string, appointmentId: string): void {
    this.setState({ appointmentLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}/appointments/${appointmentId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((appointment) => {
        this.setState({ appointment, appointmentLoading: false });
      })
      .catch(() => {
        this.setState({ appointmentLoading: false });
      });
  }

  public fetchChoices(studentId: string): void {
    axios
      .get<string, AppointmentChoicesResponse>(`${API_ENDPOINT}/patients/${studentId}/appointments/new.json`)
      .then((r: AppointmentChoicesResponse) => {
        const { service_types, recurrence_options, locations, owners } = r.data?.result;

        this.setState({
          recurrenceChoices: recurrence_options,
          serviceTypeChoices: service_types,
          locationChoices: locations,
          ownerChoices: owners,
        });
      });
  }

  public fetchStudentChoices(): void {
    axios.get<string, AppointmentChoicesResponse>(`${API_ENDPOINT}/appointments/new.json`).then((r) => {
      this.setState({ studentChoices: r?.data?.result?.students });
    });
  }

  public async createOrUpdateAppointment(
    formValue: AppointmentFormValues,
    studentId: string,
    appointmentId: string,
  ): Promise<void> {
    try {
      const response = await axios[appointmentId ? 'put' : 'post']<string, AppointmentResponse>(
        `${API_ENDPOINT}/patients/${studentId}/appointments${appointmentId ? `/${appointmentId}` : ''}.json`,
        {
          appointment: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      this.fetchRecords(); // TODO optimize?
      this.setState({ formStepperIndex: 2, formAppointmentId: response.data.result.id, editStatusModalOpen: false });
    } catch (error) {
      this.setState({
        formStepperIndex: 1, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public deleteAppointment(appointmentId: string): void {
    axios
      .delete<string, AppointmentResponse>(`${API_ENDPOINT}/appointments/${appointmentId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('appointments.alert.delete.error');
      });
  }

  public setFormValue(formValue: AppointmentFormValues): void {
    this.setState({ formValue });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen });
  }

  public setStudentId(studentId: string): void {
    this.setState({ formStudentId: studentId });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public setModalOpenWithStudentId(isOpen: boolean, studentId: string): void {
    this.setState({ formModalOpen: isOpen, formStudentId: studentId });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public setEditModalOpen(isOpen: boolean, appointmentId: string): void {
    const appointment = this.getState().records.find((record) => record.id === appointmentId);

    if (appointment) {
      const formValue = {
        referral_service_id: appointment.referral_service_id,
        recurrence: appointment.recurrence,
        schedule_date: appointment.schedule_date,
        end_recurrence: appointment.end_recurrence,
        start_time: appointment.start_time,
        end_time: appointment.end_time,
        location_id: appointment.location_id,
        owner_id: appointment.owner_id,
        student_id: appointment.student_id,
        comment: '',
      };

      this.setState({
        formModalOpen: isOpen,
        formAppointmentId: appointmentId,
        formStudentId: appointment?.student_id,
        formValue,
      });
    }
  }

  public setRescheduleModalOpen(isOpen: boolean, appointmentId: string): void {
    const appointment = this.getState().records.find((record) => record.id === appointmentId);

    if (appointment) {
      const formValue = {
        referral_service_id: appointment.referral_service_id,
        schedule_date: appointment.schedule_date,
        start_time: appointment.start_time,
        end_time: appointment.end_time,
        location_id: appointment.location_id,
        owner_id: appointment.owner_id,
        student_id: appointment.student_id,
        comment: '',
      };

      this.setState({
        rescheduleModalOpen: isOpen,
        formAppointmentId: appointmentId,
        formStudentId: appointment?.student_id,
        formValue,
        appointment,
      });
    }
  }

  public setEditStatusModalOpen(isOpen: boolean, appointmentId: string): void {
    const appointment = this.getState().records.find((record) => record.id === appointmentId);

    if (appointment) {
      const formValue = {
        referral_service_id: appointment.referral_service_id,
        schedule_date: appointment.schedule_date,
        start_time: appointment.start_time,
        end_time: appointment.end_time,
        location_id: appointment.location_id,
        student_id: appointment.student_id,
        comment: '',
      };

      this.setState({
        editStatusModalOpen: isOpen,
        formAppointmentId: appointmentId,
        formStudentId: appointment?.student_id,
        formValue,
        appointment,
      });
    }
  }

  public liveSearch(field: string, value: string, type = 'string'): void {
    this.setState({
      filteredRecords: this.getState().records.filter((record) => {
        if (type === 'select') {
          return record[field].toLowerCase() === value.toLowerCase();
        }
        if (typeof record[field] === 'string') {
          return record[field].toLowerCase().includes(value);
        }
        if (typeof record[field] === 'object' && Array.isArray(record[field])) {
          return record[field].find((x: string) => x.toLowerCase().includes(value.toLowerCase()));
        }

        // Field not on record, return false for now
        return false;
      }),
    });
  }
}
