import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { serviceStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../service.store';
import ReviewField from '../../shared/components/ReviewField';
import InformationField from '../../shared/components/InformationField';

const ReviewNewService: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<ServiceState>(serviceStore);

  const t = _translateEach({
    title: 'services.reviewService.title',
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <InformationField label="Label" value={formValues?.owner_id} />
          </Grid>
          <Grid item xs={2}>
            <InformationField label="ID" value={formValues?.owner_id} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">{t.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Service Phase" value={formValues?.referral_stage_id} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Service Type" value={null} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Phase" value={formValues?.phase} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Start Date" value={formValues?.start_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="End Date" value={formValues?.end_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Frequency" value={formValues?.frequency} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Owner" value={formValues?.owner_id} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Appointment Recurrence" value={formValues?.frequency} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Appointment Start Date" value={formValues?.start_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Appointment End Date" value={formValues?.end_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Appointment Location" value={null} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Total Number of Units" value={null} />
      </Grid>
    </Grid>
  );
};

export default ReviewNewService;
