import React, { FunctionComponent, useEffect } from 'react';
import ReferralStageList from '../ReferralStageList';
import { referralStagesStore } from '../../../../shared/singletons';

const ReferralStagesListPage: FunctionComponent = () => {
  useEffect(() => {
    referralStagesStore.fetchRecords();
  }, []);

  return <ReferralStageList />;
};

export default ReferralStagesListPage;
