import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { schoolStore } from '../../shared/singletons';
import { School } from '../model';
import { UserContext } from '../../shared/contexts/userContext';

interface SchoolRowMenuProps {
  school: School;
}

const useStyles = makeStyles({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
  listItemIcon: {
    minWidth: '24px',
  },
});

const SchoolRowMenu: FunctionComponent<SchoolRowMenuProps> = (props: SchoolRowMenuProps) => {
  const { school } = props;
  const history = useHistory();
  const classes = useStyles();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const t = _translateEach({
    viewSchool: 'schools.rowMenu.viewSchool',
    editSchool: 'schools.rowMenu.editSchool',
    deleteSchool: 'schools.rowMenu.deleteSchool',
    deleteConfirm: 'schools.rowMenu.deleteConfirm',
    viewListingFees: 'schools.rowMenu.viewListingFees',
    billing: 'schools.rowMenu.billing',
    addBlackoutDates: 'schools.rowMenu.addBlackoutDates',
  });

  const handleDestroyFormSubmit = () => {
    schoolStore.deleteSchool(school.id);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>

      <MenuItem onClick={() => history.push(`/schools/${school.id}`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewSchool}</Typography>
      </MenuItem>

      <Box>
        {currentUserHasRole('admin') && (
          <Box>
            <MenuItem onClick={() => history.push(`/schools/${school.id}/listing_fees`)}>
              <ListItemIcon className={classes.listItemIcon}>
                <LocalOfferIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.viewListingFees}</Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <LocalAtmIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.billing}</Typography>
            </MenuItem>
            <MenuItem onClick={() => schoolStore.setEditModalOpen(true, school.id)}>
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.editSchool}</Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <DateRangeIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addBlackoutDates}</Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => setDestroyModalOpen(true)}>
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.deleteSchool}</Typography>
            </MenuItem>
          </Box>
        )}
      </Box>
    </CustomMaterialMenu>
  );
};

export default SchoolRowMenu;
