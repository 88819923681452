import React, { FunctionComponent } from 'react';
import { Typography, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { userStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SelectOption } from '../../shared/common.model';
import { UserState } from '../user.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

const UserLocationsForm: FunctionComponent = () => {
  const classes = useStyles();

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formValue: formValues,
    locationChoices,
  } = useStoreObservable<UserState>(userStore);

  const t = _translateEach({
    title: 'users.locationsForm.title',
    subtitle: 'users.locationsForm.subtitle',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let options = [];

    if (formValues?.location_ids) {
      options = formValues?.location_ids;
    }

    if (event.target.checked) {
      options.push(+event.target.value);
    } else {
      const index = options.indexOf(+event.target.value);
      options.splice(index, 1);
    }

    userStore.setFormValue({ ...formValues, location_ids: options });
  };

  React.useEffect(() => {
    userStore.fetchLocations();
  }, []);

  return (
    <form noValidate autoComplete="off" className={classes.root}>
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => userStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">{t.title}</Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t.subtitle}</FormLabel>
        <br />
        <FormGroup>
          {locationChoices.map((location: SelectOption, _index: number) => (
            <FormControlLabel
              key={location.value}
              checked={formValues?.location_ids?.includes(location.value)}
              control={<Checkbox onChange={handleChange} name={location.value} value={location.value} />}
              label={location.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </form>
  );
};

export default UserLocationsForm;
