import React, { FunctionComponent } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import ReviewField from '../../shared/components/ReviewField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { SessionNote } from '../model';

interface SessionNotesListProps {
  sessionNotes: SessionNote[];
  isLoading: boolean;
}

const SessionNotesList: FunctionComponent<SessionNotesListProps> = (props) => {
  const { sessionNotes, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={8} direction="row">
              <Grid item>
                <Typography variant="h2" component="h2">
                  Session Notes
                </Typography>
              </Grid>
            </Grid>
            {sessionNotes.length > 0 && (
              <Grid container spacing={3} direction="row">
                <Grid item>
                  <ReviewField label="Date" value={sessionNotes[0].created_at} />
                </Grid>
                <Grid item>
                  <ReviewField label="Created by" value={sessionNotes[0].created_by} />
                </Grid>
                <Grid item>
                  <ReviewField label="Status" value={sessionNotes[0].status} />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {sessionNotes ? (
              sessionNotes?.map((session_note: SessionNote, index: number) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={12}>
                    <Typography variant="h3">{`Service Goal ${index + 1}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Percent" value={session_note.percent_string} />
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Level of Assistance" value={session_note.assistance} />
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Comment" value={session_note.content} />
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Prompts" value={session_note.prompts} />
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Participation" value={session_note.participation} />
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Transitions" value={session_note.transitions} />
                  </Grid>
                  <Grid item xs={12}>
                    <InformationField label="Subjective Comment" value={session_note.subjective_comment} />
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid container justify="center" alignItems="center">
                <Typography paragraph>There are no records to display</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SessionNotesList;
