import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { goalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { GoalState } from '../goal.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const NewGoalForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible: showErrorAlert, formErrors: errorValues, formValue: formValues } = useStoreObservable<
    GoalState
  >(goalStore);

  const handleFormValueChange = (key: string, value: string) => {
    goalStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => goalStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.start_date}
            label="Start Date"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => handleFormValueChange('start_date', value)}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.end_date}
            label="End Date"
            type="date"
            shrinkLabel
            valueChanged={(value: string) => handleFormValueChange('end_date', value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <TextInput
            styleOverrides={classes.textInput}
            value={formValues?.description}
            label="Goal Objectives"
            multiline
            rows={8}
            valueChanged={(value: string) => handleFormValueChange('description', value)}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default NewGoalForm;
