import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';

interface RenewalStageRowMenuProps {
  row: { id?: number };
}

const RenewalStageRowMenu: FunctionComponent<RenewalStageRowMenuProps> = (props: RenewalStageRowMenuProps) => {
  const { row } = props;

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={() => console.log(row)}>Edit Renewal Stage</MenuItem>
      <MenuItem onClick={() => null}>Destroy Renewal Stage</MenuItem>
    </CustomMaterialMenu>
  );
};

export default RenewalStageRowMenu;
