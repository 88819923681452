import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { School } from '../model';

interface SchoolInformationProps {
  school: School;
  schoolLoading: boolean;
}

const SchoolInformation: FunctionComponent<SchoolInformationProps> = (props) => {
  const { school, schoolLoading } = props;

  return schoolLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          School Information
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label="Name" value={school?.name} />
          </Grid>
          <Grid item xs={8}>
            <InformationField label="Abbreviation" value={school?.abbreviation} />
          </Grid>
          <Grid item xs={12}>
            <InformationField label="Locations" value={school?.locations} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Address" value={school?.address} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Contact Name" value={school?.contact_name} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Contact Email" value={school?.contact_email} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Start Date" value={school?.start_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="End Date" value={school?.end_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Payment terms" value={school?.payment_terms} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Q1 Start Date" value={school?.q1_sd} />
          </Grid>
          <Grid item xs={8}>
            <InformationField label="Q1 End Date" value={school?.q1_ed} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Q2 Start Date" value={school?.q2_sd} />
          </Grid>
          <Grid item xs={8}>
            <InformationField label="Q2 End Date" value={school?.q2_ed} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Q3 Start Date" value={school?.q3_sd} />
          </Grid>
          <Grid item xs={8}>
            <InformationField label="Q3 End Date" value={school?.q3_ed} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Q4 Start Date" value={school?.q4_sd} />
          </Grid>
          <Grid item xs={8}>
            <InformationField label="Q4 End Date" value={school?.q4_ed} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SchoolInformation;
