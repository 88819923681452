import React from 'react';
import Typography from '@material-ui/core/Typography';

interface ReviewField {
  label: string;
  value?: string | number | null;
}

export default function ReviewField(props: ReviewField) {
  const { label, value } = props;
  return (
    <Typography variant="body1">
      <b>{label}:</b> {value}
    </Typography>
  );
}
