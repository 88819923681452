enum Urls {
  AccountRoute = '/account',
  GradeLevelsRoute = '/grade_levels',
  ListingServicesRoute = '/listing_services',
  LocationsRoute = '/locations',
  ReferralStagesRoute = '/referral_stages',
  RenewalStagesRoute = '/renewal_stages',
  RenewalTypesRoute = '/renewal_types',
  RolesRoute = '/roles',

  DashboardRoute = '/',
  StudentsRoute = '/students',
  AppointmentsRoute = '/appointments',
  ServicesRoute = '/services',
  RenewalsRoute = '/renewals',
  UsersRoute = '/users',
  SchoolsRoute = '/schools',
  LoginRoute = '/login',
}

class DynamicUrls {
  static readonly AppointmentRoute = (id: string): string => `/appointments/${id}`;

  static readonly SchoolRoute = (id: string): string => `/schools/${id}`;

  static readonly ServiceRoute = (id: string): string => `/services/${id}`;

  static readonly RenewalRoute = (id: string): string => `/renewals/${id}`;

  static readonly StudentRoute = (id: string): string => `/students/${id}`;

  static readonly UserRoute = (id: string): string => `/users/${id}`;
}

export { Urls, DynamicUrls };
