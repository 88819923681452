import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SchoolInformation from './SchoolInformation';
import { School } from '../model';
import AttachmentsList from '../../attachments/components/AttachmentsList';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface SchoolDetailsTabProps {
  school: School;
  schoolLoading: boolean;
}

const SchoolDetailsTab: FunctionComponent<SchoolDetailsTabProps> = (props) => {
  const { school, schoolLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SchoolInformation school={school} schoolLoading={schoolLoading} />
        </Grid>
        <Grid item xs={6}>
          <AttachmentsList attachments={school.attachments} isLoading={schoolLoading} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SchoolDetailsTab;
