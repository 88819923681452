import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { API_ENDPOINT } from '../../config/env';
import { alertStore } from '../shared/singletons';
import { CommunicationResponse, CommunicationFormValues } from './model';

export class CommunicationState {
  formModalOpen: boolean;
  isCreating: boolean;
  formValue: CommunicationFormValues;

  static create(props: Partial<CommunicationState>): CommunicationState {
    const defaults: CommunicationState = {
      formModalOpen: false,
      isCreating: false,
      formValue: null,
    };
    return Object.assign(new CommunicationState(), defaults, props || {});
  }
}

export class CommunicationStore extends BaseStore<CommunicationState> {
  constructor() {
    super(CommunicationState.create({}));
  }

  public setModalOpen = (isOpen: boolean): void => this.setState({ formModalOpen: isOpen });

  public setFormValue(formValue: CommunicationFormValues): void {
    this.setState({ formValue });
  }

  public createOrUpdateCommunication(formValue: CommunicationFormValues, parentType: string, parentId: string): void {
    axios
      .post<string, CommunicationResponse>(`${API_ENDPOINT}/${parentType}/${parentId}/communications.json`, {
        communication: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        alertStore.alertSuccess('comments.alert.create.success');
        this.setState({ formModalOpen: false, isCreating: false });
      })
      .catch(() => {
        alertStore.alertSuccess('comments.alert.create.error');
        this.setState({ formModalOpen: false, isCreating: false });
      });
  }
}
