import React, { useState, useEffect, FunctionComponent } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { API_ENDPOINT } from '../../../config/env';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { AllRenewalStagesData } from './model';
import RenewalStageRowMenu from './components/RenewalStageRowMenu';
import AddRenewalStageModal from './components/AddRenewalStageModal';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    cell: (row) => <RenewalStageRowMenu row={row} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

const RenewalStages: FunctionComponent = () => {
  const [data, setData] = useState<AllRenewalStagesData>();
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      const result = await axios(`${API_ENDPOINT}/renewal_stages.json`);
      setData(result.data);
    }
    fetchData();
  }, []);

  if (!data) {
    return (
      <Card>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Box>
      <AddRenewalStageModal isOpen={isOpen} setOpen={setOpen} />
      <CardContent>
        <Grid justify="space-between" container spacing={3}>
          <Grid item>
            <Typography variant="h1" component="h1">
              Renewal Stages
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
              Create New Renewal Stage +
            </Button>
          </Grid>
        </Grid>
        <br />
        <DataTable columns={columns} noHeader data={data?.result} striped highlightOnHover pointerOnHover />
      </CardContent>
    </Box>
  );
};

export default RenewalStages;
