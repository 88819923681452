import axios from 'axios';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BaseStore } from '../../shared/state/base.store';
import { ReferralStage } from './model';
import { API_ENDPOINT } from '../../../config/env';
import { _max } from '../../shared/utils/number.utils';
import { alertStore } from '../../shared/singletons';

export class ReferralStagesState {
  records: ReferralStage[];
  isLoading: boolean;
  createModalOpen: boolean;
  isCreating: boolean;

  static create(props: Partial<ReferralStagesState>): ReferralStagesState {
    const defaults: ReferralStagesState = {
      records: [],
      isLoading: false,
      createModalOpen: false,
      isCreating: false,
    };
    return Object.assign(new ReferralStagesState(), defaults, props || {});
  }
}

export class ReferralStagesStore extends BaseStore<ReferralStagesState> {
  constructor() {
    super(ReferralStagesState.create({}));
  }

  public setModalOpen = (isOpen: boolean): void => this.setState({ createModalOpen: isOpen });

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/referral_stages.json`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, isLoading: false });
      })
      .catch((e) => {
        console.log('Failed to fetch referral stages.', e);
        this.setState({ isLoading: false });
      });
  }

  public addStage(stageName: string): void {
    if (!stageName) return;

    this.setState({ isCreating: true });

    this.createStageByName(stageName)
      .then((newStage) => {
        this.setState({
          isCreating: false,
          records: [...this.getState().records, newStage],
          createModalOpen: false,
        });
        alertStore.alertSuccess('referralStages.alert.create.success');
      })
      .catch((e) => {
        console.log('Failed to create stage.', e);
        this.setState({ isCreating: false });
        alertStore.alertError('referralStages.alert.create.error');
      });
  }

  private createStageByName(name: string): Promise<ReferralStage> {
    const nextId = _max(...this.getState().records.map((r) => r.id));
    const newStage = { id: nextId, name };
    return of(newStage).pipe(delay(1000)).toPromise();
  }
}
