import React, { FunctionComponent } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Quarter } from '../model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'START DATE',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'END DATE',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'SCHEDULED UNITS',
    selector: 'scheduled_units',
    sortable: true,
  },
  {
    name: 'SCHEDULED MINUTES',
    selector: 'scheduled_minutes',
    sortable: true,
  },
  {
    name: 'COMPLETED',
    selector: 'completed',
    sortable: true,
  },
  {
    name: 'EARLY CANCEL',
    selector: 'early_cancel',
    sortable: true,
  },
  {
    name: 'LATE CANCEL',
    selector: 'late_cancel',
    sortable: true,
  },
  {
    name: 'NEEDS RESCHEDULED',
    selector: 'needs_rescheduled',
    sortable: true,
  },
  {
    name: 'NO SHOW',
    selector: 'no_show',
    sortable: true,
  },
];

interface AppointmentQuartersProps {
  quarters: Quarter[];
  appointmentLoading: boolean;
}

const AppointmentQuarters: FunctionComponent<AppointmentQuartersProps> = (props: AppointmentQuartersProps) => {
  const { quarters, appointmentLoading } = props;

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Appointment Quarters
        </Typography>
        <DataTable columns={columns} noHeader data={quarters} striped highlightOnHover pointerOnHover />
      </CardContent>
    </Card>
  );
};

export default AppointmentQuarters;
