import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { schoolStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../school.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '98%',
    margin: '10px',
  },
});

const SchoolDatesForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible: showErrorAlert, formErrors: errorValues, formValue: formValues } = useStoreObservable<
    SchoolState
  >(schoolStore);

  const t = _translateEach({
    title: 'schools.datesForm.title',
    quarters: 'schools.datesForm.quarters',
    firstQuarter: 'schools.datesForm.firstQuarter',
    secondQuarter: 'schools.datesForm.secondQuarter',
    thirdQuarter: 'schools.datesForm.thirdQuarter',
    fourthQuarter: 'schools.datesForm.fourthQuarter',
    esyDates: 'schools.datesForm.esyDates',
    startDate: 'schools.datesForm.startDate',
    endDate: 'schools.datesForm.endDate',
  });

  const handleFormValueChange = (key: string, value: string) => {
    schoolStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    schoolStore.fetchLocations();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => schoolStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">{t.title}</Typography>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.startDate}
            errorMessage={errorValues?.start_date}
            value={formValues?.start_date}
            valueChanged={(value) => handleFormValueChange('start_date', value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.endDate}
            errorMessage={errorValues?.end_date}
            value={formValues?.end_date}
            valueChanged={(value) => handleFormValueChange('end_date', value)}
          />
        </Grid>
      </Grid>

      <Typography variant="h2">{t.quarters}</Typography>
      <Typography variant="subtitle1" component="h6">
        {t.firstQuarter}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.startDate}
            errorMessage={errorValues?.q1_sd}
            value={formValues?.q1_sd}
            valueChanged={(value) => handleFormValueChange('q1_sd', value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.endDate}
            errorMessage={errorValues?.q1_ed}
            value={formValues?.q1_ed}
            valueChanged={(value) => handleFormValueChange('q1_ed', value)}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" component="h6">
        {t.secondQuarter}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.startDate}
            errorMessage={errorValues?.q2_sd}
            value={formValues?.q2_sd}
            valueChanged={(value) => handleFormValueChange('q2_sd', value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.endDate}
            errorMessage={errorValues?.q2_ed}
            value={formValues?.q2_ed}
            valueChanged={(value) => handleFormValueChange('q2_ed', value)}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" component="h6">
        {t.thirdQuarter}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.startDate}
            errorMessage={errorValues?.q3_sd}
            value={formValues?.q3_sd}
            valueChanged={(value) => handleFormValueChange('q3_sd', value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.endDate}
            errorMessage={errorValues?.q3_ed}
            value={formValues?.q3_ed}
            valueChanged={(value) => handleFormValueChange('q3_ed', value)}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" component="h6">
        {t.fourthQuarter}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.startDate}
            errorMessage={errorValues?.q4_sd}
            value={formValues?.q4_sd}
            valueChanged={(value) => handleFormValueChange('q4_sd', value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.endDate}
            errorMessage={errorValues?.q4_ed}
            value={formValues?.q4_ed}
            valueChanged={(value) => handleFormValueChange('q4_ed', value)}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" component="h6">
        {t.esyDates}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.startDate}
            errorMessage={errorValues?.esy_sd}
            value={formValues?.esy_sd}
            valueChanged={(value) => handleFormValueChange('esy_sd', value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={t.endDate}
            errorMessage={errorValues?.esy_ed}
            value={formValues?.esy_ed}
            valueChanged={(value) => handleFormValueChange('esy_ed', value)}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SchoolDatesForm;
