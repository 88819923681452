import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RenewalDetail from '../RenewalDetail';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../../renewal.store';
import { renewalStore } from '../../../shared/singletons';
import { RenewalRouteParams } from '../../model';

export type RenewalShowPageProps = Record<string, unknown>;

const RenewalShowPage: FunctionComponent<RenewalShowPageProps> = () => {
  const renewalState = useStoreObservable<RenewalState>(renewalStore);
  const { renewal, renewalLoading } = renewalState;
  const params = useParams<RenewalRouteParams>();

  useEffect(() => {
    renewalStore.fetchRenewal(params.studentId, params.renewalId);
  }, [params]);

  return <RenewalDetail renewal={renewal} renewalLoading={renewalLoading} />;
};

export default RenewalShowPage;
