import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { appointmentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../appointment.store';
import ReviewField from '../../shared/components/ReviewField';
import InformationField from '../../shared/components/InformationField';

const ReviewAppointmentForm: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<AppointmentState>(appointmentStore);

  const t = _translateEach({
    title: 'appointments.reviewAppointment.title',
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <InformationField label="Label" value={formValues?.owner_id} />
          </Grid>
          <Grid item xs={2}>
            <InformationField label="ID" value={formValues?.owner_id} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">{t.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Service Type" value={null} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Recurrence" value={formValues?.recurrence} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Start Date" value={formValues?.schedule_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="End Date" value={formValues?.end_recurrence} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Time" value={formValues?.start_time} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Owner" value={formValues?.owner_id} />
      </Grid>
    </Grid>
  );
};

export default ReviewAppointmentForm;
