import React, { useState, useEffect, FunctionComponent } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import { API_ENDPOINT } from '../../../config/env';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { AllListingServicesData } from './model';
import ListingServiceRowMenu from './components/ListingServiceRowMenu';
import AddListingServiceModal from './components/AddListingServiceModal';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    cell: (row) => <ListingServiceRowMenu row={row} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

const ListingServices: FunctionComponent = () => {
  const [data, setData] = useState<AllListingServicesData>();
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      const result = await axios(`${API_ENDPOINT}/listing_services.json`);
      setData(result.data);
    }
    fetchData();
  }, []);

  if (!data) {
    return (
      <Card>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Box>
      <AddListingServiceModal isOpen={isOpen} setOpen={setOpen} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Listing Services
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            Create New Listing Service +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={data?.result} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListingServices;
