import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { renewalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../renewal.store';
import ReviewField from '../../shared/components/ReviewField';
import InformationField from '../../shared/components/InformationField';

const ReviewRenewal: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<RenewalState>(renewalStore);

  const t = _translateEach({
    title: 'renewals.reviewRenewal.title',
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <InformationField label="Student" value={formValues?.patient_id} />
          </Grid>
          <Grid item xs={2}>
            <InformationField label="ID" value={formValues?.patient_id} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">{t.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Renewal Type" value={formValues?.renewal_type_id} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Renewal Date" value={formValues?.renewal_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Renewal Stage" value={formValues?.renewal_stage_id} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Renewal Owner" value={formValues?.owner_id} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Renewal Supervisor" value={null} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Comments" value={null} />
      </Grid>
    </Grid>
  );
};

export default ReviewRenewal;
