import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceInformation from './ServiceInformation';
import StudentInformation from '../../students/components/StudentInformation';
import CommunicationsList from '../../communications/components/CommunicationsList';
import ServiceHistory from './ServiceHistory';
import { Service } from '../model';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface ServiceDetailsProps {
  service: Service;
  serviceLoading: boolean;
}

const ServiceDetailsTab: FunctionComponent<ServiceDetailsProps> = (props) => {
  const { service, serviceLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ServiceInformation service={service} serviceLoading={serviceLoading} />
        </Grid>
        <Grid item xs={6}>
          <StudentInformation student={service?.student} studentLoading={serviceLoading} />
        </Grid>
        <Grid item xs={6}>
          <ServiceHistory historyItems={service.history} isLoading={serviceLoading} />
        </Grid>
        {/* <Grid item xs={6}>
          <CommentsList
            comments={service.comments}
            parentType="referral_services"
            parentId={service.id}
            isLoading={serviceLoading}
          />
        </Grid> */}
        <Grid item xs={6}>
          <CommunicationsList
            communications={service.communications}
            parentType="referral_services"
            parentId={service.id}
            isLoading={serviceLoading}
            showCreateButton={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceDetailsTab;
