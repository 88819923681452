import React, { useContext } from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AppBar, Box } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Settings from '@material-ui/icons/Settings';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Urls } from '../urls';
import { UserContext } from '../contexts/userContext';
import StudentSearch from './StudentSearch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
    },
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      backgroundColor: '#fff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      color: '#000',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#ccc',
    },
    inputRoot: {
      color: 'inherit',
      border: '1px solid #ccc',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      color: '#000',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '25ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const { currentUserHasRole, user, logout } = useContext(UserContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {currentUserHasRole?.('admin') && (
        <Box>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.GradeLevelsRoute}>
              Grade Levels
            </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.LocationsRoute}>
              Locations
            </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.ReferralStagesRoute}>
              Referral Stages
            </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.RenewalStagesRoute}>
              Renewal Stages
            </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.RenewalTypesRoute}>
              Renewal Types
            </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.ListingServicesRoute}>
              Listing Services
            </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link variant="body2" href={Urls.RolesRoute}>
              Roles
            </Link>
          </MenuItem>
        </Box>
      )}
      <MenuItem onClick={handleMenuClose}>
        <Link variant="body2" href={Urls.AccountRoute}>
          My Account
        </Link>
      </MenuItem>
      {user && (
        <MenuItem onClick={handleMenuClose}>
          <Link variant="body2" onClick={logout}>
            Logout
          </Link>
        </MenuItem>
      )}
      {!user && (
        <MenuItem onClick={handleMenuClose}>
          <Link variant="body2" href={Urls.LoginRoute}>
            Login
          </Link>
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h1" noWrap>
            Hope Learning
          </Typography>
          <div className={classes.grow} />
          <div className={classes.search}>
            <StudentSearch />
          </div>
          <div className={classes.sectionDesktop}>
            {currentUserHasRole?.('admin') && (
              <Box>
                <IconButton aria-label="show 4 new mails" color="primary">
                  <Badge color="secondary">
                    <HelpOutline />
                  </Badge>
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="primary">
                  <Badge color="secondary">
                    <Settings />
                  </Badge>
                </IconButton>
              </Box>
            )}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="primary"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
