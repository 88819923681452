import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../student.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
});

const StudentEmergencyContactsForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible: showErrorAlert, formErrors: errorValues, formValue: formValues } = useStoreObservable<
    StudentState
  >(studentStore);

  const t = _translateEach({
    primaryGuardian: 'students.emergencyContacts.primaryGuardian',
    guardianName: 'students.emergencyContacts.guardianName',
    guardianContactPrimary: 'students.emergencyContacts.guardianContactPrimary',
    guardianContactSecondary: 'students.emergencyContacts.guardianContactSecondary',
    guardianContactAlternate: 'students.emergencyContacts.guardianContactAlternate',
    emailGuardian: 'students.emergencyContacts.emailGuardian',
    emergencyContact: 'students.emergencyContacts.emergencyContact',
    emergencyName: 'students.emergencyContacts.emergencyName',
    emergencyRelationship: 'students.emergencyContacts.emergencyRelationship',
    emergencyPhone: 'students.emergencyContacts.emergencyPhone',
  });

  const handleFormValueChange = (key: string, value: string) => {
    studentStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => studentStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">{t.primaryGuardian}</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianName}
        errorMessage={errorValues?.gaurdian_name}
        value={formValues?.gaurdian_name}
        valueChanged={(value) => handleFormValueChange('gaurdian_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianContactPrimary}
        errorMessage={errorValues?.gaurdian_contact_primary}
        value={formValues?.gaurdian_contact_primary}
        valueChanged={(value) => handleFormValueChange('gaurdian_contact_primary', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianContactSecondary}
        errorMessage={errorValues?.gaurdian_contact_secondary}
        value={formValues?.gaurdian_contact_secondary}
        valueChanged={(value) => handleFormValueChange('gaurdian_contact_secondary', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.guardianContactAlternate}
        errorMessage={errorValues?.gaurdian_contact_alternate}
        value={formValues?.gaurdian_contact_alternate}
        valueChanged={(value) => handleFormValueChange('gaurdian_contact_alternate', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.emailGuardian}
        errorMessage={errorValues?.email_gaurdian}
        value={formValues?.email_gaurdian}
        valueChanged={(value) => handleFormValueChange('email_gaurdian', value)}
      />
      <Typography variant="h2">{t.emergencyContact}</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.emergencyName}
        errorMessage={errorValues?.emergency_name}
        value={formValues?.emergency_name}
        valueChanged={(value) => handleFormValueChange('emergency_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.emergencyRelationship}
        errorMessage={errorValues?.emergency_relationship}
        value={formValues?.emergency_relationship}
        valueChanged={(value) => handleFormValueChange('emergency_relationship', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.emergencyPhone}
        errorMessage={errorValues?.emergency_phone}
        value={formValues?.emergency_phone}
        valueChanged={(value) => handleFormValueChange('emergency_phone', value)}
      />
    </form>
  );
};

export default StudentEmergencyContactsForm;
