import React, { FunctionComponent } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../shared/components/form/TextInput';
import { userStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserState } from '../../users/user.store';

import { _translateEach } from '../../shared/utils/translation.utils';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const AccountEditModal: FunctionComponent = () => {
  const state = useStoreObservable<UserState>(userStore);
  const { accountEditModal, formValue, formErrors, formUserId, user } = state;
  const classes = useStyles();

  const t = _translateEach({
    title: 'users.editAccountModal.title',
    update: 'users.editAccountModal.update',
  });

  const handleFormValueChange = (key: string, value: string) => {
    userStore.setFormValue({ ...formValue, [key]: value });
  };

  React.useEffect(() => {
    userStore.fetchUser(formUserId);
  }, [formUserId]);

  const handleFormSubmit = () => {
    userStore.createOrUpdateUser(formValue, formUserId);
    userStore.setAccountEditModalOpen(false, user);
  };

  return (
    <ConfirmCancelModal
      isOpen={accountEditModal}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => userStore.setAccountEditModalOpen(isOpen, user)}
    >
      <Box p={2}>
        <Grid container>
          <Grid item xs={10}>
            <TextInput
              value={formValue?.first_name}
              styleOverrides={classes.textInput}
              shrinkLabel
              label="First Name"
              errorMessage={formErrors?.first_name}
              valueChanged={(value: string) => handleFormValueChange('first_name', value)}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={formValue?.last_name}
              styleOverrides={classes.textInput}
              shrinkLabel
              label="Last Name"
              errorMessage={formErrors?.last_name}
              valueChanged={(value: string) => handleFormValueChange('last_name', value)}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={formValue?.username}
              styleOverrides={classes.textInput}
              shrinkLabel
              label="Username"
              errorMessage={formErrors?.username}
              valueChanged={(value: string) => handleFormValueChange('username', value)}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={formValue?.email}
              styleOverrides={classes.textInput}
              shrinkLabel
              label="Email"
              errorMessage={formErrors?.email}
              valueChanged={(value: string) => handleFormValueChange('email', value)}
            />
          </Grid>
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default AccountEditModal;
