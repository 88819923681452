import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../student.store';
import ReviewField from '../../shared/components/ReviewField';

const ReviewStudentForm: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<StudentState>(studentStore);

  const t = _translateEach({
    title: 'students.reviewStudent.title',
    section1: 'students.reviewStudent.section1',
    section2: 'students.reviewStudent.section2',
    section3: 'students.reviewStudent.section3',
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">{t.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Student First Name" value={formValues?.first_name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Student Last Name" value={formValues?.last_name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="School" value={null} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Teacher's Name" value={formValues?.teacher} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Room Number" value={formValues?.room_number} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="DOB" value={formValues?.birthdate} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Email" value={null} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Attachments" value={null} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">{t.section1}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Name" value={formValues?.gaurdian_name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Primary Phone" value={formValues?.gaurdian_contact_primary} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Secondary Phone" value={formValues?.gaurdian_contact_secondary} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Alternate Phone" value={formValues?.gaurdian_contact_alternate} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">{t.section2}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Name" value={formValues?.emergency_name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Relationship" value={formValues?.emergency_relationship} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Email" value={formValues?.emergency_phone} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">{t.section3}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Address 1" value={formValues?.address1} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Address 2" value={formValues?.address2} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="City" value={formValues?.city} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="State" value={formValues?.state} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Zip" value={formValues?.zip} />
      </Grid>
    </Grid>
  );
};

export default ReviewStudentForm;
