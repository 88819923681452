import React, { FunctionComponent, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import ServiceRowMenu from './ServiceRowMenu';
import ServiceFormModal from './ServiceFormModal';
import { Service } from '../model';
import { serviceStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../service.store';
import SearchBar, { FieldProps, FilterProps } from '../../shared/components/SearchBar';
import ServiceStageChangeModal from './ServiceStageChangeModal';

interface ServicesListProps {
  records: Service[];
  recordsLoading: boolean;
  showCreateButton?: boolean;
  studentId?: string;
  showFilterBar?: boolean;
}

const ServicesList: FunctionComponent<ServicesListProps> = (props) => {
  const { records, recordsLoading, showCreateButton = false, studentId = null, showFilterBar = false } = props;
  const {
    phaseColors,
    statusColors,
    phaseChoices,
    serviceTypeChoices,
    ownerChoices,
    stageChoices,
    statusChoices,
  } = useStoreObservable<ServiceState>(serviceStore);
  const history = useHistory();

  const fields: FieldProps[] = [
    {
      selector: 'phase',
      label: 'Phase',
      type: 'select',
      options: phaseChoices,
    },
    {
      selector: 'student_slug',
      label: 'Student ID',
      type: 'text',
    },
    {
      selector: 'student_name',
      label: 'Student Name',
      type: 'select',
    },
  ];

  const filters: FilterProps[] = [
    {
      selector: 'ransack[service_id_eq]',
      label: 'Service Type',
      type: 'select',
      options: serviceTypeChoices,
    },
    {
      selector: 'ransack[created_at_eq]',
      label: 'Date Created',
      type: 'date',
    },
    {
      selector: 'ransack[user_id_eq]',
      label: 'Owner',
      type: 'select',
      options: ownerChoices,
    },
    {
      selector: 'ransack[referral_stage_id_eq]',
      label: 'Stage',
      type: 'select',
      options: stageChoices,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'select',
      options: statusChoices,
    },
  ];

  const columns: IDataTableColumn<Service>[] = [
    {
      name: 'PHASE',
      selector: 'phase',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: phaseColors[row.phase],
          }}
        >
          {row.phase}
        </div>
      ),
    },
    {
      name: 'STUDENT ID',
      selector: 'student_slug',
      sortable: true,
    },
    {
      name: 'STUDENT NAME',
      selector: 'student_name',
      sortable: true,
    },
    {
      name: 'SERVICE TYPE',
      selector: 'service_type',
      sortable: true,
    },
    {
      name: 'DATE CREATED',
      selector: 'date_created',
      sortable: true,
    },
    {
      name: 'OWNER',
      selector: 'owner',
      sortable: true,
    },
    {
      name: 'STAGE',
      selector: 'stage',
      sortable: true,
    },
    {
      name: 'TIMELINE',
      selector: 'timeline',
      sortable: true,
    },
    {
      name: 'STATUS',
      selector: 'status',
      sortable: true,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.status],
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      cell: (service) => <ServiceRowMenu service={service} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  useEffect(() => {
    if (studentId) {
      serviceStore.setModalOpenWithStudentId(true, studentId);
    }
  }, [studentId]);

  return recordsLoading ? (
    <Box>
      <Typography variant="h1" component="h1">
        Services
      </Typography>
      <br />
      <LoadingSpinner />
    </Box>
  ) : (
    <Box>
      <ServiceFormModal />
      <ServiceStageChangeModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Services
          </Typography>
        </Grid>
        <Grid item>
          {showCreateButton && (
            <Button variant="contained" color="primary" onClick={() => serviceStore.setModalOpen(true)}>
              Create New Service +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <SearchBar fields={fields} filters={filters} store={serviceStore} />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            striped
            onRowClicked={(service: Service) => history.push(`/students/${service.student_id}/services/${service.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ServicesList;
