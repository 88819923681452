import React, { FunctionComponent } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import RenewalInformation from './RenewalInformation';
import RenewalFormModal from './RenewalFormModal';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import StudentInformation from '../../students/components/StudentInformation';
import CommunicationsList from '../../communications/components/CommunicationsList';
import RenewalHistory from './RenewalHistory';
import { Renewal } from '../model';
import { renewalStore } from '../../shared/singletons';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../shared/contexts/userContext';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface RenewalDetailProps {
  renewal: Renewal;
  renewalLoading: boolean;
}

const RenewalDetail: FunctionComponent<RenewalDetailProps> = (props) => {
  const classes = useStyles();
  const { renewal, renewalLoading } = props;
  const { currentUserHasRole } = React.useContext(UserContext);

  const links: BreadcrumbLink[] = [
    {
      href: '/renewals',
      text: 'RENEWALS',
    },
    {
      href: null,
      text: renewal.type,
    },
  ];

  return renewalLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <RenewalFormModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {renewal.type}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button variant="contained" color="primary" onClick={() => renewalStore.setModalOpen(true)}>
              Create New Renewal +
            </Button>
          )}
        </Grid>
      </Grid>
      <br />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <RenewalInformation renewal={renewal} renewalLoading={renewalLoading} />
          </Grid>
          <Grid item xs={6}>
            <StudentInformation student={renewal?.student} studentLoading={renewalLoading} />
          </Grid>
          <Grid item xs={6}>
            <RenewalHistory historyItems={renewal.history} isLoading={renewalLoading} />
          </Grid>
          {/* <Grid item xs={6}>
            <CommentsList
              comments={renewal.comments}
              parentType="renewals"
              parentId={renewal.id}
              isLoading={renewalLoading}
            />
          </Grid> */}
          <Grid item xs={6}>
            <CommunicationsList
              communications={renewal.communications}
              parentType="renewals"
              parentId={renewal.id}
              isLoading={renewalLoading}
              showCreateButton={false}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default RenewalDetail;
