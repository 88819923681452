import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { Renewal, RenewalChoicesResponse, RenewalFormValues, RenewalFormErrors, RenewalResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';
import { alertStore } from '../shared/singletons';
import { Colors } from '../shared/style/colors';

export class RenewalState {
  records: Renewal[];
  renewal: Renewal;
  recordsLoading: boolean;
  renewalLoading: boolean;
  filteredRecords: Renewal[];

  formValue: RenewalFormValues;
  formErrors: RenewalFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  editStageModalOpen: boolean;
  formStudentId: string;
  formRenewalId: string;

  renewalTypeChoices: SelectOption[];
  renewalStageChoices: SelectOption[];
  supervisorChoices: SelectOption[];
  ownerChoices: SelectOption[];
  studentChoices: SelectOption[];
  statusChoices: SelectOption[];
  statusColors: Record<string, string>;
  filterValues: Record<string, string>;

  static create(props: Partial<RenewalState>): RenewalState {
    const defaults: RenewalState = {
      records: [],
      renewal: {},
      recordsLoading: false,
      renewalLoading: false,
      filteredRecords: null,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      editStageModalOpen: false,
      formStudentId: '',
      formRenewalId: '',

      renewalTypeChoices: [],
      renewalStageChoices: [],
      supervisorChoices: [],
      ownerChoices: [],
      studentChoices: [],
      statusChoices: [
        { label: 'On Target', value: 'on_target' },
        { label: 'Needs Assignment', value: 'needs_assignment' },
        { label: 'Renewed', value: 'renewed' },
      ],

      statusColors: {
        'On Target': Colors.Success,
        Renewed: Colors.BabyBlue,
        'Needs Assignment': Colors.Red,
      },
      filterValues: {},
    };
    return Object.assign(new RenewalState(), defaults, props || {});
  }
}

export class RenewalStore extends BaseStore<RenewalState> {
  constructor() {
    super(RenewalState.create({}));
  }

  public fetchRecords(params = ''): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/renewals.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchRenewal(studentId: string, renewalId: string): void {
    this.setState({ renewalLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}/renewals/${renewalId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((renewal) => {
        this.setState({ renewal, renewalLoading: false });
      })
      .catch(() => {
        this.setState({ renewalLoading: false });
      });
  }

  public async createOrUpdateRenewal(
    formValue: RenewalFormValues,
    studentId: string,
    renewalId: string,
  ): Promise<void> {
    try {
      await axios[studentId ? 'put' : 'post']<string>(
        `${API_ENDPOINT}/patients/${studentId}/renewals${renewalId ? `/${renewalId}` : ''}.json`,
        {
          renewal: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      this.fetchRecords(); // TODO optimize?
      this.setState({ formModalOpen: false, formStepperIndex: 2 });
    } catch (error) {
      this.setState({
        formStepperIndex: 1, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public deleteRenewal(renewalId: string): void {
    axios
      .delete<string, RenewalResponse>(`${API_ENDPOINT}/renewals/${renewalId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('renewals.alert.delete.error');
      });
  }

  public setEditModalOpen(isOpen: boolean, renewalId: string): void {
    const renewal = this.getState().records.find((record) => record.id === renewalId);

    if (renewal) {
      const formValue = {
        patient_id: renewal.student_id,
        renewal_type_id: renewal.renewal_type_id,
        renewal_date: renewal.renewal_date,
        renewal_stage_id: renewal.renewal_stage_id,
        user_id: renewal.user_id,
      };

      this.setState({ formModalOpen: isOpen, formRenewalId: renewal.id, formStudentId: renewal.student_id, formValue });
    }
  }

  public setFormValue(formValue: RenewalFormValues): void {
    this.setState({ formValue });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen });
  }

  public setStudentId(studentId: string): void {
    this.setState({ formStudentId: studentId });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public setModalOpenWithStudentId(isOpen: boolean, studentId: string): void {
    this.setState({ formModalOpen: isOpen, formStudentId: studentId });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public fetchChoices(studentId: string): void {
    axios
      .get<string, RenewalChoicesResponse>(`${API_ENDPOINT}/patients/${studentId}/renewals/new.json`)
      .then((r: RenewalChoicesResponse) => {
        const { renewal_types, renewal_stages, supervisors, owners, students } = r.data?.result;

        this.setState({
          renewalTypeChoices: renewal_types,
          renewalStageChoices: renewal_stages,
          supervisorChoices: supervisors,
          ownerChoices: owners,
          studentChoices: students,
        });
      });
  }

  public fetchStudentChoices(): void {
    axios.get<string, RenewalChoicesResponse>(`${API_ENDPOINT}/renewals/new.json`).then((r) => {
      console.log('r', r);
      this.setState({ studentChoices: r?.data?.result?.students });
    });
  }

  public setRescheduleModalOpen(isOpen: boolean, renewalId: string): void {
    const renewal = this.getState().records.find((record) => record.id === renewalId);

    if (renewal) {
      const formValue = {
        patient_id: renewal.student_id,
        renewal_type_id: renewal.renewal_type_id,
        renewal_date: renewal.renewal_date,
        renewal_stage_id: renewal.renewal_stage_id,
        user_id: renewal.user_id,
      };

      this.setState({
        editStageModalOpen: isOpen,
        formRenewalId: renewalId,
        formStudentId: renewal?.student_id,
        formValue,
        renewal,
      });
    }
  }

  public liveSearch(field: string, value: string): void {
    this.setState({
      filteredRecords: this.getState().records.filter((record) => {
        if (typeof record[field] === 'string') {
          return record[field].toLowerCase().includes(value.toLowerCase());
        }

        if (typeof record[field] === 'object' && Array.isArray(record[field])) {
          return record[field].find((x: string) => x.toLowerCase().includes(value.toLowerCase()));
        }

        // Field not on record, return false for now
        return false;
      }),
    });
  }
}
