import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import UserInformation from './UserInformation';
import UserServices from './UserServices';
import { User } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface UserDetailProps {
  user: User;
  userLoading: boolean;
}

const UserDetail: FunctionComponent<UserDetailProps> = (props) => {
  const { user, userLoading } = props;
  const classes = useStyles();
  const links: BreadcrumbLink[] = [
    {
      href: '/users',
      text: 'USERS',
    },
    {
      href: null,
      text: user.name,
    },
  ];

  return (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {user.name}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
      </Grid>
      <br />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <UserInformation user={user} userLoading={userLoading} />
          </Grid>
          <Grid item xs={6}>
            <UserServices services={user.services} isLoading={userLoading} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UserDetail;
