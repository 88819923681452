import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';

interface LocationRowMenuProps {
  row: { id?: number };
}

const LocationRowMenu: FunctionComponent<LocationRowMenuProps> = (props: LocationRowMenuProps) => {
  const { row } = props;

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={() => console.log(row)}>Edit Location</MenuItem>
      <MenuItem onClick={() => null}>Destroy Location</MenuItem>
    </CustomMaterialMenu>
  );
};

export default LocationRowMenu;
