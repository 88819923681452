import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { ListingFee } from '../model';
import ListingFeeRowMenu from './ListingFeeRowMenu';

const columns: IDataTableColumn<ListingFee>[] = [
  {
    name: 'START TIME',
    selector: 'start_time',
    sortable: true,
  },
  {
    name: 'END TIME',
    selector: 'end_time',
    sortable: true,
  },
  {
    name: 'FEE TYPE',
    selector: 'fee_type',
    sortable: true,
  },
  {
    name: 'AMOUNT',
    selector: 'amount',
    sortable: true,
  },
  {
    cell: (row) => <ListingFeeRowMenu row={row} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

interface ListingFeesListProps {
  listingFees: ListingFee[];
  isLoading: boolean;
}

const ListingFeesList: FunctionComponent<ListingFeesListProps> = (props) => {
  const { listingFees, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Listing Fees
        </Typography>
        <DataTable columns={columns} noHeader data={listingFees} striped highlightOnHover pointerOnHover />
      </CardContent>
    </Card>
  );
};

export default ListingFeesList;
