import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { School, SchoolLocationsResponse, SchoolFormValues, SchoolFormErrors, SchoolResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';
import { alertStore } from '../shared/singletons';

export class SchoolState {
  records: School[];
  school: School;
  recordsLoading: boolean;
  schoolLoading: boolean;
  filteredRecords: School[];

  formValue: SchoolFormValues;
  formErrors: SchoolFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  formSchoolId: string;

  locationChoices: SelectOption[];
  filterValues: Record<string, string>;

  static create(props: Partial<SchoolState>): SchoolState {
    const defaults: SchoolState = {
      records: [],
      school: {},
      recordsLoading: false,
      schoolLoading: false,
      filteredRecords: null,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      formSchoolId: '',

      locationChoices: [],
      filterValues: {},
    };
    return Object.assign(new SchoolState(), defaults, props || {});
  }
}

export class SchoolStore extends BaseStore<SchoolState> {
  constructor() {
    super(SchoolState.create({}));
  }

  public fetchRecords(params = ''): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/payers.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchSchool(schoolId: string): void {
    this.setState({ schoolLoading: true });

    axios(`${API_ENDPOINT}/payers/${schoolId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((school) => {
        this.setState({ school, schoolLoading: false });
      })
      .catch(() => {
        this.setState({ schoolLoading: false });
      });
  }

  public async createOrUpdateSchool(formValue: SchoolFormValues, schoolId: string): Promise<void> {
    try {
      const response = await axios[schoolId ? 'put' : 'post']<string, SchoolResponse>(
        `${API_ENDPOINT}/payers${schoolId ? `/${schoolId}` : ''}.json`,
        {
          payer: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      this.fetchRecords(); // TODO optimize?
      this.setState({ formStepperIndex: 1, formSchoolId: response.data.result.id });
    } catch (error) {
      this.setState({
        formStepperIndex: 0, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public setEditModalOpen(isOpen: boolean, schoolId: string): void {
    const school = this.getState().records.find((record) => record.id === schoolId);

    if (school) {
      const formValue = {
        name: school.name,
        abbr: school.abbreviation,
        contact_name: school.contact_name,
        contact_email: school.contact_email,
        start_date: school.start_date,
        end_date: school.end_date,
        q1_sd: school.q1_sd,
        q1_ed: school.q1_ed,
        q2_sd: school.q2_sd,
        q2_ed: school.q2_ed,
        q3_sd: school.q3_sd,
        q3_ed: school.q3_ed,
        q4_sd: school.q4_sd,
        q4_ed: school.q4_ed,
        address1: school.address1,
        address2: school.address2,
        city: school.city,
        state: school.state,
        zip: school.zip,
        phone: school.phone,
        fax: school.fax,
      };

      this.setState({ formModalOpen: isOpen, formSchoolId: schoolId, formValue });
    }
  }

  public updateDates(formValue: SchoolFormValues, schoolId: string): void {
    axios
      .put<string, SchoolResponse>(`${API_ENDPOINT}/payers/${schoolId}.json`, {
        payer: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateLocations(formValue: SchoolFormValues, schoolId: string): void {
    axios
      .put<string, SchoolResponse>(`${API_ENDPOINT}/payers/${schoolId}.json`, {
        payer: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 3 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteSchool(schoolId: string): void {
    axios
      .delete<string, SchoolResponse>(`${API_ENDPOINT}/payers/${schoolId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('schools.alert.delete.error');
      });
  }

  public setFormValue(formValue: SchoolFormValues): void {
    this.setState({ formValue });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public fetchLocations(): void {
    axios.get<string, SchoolLocationsResponse>(`${API_ENDPOINT}/payers/new.json`).then((r) => {
      console.log('r', r);
      this.setState({ locationChoices: r?.data?.result?.locations });
    });
  }

  public liveSearch(field: string, value: string): void {
    this.setState({
      filteredRecords: this.getState().records.filter((record) => {
        if (typeof record[field] === 'string') {
          return record[field].toLowerCase().includes(value.toLowerCase());
        }
        if (typeof record[field] === 'object' && Array.isArray(record[field])) {
          return record[field].find((x: string) => x.toLowerCase().includes(value.toLowerCase()));
        }

        // Field not on record, return false for now
        return false;
      }),
    });
  }
}
