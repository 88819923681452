import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import StudentForm from './StudentForm';
import StudentEmergencyContactsForm from './StudentEmergencyContactsForm';
import StudentAddressForm from './StudentAddressForm';
import ReviewStudentForm from './ReviewStudentForm';
import { studentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../student.store';
import { _translateEach } from '../../shared/utils/translation.utils';

const StudentFormModal: FunctionComponent = () => {
  const state = useStoreObservable<StudentState>(studentStore);
  const { formStepperIndex, formModalOpen, formValue, formStudentId } = state;

  const t = _translateEach({
    createTitle: 'students.formModal.createTitle',
    updateTitle: 'students.formModal.updateTitle',
    cancel: 'students.formModal.cancel',
    confirmLabelStep1: 'students.formModal.confirmLabelStep1',
    confirmLabelStep2: 'students.formModal.confirmLabelStep2',
    confirmLabelStep3: 'students.formModal.confirmLabelStep3',
    confirmLabelStep4: 'students.formModal.confirmLabelStep4',
    updateLabelStep1: 'students.formModal.updateLabelStep1',
    updateLabelStep2: 'students.formModal.updateLabelStep2',
    updateLabelStep3: 'students.formModal.updateLabelStep3',
  });

  const handleNextClick = (currentIndex: number): void => {
    if (currentIndex === 0) {
      studentStore.createOrUpdateStudent(formValue, formStudentId);
    } else if (currentIndex === 1) {
      studentStore.updateEmergencyContacts(formValue, formStudentId);
    } else if (currentIndex === 2) {
      studentStore.updateAddress(formValue, formStudentId);
    } else {
      studentStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <StudentForm />;
      case 1:
        return <StudentEmergencyContactsForm />;
      case 2:
        return <StudentAddressForm />;
      default:
        return <ReviewStudentForm />;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={4}
      isOpen={formModalOpen}
      title={formStudentId ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => studentStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: t.cancel, confirm: formStudentId ? t.updateLabelStep1 : t.confirmLabelStep1 },
        { cancel: t.cancel, confirm: formStudentId ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: t.cancel, confirm: formStudentId ? t.updateLabelStep3 : t.confirmLabelStep3 },
        { cancel: t.cancel, confirm: t.confirmLabelStep4 },
      ]}
      previousClicked={() => studentStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default StudentFormModal;
