import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { serviceStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const ServiceStudentForm: FunctionComponent = () => {
  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formStudentId,
    studentChoices,
  } = useStoreObservable(serviceStore);

  const classes = useStyles();

  React.useEffect(() => {
    serviceStore.fetchStudentChoices();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => serviceStore.setFormErrorAlertVisibility(false)}>
          Please select a student before continuing.
        </ErrorAlert>
      )}
      <SelectInput
        value={formStudentId}
        styleOverrides={classes.textInput}
        type="text"
        label="Student ID"
        errorMessage={errorValues?.patient_id}
        choices={studentChoices}
        defaultChoice={{ value: '', label: 'None' }}
        valueChanged={(value: string) => serviceStore.setStudentId(value)}
      />
    </form>
  );
};

export default ServiceStudentForm;
