import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import NewGoalForm from './NewGoalForm';
import GoalServiceForm from './GoalServiceForm';
import ReviewNewGoal from './ReviewNewGoal';
import { goalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { GoalState } from '../goal.store';

interface NewGoalModalProps {
  serviceId?: string;
  studentId?: string;
}

const NewGoalModal: FunctionComponent<NewGoalModalProps> = (props) => {
  const { serviceId, studentId } = props;
  const state = useStoreObservable<GoalState>(goalStore);
  const { formStepperIndex, createModalOpen, formValue, formServiceId } = state;

  const handleNextClick = (currentIndex: number): void => {
    switch (currentIndex) {
      case 0:
        return goalStore.setStepperIndex(1);
      case 1:
        return goalStore.createGoal(formValue, formServiceId || serviceId);
      default:
        return goalStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <GoalServiceForm studentId={studentId} />;
      case 1:
        return <NewGoalForm />;
      default:
        return <ReviewNewGoal />;
    }
  };

  React.useEffect(() => {
    if (serviceId) {
      goalStore.setStepperIndex(1);
    }
  }, [serviceId]);

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={3}
      isOpen={createModalOpen}
      title="New Goal"
      openStatusChanged={(isOpen) => goalStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: 'Cancel', confirm: 'Continue' }, // TODO translation
        { cancel: 'Cancel', confirm: 'Create Goal' }, // TODO translation
        { cancel: 'Cancel', confirm: 'Close' }, // TODO translation
      ]}
      previousClicked={() => goalStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default NewGoalModal;
