import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { schoolStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../school.store';
import ReviewField from '../../shared/components/ReviewField';

const ReviewSchool: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<SchoolState>(schoolStore);

  const t = _translateEach({
    title: 'schools.reviewSchool.title',
    section1: 'schools.reviewSchool.section1',
    section2: 'schools.reviewSchool.section2',
    section3: 'schools.reviewSchool.section3',
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">{t.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="School Name" value={formValues?.name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Abbreviation" value={formValues?.abbr} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">{t.section1}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Name" value={formValues?.abbr} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Email" value={formValues?.abbr} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Address 1" value={formValues?.address1} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Address 2" value={formValues?.address2} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="City" value={formValues?.city} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="State" value={formValues?.state} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Zip" value={formValues?.zip} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">{t.section2}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="State" value={formValues?.start_date} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Zip" value={formValues?.end_date} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">{t.section3}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Q1 Start Date" value={formValues?.q1_sd} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Q1 End Date" value={formValues?.q1_ed} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Q2 Start Date" value={formValues?.q2_sd} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Q2 End Date" value={formValues?.q2_ed} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Q3 Start Date" value={formValues?.q3_sd} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Q3 End Date" value={formValues?.q3_ed} />
      </Grid>
    </Grid>
  );
};

export default ReviewSchool;
