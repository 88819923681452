import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import AppointmentStudentForm from './AppointmentStudentForm';
import AppointmentForm from './AppointmentForm';
import ReviewAppointmentForm from './ReviewAppointmentForm';
import { appointmentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../appointment.store';
import { _translateEach } from '../../shared/utils/translation.utils';

const AppointmentFormModal: FunctionComponent = () => {
  const state = useStoreObservable<AppointmentState>(appointmentStore);
  const { formStepperIndex, formModalOpen, formValue, formStudentId, formAppointmentId } = state;

  const t = _translateEach({
    createTitle: 'appointments.formModal.createTitle',
    updateTitle: 'appointments.formModal.updateTitle',
    cancel: 'appointments.formModal.cancel',
    confirmLabelStep1: 'appointments.formModal.confirmLabelStep1',
    confirmLabelStep2: 'appointments.formModal.confirmLabelStep2',
    confirmLabelStep3: 'appointments.formModal.confirmLabelStep3',
    updateLabelStep1: 'appointments.formModal.updateLabelStep1',
    updateLabelStep2: 'appointments.formModal.updateLabelStep2',
  });

  const handleNextClick = (currentIndex: number): void => {
    switch (currentIndex) {
      case 0:
        return appointmentStore.setStepperIndex(1);
      case 1:
        appointmentStore.createOrUpdateAppointment(formValue, formStudentId, formAppointmentId);
        return null;
      default:
        return appointmentStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <AppointmentStudentForm />;
      case 1:
        return <AppointmentForm />;
      default:
        return <ReviewAppointmentForm />;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={3}
      isOpen={formModalOpen}
      title={formAppointmentId ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => appointmentStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: 'Cancel', confirm: formAppointmentId ? t.updateLabelStep1 : t.confirmLabelStep1 },
        { cancel: 'Cancel', confirm: formAppointmentId ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: 'Cancel', confirm: t.confirmLabelStep3 },
      ]}
      previousClicked={() => appointmentStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default AppointmentFormModal;
