import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import TextInput from '../../../shared/components/form/TextInput';

interface GradeLevelFormProps {
  formValues: Dispatch<SetStateAction<boolean>>;
  onFormValueChanged: Dispatch<Record<string, string | number>>;
}

const NewGradeLevelForm: FunctionComponent<GradeLevelFormProps> = (props) => {
  const { formValues, onFormValueChanged } = props;

  const handleFormValueChange = (key: string, value: string) => {
    onFormValueChanged({ ...formValues, [key]: value });
  };

  return (
    <form noValidate autoComplete="off">
      <TextInput
        value={formValues.name}
        label="Grade Level Name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormValueChange('name', e.target.value)}
      />
    </form>
  );
};

export default NewGradeLevelForm;
