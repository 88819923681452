import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import RenewalStudentForm from './RenewalStudentForm';
import RenewalForm from './RenewalForm';
import ReviewRenewal from './ReviewRenewal';
import { renewalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../renewal.store';
import { _translateEach } from '../../shared/utils/translation.utils';

const RenewalFormModal: FunctionComponent = () => {
  const state = useStoreObservable<RenewalState>(renewalStore);
  const { formStepperIndex, formModalOpen, formValue, formStudentId, formRenewalId } = state;

  const t = _translateEach({
    createTitle: 'renewal.formModal.createTitle',
    updateTitle: 'renewal.formModal.updateTitle',
    cancel: 'renewal.formModal.cancel',
    confirmLabelStep1: 'renewal.formModal.confirmLabelStep1',
    confirmLabelStep2: 'renewal.formModal.confirmLabelStep2',
    confirmLabelStep3: 'renewal.formModal.confirmLabelStep3',
    updateLabelStep2: 'renewal.formModal.updateLabelStep2',
  });

  const handleNextClick = (currentIndex: number): void => {
    if (currentIndex === 0) {
      renewalStore.setStepperIndex(1);
    } else if (currentIndex === 1) {
      renewalStore.createOrUpdateRenewal(formValue, formStudentId, formRenewalId);
    } else {
      renewalStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <RenewalStudentForm />;
      case 1:
        return <RenewalForm />;
      default:
        return <ReviewRenewal />;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={3}
      isOpen={formModalOpen}
      title={formStudentId ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => renewalStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: t.cancel, confirm: t.confirmLabelStep1 },
        { cancel: t.cancel, confirm: formStudentId ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: t.cancel, confirm: t.confirmLabelStep3 },
      ]}
      previousClicked={() => renewalStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default RenewalFormModal;
