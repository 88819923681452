import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { Student, StudentChoicesResponse, StudentFormValues, StudentFormErrors, StudentResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';
import { alertStore } from '../shared/singletons';
import { Colors } from '../shared/style/colors';

export class StudentState {
  records: Student[];
  student: Student;
  recordsLoading: boolean;
  studentLoading: boolean;
  filteredRecords: Student[];

  formValue: StudentFormValues;
  formErrors: StudentFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  formStudentId: string;

  schoolChoices: SelectOption[];
  locationChoices: SelectOption[];
  gradeLevelChoices: SelectOption[];
  statusChoices: SelectOption[];
  userChoices: SelectOption[];
  statusColors: Record<string, string>;
  filterValues: Record<string, string>;

  static create(props: Partial<StudentState>): StudentState {
    const defaults: StudentState = {
      records: [],
      student: {},
      recordsLoading: false,
      studentLoading: false,
      filteredRecords: null,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      formStudentId: '',

      schoolChoices: [],
      locationChoices: [],
      gradeLevelChoices: [],
      userChoices: [],
      statusChoices: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],

      statusColors: {
        Active: Colors.Success,
        Inactive: Colors.Red,
      },
      filterValues: {},
    };
    return Object.assign(new StudentState(), defaults, props || {});
  }
}

export class StudentStore extends BaseStore<StudentState> {
  constructor() {
    super(StudentState.create({}));
  }

  public fetchRecords(params = ''): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/patients.json?${params}`, { withCredentials: true })
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchStudent(studentId: string): void {
    this.setState({ studentLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((student) => {
        this.setState({ student, studentLoading: false });
      })
      .catch(() => {
        this.setState({ studentLoading: false });
      });
  }

  public async createOrUpdateStudent(formValue: StudentFormValues, studentId: string): Promise<void> {
    try {
      const response = await axios[studentId ? 'put' : 'post']<string, StudentResponse>(
        `${API_ENDPOINT}/patients${studentId ? `/${studentId}` : ''}.json`,
        {
          patient: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      this.fetchRecords(); // TODO optimize?
      this.setState({ formStepperIndex: 1, formStudentId: response.data.result.id });
    } catch (error) {
      this.setState({
        formStepperIndex: 0, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateEmergencyContacts(formValue: StudentFormValues, studentId: string): void {
    axios
      .put<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
        patient: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        this.setState({ formStepperIndex: 2, formStudentId: response.data.result.id });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response?.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateAddress(formValue: StudentFormValues, studentId: string): void {
    axios
      .put<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
        patient: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.setState({ formStepperIndex: 3 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteStudent(studentId: string): void {
    axios
      .delete<string, StudentResponse>(`${API_ENDPOINT}/patients/${studentId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('students.alert.delete.error');
      });
  }

  public setFormValue(formValue: StudentFormValues): void {
    this.setState({ formValue });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public setEditModalOpen(isOpen: boolean, studentId: string): void {
    const student = this.getState().records.find((record) => record.id === studentId);

    if (student) {
      const formValue = {
        first_name: student.first_name,
        last_name: student.last_name,
        payer_id: student.payer_id,
        teacher: student.teacher,
        room_number: student.room_number,
        birthdate: student.birthdate,
        location_id: student.location_id,
        grade_level_id: student.grade_level_id,
        gaurdian_name: student.primary_guardian,
        gaurdian_contact_primary: student.primary_guardian_phone_contact1,
        gaurdian_contact_secondary: student.primary_guardian_phone_contact2,
        gaurdian_contact_alternate: student.primary_guardian_phone_contact3,
        email_gaurdian: student.primary_guardian_email,
        emergency_name: student.emergency_name,
        emergency_relationship: student.emergency_relationship,
        emergency_phone: student.emergency_phone,
        address_line1: student.address_line1,
        address_line2: student.address_line2,
        address_city: student.address_city,
        address_state: student.address_state,
        address_zip: student.address_zip,
      };

      this.setState({ formModalOpen: isOpen, formStudentId: studentId, formValue });
    }
  }

  public setStudentId(studentId: string): void {
    this.setState({ formStudentId: studentId });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public fetchChoices(): void {
    axios.get<string, StudentChoicesResponse>(`${API_ENDPOINT}/patients/new.json`).then((r: StudentChoicesResponse) => {
      const { schools, grade_levels, locations, users } = r.data?.result;

      this.setState({
        schoolChoices: schools,
        gradeLevelChoices: grade_levels,
        locationChoices: locations,
        userChoices: users,
      });
    });
  }

  public liveSearch(field: string, value: string): void {
    this.setState({
      filteredRecords: this.getState().records.filter((record) => {
        if (typeof record[field] === 'string') {
          return record[field].toLowerCase().includes(value.toLowerCase());
        }

        if (typeof record[field] === 'object' && Array.isArray(record[field])) {
          return record[field].find((x: string) => x.toLowerCase().includes(value.toLowerCase()));
        }

        // Field not on record, return false for now
        return false;
      }),
    });
  }
}
