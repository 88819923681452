import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceInformation from '../../services/components/ServiceInformation';
import AttachmentsList from '../../attachments/components/AttachmentsList';
import CommunicationsList from '../../communications/components/CommunicationsList';
import AppointmentQuarters from './AppointmentQuarters';
import { Appointment } from '../model';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

interface AppointmentServiceDetailsProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

const AppointmentServiceDetails: FunctionComponent<AppointmentServiceDetailsProps> = (
  props: AppointmentServiceDetailsProps,
) => {
  const { appointment, appointmentLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ServiceInformation service={appointment?.service} serviceLoading={appointmentLoading} />
        </Grid>
        <Grid item xs={6}>
          <AttachmentsList attachments={appointment.attachments} isLoading={appointmentLoading} />
        </Grid>
        <Grid item xs={12}>
          <AppointmentQuarters quarters={appointment.quarters} appointmentLoading={appointmentLoading} />
        </Grid>
        {/* <Grid item xs={6}>
          <CommentsList
            comments={appointment.comments}
            parentType="appointments"
            parentId={appointment.id}
            isLoading={appointmentLoading}
          />
        </Grid> */}
        <Grid item xs={12}>
          <CommunicationsList
            communications={appointment.communications}
            parentType="appointments"
            parentId={appointment.id}
            isLoading={appointmentLoading}
            showCreateButton={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AppointmentServiceDetails;
