import React, { FunctionComponent, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Renewal } from '../model';
import RenewalRowMenu from './RenewalRowMenu';
import RenewalFormModal from './RenewalFormModal';
import { renewalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../renewal.store';
import SearchBar, { FieldProps, FilterProps } from '../../shared/components/SearchBar';
import RenewalStageChangeModal from './RenewalStageChangeModal';

interface RenewalsListProps {
  records: Renewal[];
  recordsLoading: boolean;
  showCreateButton?: boolean;
  studentId?: string;
  showFilterBar?: boolean;
}

const RenewalsList: FunctionComponent<RenewalsListProps> = (props) => {
  const { records, recordsLoading, showCreateButton = false, studentId = null, showFilterBar = false } = props;
  const history = useHistory();
  const { statusColors, renewalStageChoices, renewalTypeChoices, ownerChoices, statusChoices } = useStoreObservable<
    RenewalState
  >(renewalStore);

  useEffect(() => {
    renewalStore.fetchChoices(studentId);
  }, [studentId]);

  const fields: FieldProps[] = [
    {
      selector: 'stage',
      label: 'Renewal Stage',
      type: 'select',
      options: renewalStageChoices,
    },
    {
      selector: 'type',
      label: 'Renewal Type',
      type: 'select',
      options: renewalTypeChoices,
    },
    {
      selector: 'student_id',
      label: 'Student ID',
      type: 'text',
    },
  ];

  const filters: FilterProps[] = [
    {
      selector: 'ransack[user_id_eq]',
      label: 'Owner',
      type: 'select',
      options: ownerChoices,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'select',
      options: statusChoices,
    },
  ];

  const columns: IDataTableColumn<Renewal>[] = [
    {
      name: 'STUDENT ID',
      selector: 'student_slug',
      sortable: true,
    },
    {
      name: 'STUDENT NAME',
      selector: 'student_name',
      sortable: true,
    },
    {
      name: 'TYPE',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'OWNER',
      selector: 'owner',
      sortable: true,
    },
    {
      name: 'RENEWAL DATE',
      selector: 'renewal_date',
      sortable: true,
    },
    {
      name: 'STAGE',
      selector: 'stage',
      sortable: true,
    },
    {
      name: 'TIMELINE',
      selector: 'timeline',
      sortable: true,
    },
    {
      name: 'STATUS',
      selector: 'status',
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.status],
          }}
        >
          {row.status}
        </div>
      ),
      sortable: true,
    },
    {
      cell: (renewal: Renewal) => <RenewalRowMenu renewal={renewal} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  useEffect(() => {
    if (studentId) {
      renewalStore.setModalOpenWithStudentId(true, studentId);
    }
  }, [studentId]);

  return recordsLoading ? (
    <Box>
      <Typography variant="h1" component="h1">
        Renewals
      </Typography>
      <br />
      <LoadingSpinner />
    </Box>
  ) : (
    <Box>
      <RenewalFormModal />
      <RenewalStageChangeModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Renewals
          </Typography>
        </Grid>
        <Grid item>
          {showCreateButton && (
            <Button variant="contained" color="primary" onClick={() => renewalStore.setModalOpen(true)}>
              Create New Renewal +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <SearchBar fields={fields} filters={filters} store={renewalStore} />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            striped
            onRowClicked={(renewal: Renewal) => history.push(`/students/${renewal.student_id}/renewals/${renewal.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RenewalsList;
