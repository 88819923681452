import React, { FunctionComponent } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InformationField from '../../shared/components/InformationField';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SessionNoteState } from '../sessionNote.store';
import { sessionNoteStore } from '../../shared/singletons';
import { Goal } from '../../goals/model';
import { SelectOption } from '../../shared/common.model';

interface DirectServiceFormProps {
  goal: Goal;
  sessionNoteFormValues: any;
  handleFormValueChange: any;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  link: {
    textDecoration: 'none',
  },
});

const DirectServiceForm: FunctionComponent<DirectServiceFormProps> = (props) => {
  const { goal, sessionNoteFormValues, handleFormValueChange } = props;
  const classes = useStyles();

  const {
    errorValues,
    prompts,
    percentageChoices,
    assistanceChoices,
  } = useStoreObservable<SessionNoteState>(sessionNoteStore);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InformationField label="Objective" value={goal.objectives} />
          </Grid>
          <Grid item xs={12}>
            <InformationField label="SDI Item" value={null} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SelectInput
                value={sessionNoteFormValues(goal.id)?.percent_string}
                styleOverrides={classes.textInput}
                choices={percentageChoices}
                label="Percentage Complete"
                errorMessage={errorValues?.percent_string}
                valueChanged={(value: string) => handleFormValueChange(goal.id, 'percent_string', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                value={sessionNoteFormValues(goal.id)?.assistance}
                styleOverrides={classes.textInput}
                choices={assistanceChoices}
                label="Assistance"
                errorMessage={errorValues?.assistance}
                valueChanged={(value: string) => handleFormValueChange(goal.id, 'assistance', value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3" component="h3">
                    Prompts Utilized
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Grid container>
                      {prompts.map((prompt: SelectOption) => (
                        <Grid key={prompt.label} item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedC"
                                color="primary"
                                onChange={() => handleFormValueChange(goal.id, 'prompts', prompt.value)}
                              />
                            }
                            label={prompt.value}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                value={sessionNoteFormValues(goal.id)?.content}
                styleOverrides={classes.textInput}
                shrinkLabel
                label="Note Content (Optional)"
                multiline
                rows={8}
                errorMessage={errorValues?.content}
                valueChanged={(value: string) => handleFormValueChange(goal.id, 'content', value)}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default DirectServiceForm;
