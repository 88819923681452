import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Axios from 'axios';
import { API_ENDPOINT } from '../../../config/env';

const API = Axios.create({
  baseURL: API_ENDPOINT,
  timeout: 1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const refreshAuthLogic = (failedRequest) =>
  API.get(`users/refreshJwt`)
    .then((tokenRefreshResponse) => Promise.resolve())
    .catch((tokenRefreshResponse) => Promise.reject());

const options = {
  statusCodes: [400],
};

createAuthRefreshInterceptor(API, refreshAuthLogic, options);

export default API;
