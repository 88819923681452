import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';

interface RenewalTypeRowMenuProps {
  row: { id?: number };
}

const RenewalTypeRowMenu: FunctionComponent<RenewalTypeRowMenuProps> = (props: RenewalTypeRowMenuProps) => {
  const { row } = props;

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={() => console.log(row)}>Edit Renewal Type</MenuItem>
      <MenuItem onClick={() => null}>Destroy Renewal Type</MenuItem>
    </CustomMaterialMenu>
  );
};

export default RenewalTypeRowMenu;
