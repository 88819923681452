import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import SchoolForm from './SchoolForm';
import SchoolDatesForm from './SchoolDatesForm';
import SchoolLocationsForm from './SchoolLocationsForm';
import ReviewSchool from './ReviewSchool';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../school.store';
import { schoolStore } from '../../shared/singletons';
import { _translateEach } from '../../shared/utils/translation.utils';

const SchoolModal: FunctionComponent = () => {
  const state = useStoreObservable<SchoolState>(schoolStore);
  const { formStepperIndex, formModalOpen, formValue, formSchoolId } = state;

  const t = _translateEach({
    createTitle: 'school.formModal.createTitle',
    updateTitle: 'school.formModal.updateTitle',
    cancel: 'school.formModal.cancel',
    confirmLabelStep1: 'school.formModal.confirmLabelStep1',
    confirmLabelStep2: 'school.formModal.confirmLabelStep2',
    confirmLabelStep3: 'school.formModal.confirmLabelStep3',
    confirmLabelStep4: 'school.formModal.confirmLabelStep4',
    updateLabelStep1: 'school.formModal.updateLabelStep1',
    updateLabelStep2: 'school.formModal.updateLabelStep2',
    updateLabelStep3: 'school.formModal.updateLabelStep3',
  });

  const handleNextClick = (currentIndex: number): void => {
    if (currentIndex === 0) {
      schoolStore.createOrUpdateSchool(formValue, formSchoolId);
    } else if (currentIndex === 1) {
      schoolStore.updateDates(formValue, formSchoolId);
    } else if (currentIndex === 2) {
      schoolStore.updateLocations(formValue, formSchoolId);
    } else {
      schoolStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <SchoolForm />;
      case 1:
        return <SchoolDatesForm />;
      case 2:
        return <SchoolLocationsForm />;
      default:
        return <ReviewSchool />;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={4}
      isOpen={formModalOpen}
      title={formSchoolId ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => schoolStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: t.cancel, confirm: formSchoolId ? t.updateLabelStep1 : t.confirmLabelStep1 },
        { cancel: t.cancel, confirm: formSchoolId ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: t.cancel, confirm: formSchoolId ? t.updateLabelStep3 : t.confirmLabelStep3 },
        { cancel: t.cancel, confirm: t.confirmLabelStep4 },
      ]}
      previousClicked={() => schoolStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default SchoolModal;
