import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './index.css';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Sidebar from './modules/shared/components/Sidebar';
import AppBar from './modules/shared/components/AppBar';
import AppAlert from './modules/shared/components/AppAlert';
import Routes from './routes';
import { UserContext } from './modules/shared/contexts/userContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    width: `calc(100% - 150px)`,
    marginLeft: '150px',
    padding: '80px 30px',
  },
}));

const App: React.FC = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <Container className={classes.root} color="background" maxWidth="xl">
      <Box className={classes.box}>
        <AppBar />
        <Sidebar />
        <AppAlert />
        <main role="main" className={classes.content}>
          <Routes key={user ? user.id : null} />
        </main>
      </Box>
    </Container>
  );
};

export default App;
