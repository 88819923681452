import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider, ListItemIcon, MenuItem, Typography, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SchoolIcon from '@material-ui/icons/School';
import LayersIcon from '@material-ui/icons/Layers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import ChatIcon from '@material-ui/icons/Chat';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ForumIcon from '@material-ui/icons/Forum';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { studentStore } from '../../shared/singletons';
import { Student } from '../model';
import { UserContext } from '../../shared/contexts/userContext';

interface StudentRowMenuProps {
  student: Student;
}

const useStyles = makeStyles({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
  listItemIcon: {
    minWidth: '24px',
  },
});

const StudentRowMenu: FunctionComponent<StudentRowMenuProps> = (props: StudentRowMenuProps) => {
  const classes = useStyles();
  const { student } = props;
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const t = _translateEach({
    viewStudent: 'students.rowMenu.viewStudent',
    editStudent: 'students.rowMenu.editStudent',
    deleteStudent: 'students.rowMenu.deleteStudent',
    deleteConfirm: 'students.rowMenu.deleteConfirm',
    viewProfile: 'students.rowMenu.viewProfile',
    viewServices: 'students.rowMenu.viewServices',
    viewAppointments: 'students.rowMenu.viewAppointments',
    viewRenewals: 'students.rowMenu.viewRenewals',
    viewGoals: 'students.rowMenu.viewGoals',
    addServices: 'students.rowMenu.addServices',
    addAppointments: 'students.rowMenu.addAppointments',
    addRenewals: 'students.rowMenu.addRenewals',
    addComments: 'students.rowMenu.addComments',
    addAttachments: 'students.rowMenu.addAttachments',
    addCommunications: 'students.rowMenu.addCommunications',
  });

  const handleDestroyFormSubmit = () => {
    studentStore.deleteStudent(student.id);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>

      <MenuItem onClick={() => history.push(`/students/${student.id}/profile`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <SchoolIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewStudent}</Typography>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/students/${student.id}/services`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <LayersIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewServices}</Typography>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/students/${student.id}/appointments`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DateRangeIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewAppointments}</Typography>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/students/${student.id}/renewals`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <AvTimerIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewRenewals}</Typography>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/students/${student.id}/goals`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewGoals}</Typography>
      </MenuItem>

      <Box>
        {currentUserHasRole('admin') && (
          <Box>
            <Divider />
            <MenuItem onClick={() => studentStore.setEditModalOpen(true, student.id)}>
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.editStudent}</Typography>
            </MenuItem>
          </Box>
        )}

        {currentUserHasRole('admin') && (
          <Box>
            <Divider />
            <MenuItem onClick={() => history.push(`/students/${student.id}/services/create`)}>
              <ListItemIcon className={classes.listItemIcon}>
                <LayersIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addServices}</Typography>
            </MenuItem>
            <MenuItem onClick={() => history.push(`/students/${student.id}/appointments/create`)}>
              <ListItemIcon className={classes.listItemIcon}>
                <DateRangeIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addAppointments}</Typography>
            </MenuItem>
            <MenuItem onClick={() => history.push(`/students/${student.id}/renewals/create`)}>
              <ListItemIcon className={classes.listItemIcon}>
                <AvTimerIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addRenewals}</Typography>
            </MenuItem>
            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <ChatIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addComments}</Typography>
            </MenuItem>
            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <AttachFileIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addAttachments}</Typography>
            </MenuItem>
            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <ForumIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addCommunications}</Typography>
            </MenuItem>
          </Box>
        )}
        {currentUserHasRole('admin') && (
          <MenuItem onClick={() => setDestroyModalOpen(true)}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">{t.deleteStudent}</Typography>
          </MenuItem>
        )}
      </Box>
    </CustomMaterialMenu>
  );
};

export default StudentRowMenu;
