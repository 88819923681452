export enum Colors {
  PurplePrimary = '#424E7A',
  PurpleHover = '#404E86',
  PurpleSecondary = '#735CDD',
  PurpleThree = '#A52ACB',
  TertiaryOne = '#D8D8D8',
  TertiaryTwo = '#F1EEFB',
  BackgroundWhite = '#F4F4F4',
  White = '#ffffff',
  Red = '#F44F64',
  Orange = '#F5A623',
  Success = '#3BB50A',
  GreyText = '#7D7D7D',
  BlackText = '#333333',
  BabyBlue = '#38ADFE',
}
