import React, { FunctionComponent, useState } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import NewGradeLevelModal from './NewGradeLevelModal';
import GradeLevelRowMenu from './GradeLevelRowMenu';
import { IDataTableColumn } from '../../../shared/components/DataTable/DataTable/model';
import DataTable from '../../../shared/components/DataTable';
import { GradeLevel } from '../model';

const columns: IDataTableColumn<Record<string, unknown>>[] = [
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
  },
  {
    cell: (row) => <GradeLevelRowMenu row={row} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

type GradeLevelsListProps = { records: GradeLevel[]; isLoading: boolean };

const GradeLevelsList: FunctionComponent<GradeLevelsListProps> = (props: GradeLevelsListProps) => {
  const { records = [], isLoading = false } = props;

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <NewGradeLevelModal isOpen={isModalOpen} openStatusChanged={setModalOpen} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Grade Levels
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
            Create New Grade Level +
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable noHeader columns={columns} data={records} striped highlightOnHover pointerOnHover />
        </CardContent>
      </Card>
    </Box>
  );
};

export default GradeLevelsList;
