import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Service } from '../model';

interface ServiceInformationProps {
  service: Service;
  serviceLoading: boolean;
}

const ServiceInformation: FunctionComponent<ServiceInformationProps> = (props) => {
  const { service, serviceLoading } = props;

  return serviceLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Service Information
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label="Phase" value={service?.phase} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Status" value={service?.status} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Stage" value={service?.stage} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Last Updated" value={service?.updated_at} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Owner" value={service?.owner} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Initial Referral Date" value={service?.initial_referral_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Frequency" value={service?.frequency} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Start Date" value={service?.start_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="End Date" value={service?.end_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="ESY Frequency" value={service?.esy_frequency} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="ESY Start Date" value={service?.esy_start_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="ESY End Date" value={service?.esy_end_date} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServiceInformation;
