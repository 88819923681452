import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RenewalsList from '../RenewalsList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../../renewal.store';
import { renewalStore } from '../../../shared/singletons';
import { RenewalRouteParams } from '../../model';

export type RenewalsListPageProps = Record<string, unknown>;

const RenewalsListPage: FunctionComponent<RenewalsListPageProps> = () => {
  const renewalState = useStoreObservable<RenewalState>(renewalStore);
  const { filteredRecords, records, recordsLoading } = renewalState;
  const { studentId } = useParams<RenewalRouteParams>();

  useEffect(() => {
    renewalStore.fetchRecords();
  }, []);

  return (
    <RenewalsList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      studentId={studentId}
      showCreateButton={false}
      showFilterBar
    />
  );
};

export default RenewalsListPage;
