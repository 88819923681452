import React, { FunctionComponent, useEffect } from 'react';
import UsersList from '../UsersList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { UserState } from '../../user.store';
import { userStore } from '../../../shared/singletons';

export type UsersListPageProps = Record<string, unknown>;

const UsersListPage: FunctionComponent<UsersListPageProps> = () => {
  const userState = useStoreObservable<UserState>(userStore);
  const { filteredRecords, records, recordsLoading } = userState;

  useEffect(() => {
    userStore.fetchRecords();
  }, []);

  return <UsersList records={filteredRecords || records} recordsLoading={recordsLoading} showFilterBar />;
};

export default UsersListPage;
