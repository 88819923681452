import React, { FunctionComponent, ChangeEvent } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import SchoolDetailsTab from './SchoolDetailsTab';
import ListingFeesList from '../../listingFees/components/ListingFeesList';
import { School } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../shared/contexts/userContext';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface SchoolDetailProps {
  school: School;
  schoolLoading: boolean;
  tab?: string;
}

const SchoolDetail: FunctionComponent<SchoolDetailProps> = (props) => {
  const { school, schoolLoading, tab } = props;
  const [value, setValue] = React.useState(0);
  const { currentUserHasRole } = React.useContext(UserContext);

  const links: BreadcrumbLink[] = [
    {
      href: '/schools',
      text: 'SCHOOLS',
    },
    {
      href: null,
      text: school.name,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (tab) {
      setValue(['details', 'listing_fees'].indexOf(tab));
    }
  }, [tab]);

  return schoolLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {school.name}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button variant="contained" color="primary" onClick={() => null}>
              Add New Listing Fee
            </Button>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels value={value} onChange={handleChange} aria-label="student tabs">
          <TabLabel label="Details" other={a11yProps(0)} />
          {currentUserHasRole('admin') && <TabLabel label="Listing Fees" other={a11yProps(1)} />}
        </TabLabels>
        <TabPanel value={value} index={0}>
          <SchoolDetailsTab school={school} schoolLoading={schoolLoading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {currentUserHasRole('admin') && (
            <ListingFeesList listingFees={school.listing_fees} isLoading={schoolLoading} />
          )}
        </TabPanel>
      </div>
    </div>
  );
};

export default SchoolDetail;
