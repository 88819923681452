import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Student } from '../model';

interface StudentInformationProps {
  student: Student;
  studentLoading: boolean;
}

const StudentInformation: FunctionComponent<StudentInformationProps> = (props) => {
  const { student, studentLoading } = props;

  return studentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          Student Information
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label="Status" value={student?.status} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Name" value={student?.student_name} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="ID" value={student?.slug} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Referral Date" value={student?.referral_date} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="School" value={student?.school} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Preferred Location" value={student?.location} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Teacher" value={student?.teacher} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Grade" value={student?.grade} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Room Number" value={student?.room_number} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Address" value={student?.address} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Email" value={student?.email} />
          </Grid>
          <Grid item xs={4}>
            <InformationField label="Date of Birth" value={student?.birthdate} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentInformation;
