import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import ReviewField from '../../shared/components/ReviewField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Appointment } from '../../appointments/model';
import { _translateEach } from '../../shared/utils/translation.utils';
import { SessionNote } from '../model';

interface SessionNoteHistoryProps {
  appointment: Appointment;
  appointmentLoading: boolean;
}

const SessionNoteHistory: FunctionComponent<SessionNoteHistoryProps> = (props) => {
  const { appointment, appointmentLoading } = props;
  const [sessionNotes, setSessionNotes] = useState([]);

  useEffect(() => {
    if (appointment?.past_session_notes?.length > 0) {
      // set to first set of sesssion notes by default
      setSessionNotes(appointment.past_session_notes[0]);
    }
  }, [appointment]);

  const handlePrevious = () => {
    // use indexOf to get index of session notes on appointment
    let nextIndex = appointment.past_session_notes.indexOf(sessionNotes) - 1;

    if (nextIndex < 0) {
      nextIndex = appointment.past_session_notes.length - 1;
    }

    setSessionNotes(appointment.past_session_notes[nextIndex]);
  };

  const handleNext = () => {
    // use indexOf to get index of session notes on appointment
    let nextIndex = appointment.past_session_notes.indexOf(sessionNotes) + 1;

    if (nextIndex === appointment.past_session_notes.length) {
      nextIndex = 0;
    }

    setSessionNotes(appointment.past_session_notes[nextIndex]);
  };

  const t = _translateEach({
    noRecords: 'sessions.noteHistory.noRecords',
    next: 'sessions.noteHistory.next',
    previous: 'sessions.noteHistory.previous',
  });

  return appointmentLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid container>
      {appointment?.past_session_notes?.length > 0 ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {sessionNotes?.map((session_note: SessionNote, index: number) => (
                  <Grid item xs={12} key={session_note.id}>
                    <Grid container spacing={2}>
                      {index === 0 && (
                        <Grid container justify="space-between">
                          <Grid item>
                            <Grid container spacing={2}>
                              <Grid item>
                                <ReviewField label="Appointment Date" value={session_note?.appointment_date} />
                              </Grid>
                              <Grid item>
                                <ReviewField label="Date Created" value={session_note?.created_at} />
                              </Grid>
                              <Grid item>
                                <ReviewField label="Created By" value={session_note?.created_by} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Button variant="contained" color="primary" onClick={handlePrevious}>
                                  {t.previous}
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                  {t.next}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography variant="h3">{`Service Goal ${index + 1}`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Percent" value={session_note.percent_string} />
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Level of Assistance" value={session_note.assistance} />
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Comment" value={session_note.content} />
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Prompts" value={session_note.prompts} />
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Participation" value={session_note.participation} />
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Transitions" value={session_note.transitions} />
                      </Grid>
                      <Grid item xs={12}>
                        <InformationField label="Subjective Comment" value={session_note.subjective_comment} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center" alignItems="center">
          <Typography paragraph>{t.noRecords}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SessionNoteHistory;
