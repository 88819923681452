import React, { FunctionComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { _translateEach } from '../../shared/utils/translation.utils';
import { userStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserState } from '../user.store';
import ReviewField from '../../shared/components/ReviewField';

const ReviewUserForm: FunctionComponent = () => {
  const { formValue: formValues } = useStoreObservable<UserState>(userStore);

  const t = _translateEach({
    title: 'users.reviewUser.title',
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">{t.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Email" value={formValues?.email} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Username" value={formValues?.username} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="First Name" value={formValues?.first_name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Last Name" value={formValues?.last_name} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Locations" value={formValues?.location_ids?.toString()} />
      </Grid>
      <Grid item xs={12}>
        <ReviewField label="Roles" value={formValues?.role_ids?.toString()} />
      </Grid>
    </Grid>
  );
};

export default ReviewUserForm;
