import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { Typography } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';

interface AddRoleModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddRoleModal: FunctionComponent<AddRoleModalProps> = (props) => {
  return (
    <ConfirmCancelModal
      isOpen={props.isOpen}
      title="Create New Role"
      openStatusChanged={(isOpen: boolean) => props.setOpen(isOpen)}
      cancelLabel="Cancel"
      confirmLabel="Create New Role"
      hasCancelButton
      hasConfirmButton
      width="768px"
    >
      <Typography variant="h1" component="h1">
        Modal body
      </Typography>
    </ConfirmCancelModal>
  );
};

export default AddRoleModal;
