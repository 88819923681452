import React, { FunctionComponent } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import InformationField from '../../shared/components/InformationField';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { appointmentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../appointment.store';
import { _translateEach } from '../../shared/utils/translation.utils';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const RescheduleAppointmentModal: FunctionComponent = () => {
  const state = useStoreObservable<AppointmentState>(appointmentStore);
  const { rescheduleModalOpen, formValue, formErrors, formStudentId, appointment, locationChoices } = state;
  const classes = useStyles();

  const t = _translateEach({
    title: 'appointments.rescheduleModal.title',
    update: 'appointments.rescheduleModal.update',
  });

  const handleFormValueChange = (key: string, value: string) => {
    appointmentStore.setFormValue({ ...formValue, [key]: value });
  };

  React.useEffect(() => {
    if (formStudentId) {
      appointmentStore.fetchChoices(formStudentId);
    }
  }, [formStudentId]);

  const handleFormSubmit = () => {
    appointmentStore.createOrUpdateAppointment(formValue, formStudentId, appointment.id);
  };

  return (
    <ConfirmCancelModal
      isOpen={rescheduleModalOpen}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => appointmentStore.setRescheduleModalOpen(isOpen, appointment.id)}
    >
      <Box p={2}>
        <Grid container spacing={6}>
          <Grid item>
            <InformationField label="Student" value={appointment?.student_name} />
          </Grid>
          <Grid item>
            <InformationField label="ID" value={appointment?.student_slug} />
          </Grid>
          <Grid item>
            <InformationField label="Service Type" value={appointment?.service_type} />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h2">Appointment Details</Typography>
        <Grid container>
          <Grid item xs={10}>
            <TextInput
              value={formValue?.schedule_date}
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="Start Date"
              errorMessage={formErrors?.schedule_date}
              valueChanged={(value: string) => handleFormValueChange('schedule_date', value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              value={formValue?.start_time}
              styleOverrides={classes.textInput}
              type="time"
              shrinkLabel
              label="Start Time"
              errorMessage={formErrors?.start_hour}
              valueChanged={(value: string) => handleFormValueChange('start_time', value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              value={formValue?.end_time}
              styleOverrides={classes.textInput}
              type="time"
              shrinkLabel
              label="End Time"
              errorMessage={formErrors?.end_hour}
              valueChanged={(value: string) => handleFormValueChange('end_time', value)}
            />
          </Grid>
          <Grid item xs={10}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => handleFormValueChange('update_all_occ', e.target.value)}
                  name="update_all_occ"
                  value="2" // This "2" is a carry-over from the previous site and is needed for now
                />
              }
              label="Reschedule all future appointments"
            />
          </Grid>
          <Grid item xs={10}>
            <SelectInput
              value={formValue?.location_id}
              styleOverrides={classes.textInput}
              type="text"
              label="Location"
              errorMessage={formErrors?.location_id}
              valueChanged={(value: string) => handleFormValueChange('location_id', value)}
              choices={locationChoices}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              value={formValue?.comment}
              styleOverrides={classes.textInput}
              multiline
              rows={8}
              label="Comment"
              errorMessage={formErrors?.comment}
              valueChanged={(value: string) => handleFormValueChange('comment', value)}
            />
          </Grid>
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default RescheduleAppointmentModal;
