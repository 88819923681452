import React, { FunctionComponent } from 'react';
import { Typography, Box, Grid, Button, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { School } from '../model';
import SchoolRowMenu from './SchoolRowMenu';
import SchoolModal from './SchoolModal';
import { schoolStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../school.store';
import SearchBar, { FieldProps } from '../../shared/components/SearchBar';
import { UserContext } from '../../shared/contexts/userContext';

interface SchoolsList {
  records: School[];
  recordsLoading: boolean;
  showFilterBar: boolean;
}

const SchoolsList: FunctionComponent<SchoolsList> = (props) => {
  const { records, recordsLoading, showFilterBar = false } = props;
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const { locationChoices } = useStoreObservable<SchoolState>(schoolStore);

  React.useEffect(() => {
    schoolStore.fetchLocations();
  }, []);

  const columns: IDataTableColumn<School>[] = [
    {
      name: 'SCHOOL NAME',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'ABBREVIATION',
      selector: 'abbreviation',
      sortable: true,
    },
    {
      name: 'LOCATIONS',
      selector: 'locations',
      sortable: true,
    },
    {
      name: 'TOTAL STUDENTS',
      selector: 'total_students',
      sortable: true,
    },
    {
      name: 'TOTAL SERVICES',
      selector: 'total_services',
      sortable: true,
    },
    {
      cell: (school: School) => <SchoolRowMenu school={school} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  const fields: FieldProps[] = [
    {
      selector: 'name',
      label: 'School Name',
      type: 'text',
    },
    {
      selector: 'abbreviation',
      label: 'School Abbreviation',
      type: 'text',
    },
    {
      selector: 'locations',
      label: 'Locations',
      type: 'select',
      options: locationChoices,
    },
  ];

  return recordsLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <SchoolModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Schools
          </Typography>
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button variant="contained" color="primary" onClick={() => schoolStore.setModalOpen(true)}>
              Create New School +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <SearchBar fields={fields} store={schoolStore} />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            striped
            onRowClicked={(school: School) => history.push(`/schools/${school.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default SchoolsList;
