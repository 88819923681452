import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { SessionNoteState } from '../sessionNote.store';
import { sessionNoteStore } from '../../shared/singletons';
import { Appointment } from '../../appointments/model';
import { Goal } from '../../goals/model';
import { _translateEach } from '../../shared/utils/translation.utils';
import { SessionNoteFormValue } from '../model';
import SessionNoteHistory from './SessionNoteHistory';
import DirectServiceForm from './DirectServiceForm';
import GenericTemplateForm from './GenericTemplateForm';
import SchoolPersonnelSupportForm from './SchoolPersonnelSupportForm';

interface NewSessionNoteProps {
  appointment: Appointment;
  studentId: string;
  appointmentLoading: boolean;
}

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
  link: {
    textDecoration: 'none',
  },
});

const NewSessionNote: FunctionComponent<NewSessionNoteProps> = (props) => {
  const { appointment, studentId, appointmentLoading } = props;
  const classes = useStyles();

  const { errorValues, formValues, transitionChoices, participationAttentionChoices } = useStoreObservable<
    SessionNoteState
  >(sessionNoteStore);

  React.useEffect(() => {
    sessionNoteStore.getFormValues(appointment);
  }, [appointment]);

  const sessionNoteFormValues = (goalId: string): SessionNoteFormValue => {
    return formValues?.find((session_note) => session_note.goal_id === goalId);
  };

  const handleFormValueChange = (goalId: string, key: string, value: string) => {
    const obj: SessionNoteFormValue = formValues.find((val) => val.goal_id === goalId) || {
      percent_string: '',
      assistance: '',
      prompts: '',
      content: '',
      participation: '',
      transitions: '',
      subjective_comment: '',
      who: '',
      method: '',
      outcome: '',
      goal_id: goalId,
    };

    if (key === 'prompts') {
      const currentPrompts = obj.prompts.split(',');

      if (currentPrompts.find((x) => x === value)) {
        obj[key] = currentPrompts.filter((p) => p !== value).toString();
      } else {
        obj[key] = obj[key].length > 0 ? `${obj[key]},${value}` : value;
      }
    } else {
      obj[key] = value;
    }

    sessionNoteStore.setFormValues([obj, ...formValues.filter((x) => x.goal_id !== obj.goal_id)]);
  };

  const t = _translateEach({
    back: 'sessions.formModal.back',
    add: 'sessions.formModal.add',
    history: 'sessions.formModal.history',
    disabled: 'sessions.formModal.disabled',
    create: 'sessions.formModal.create',
    createDraft: 'sessions.formModal.createDraft',
    cancel: 'sessions.formModal.cancel',
  });

  return appointmentLoading && appointment ? (
    <LoadingSpinner />
  ) : (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Link to={`/students/${appointment.student_id}/appointments/${appointment.id}`} className={classes.link}>
          <Grid alignItems="center" container spacing={1}>
            <Grid item>
              <ArrowBackIcon color="primary" style={{ display: 'inherit' }} />
            </Grid>
            <Grid item>
              <Typography color="primary" variant="body1">
                {t.back}
              </Typography>
            </Grid>
          </Grid>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          {t.add}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={10}>
          <Grid item>
            <InformationField label="Appointment" value={appointment?.schedule_date} />
          </Grid>
          <Grid item>
            <InformationField label="Service Type" value={appointment?.service_type} />
          </Grid>
          <Grid item>
            <InformationField label="Student" value={appointment?.student_name} />
          </Grid>
          <Grid item>
            <InformationField label="ID" value={appointment?.student?.slug} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h2" component="h2">
              {t.history}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SessionNoteHistory appointment={appointment} appointmentLoading={appointmentLoading} />
          </AccordionDetails>
        </Accordion>
      </Grid>
      {appointment?.goals &&
        appointment?.goals?.map((goal: Goal, index: number) => (
          <Grid item xs={12} key={goal.id}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h2" component="h2">
                  {`Service Goal ${index + 1}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {appointment.note_template === 'direct_services' && (
                  <DirectServiceForm
                    goal={goal}
                    sessionNoteFormValues={sessionNoteFormValues}
                    handleFormValueChange={handleFormValueChange}
                  />
                )}
                {appointment.note_template === 'personnel_support' && (
                  <SchoolPersonnelSupportForm
                    goal={goal}
                    sessionNoteFormValues={sessionNoteFormValues}
                    handleFormValueChange={handleFormValueChange}
                  />
                )}
                {appointment.note_template === 'generic' && (
                  <GenericTemplateForm
                    goal={goal}
                    sessionNoteFormValues={sessionNoteFormValues}
                    handleFormValueChange={handleFormValueChange}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h2" component="h2">
              Subjective
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <SelectInput
                        value=""
                        // value={sessionNoteFormValues(goal.id)?.participation}
                        styleOverrides={classes.textInput}
                        choices={participationAttentionChoices}
                        label="Participation Attention"
                        errorMessage={errorValues?.participation}
                        // valueChanged={(value: string) => handleFormValueChange(goal.id, 'participation', value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        value=""
                        // value={sessionNoteFormValues(goal.id)?.transitions}
                        styleOverrides={classes.textInput}
                        choices={transitionChoices}
                        label="Transitions"
                        errorMessage={errorValues?.transitions}
                        // valueChanged={(value: string) => handleFormValueChange(goal.id, 'transitions', value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        value=""
                        // value={sessionNoteFormValues(goal.id)?.subjective_comment}
                        styleOverrides={classes.textInput}
                        shrinkLabel
                        label="Other Subjective (Optional)"
                        multiline
                        rows={8}
                        errorMessage={errorValues?.subjective_comment}
                        // valueChanged={(value: string) =>
                        //   handleFormValueChange(goal.id, 'subjective_comment', value)
                        // }
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h2" component="h2">
              Mileage
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        value=""
                        // value={sessionNoteFormValues(goal.id)?.subjective_comment}
                        styleOverrides={classes.textInput}
                        label="Mileage"
                        errorMessage={errorValues?.subjective_comment}
                        // valueChanged={(value: string) =>
                        //   handleFormValueChange(goal.id, 'subjective_comment', value)
                        // }
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Grid alignItems="center" container spacing={1}>
          <Grid item>
            <ErrorOutlineIcon color="primary" style={{ display: 'inherit' }} />
          </Grid>
          <Grid item>
            <Typography color="primary" variant="body1">
              {t.disabled}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => sessionNoteStore.createSessionNotes(formValues, appointment.id, studentId, true)}
            >
              {t.create}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => sessionNoteStore.createSessionNotes(formValues, appointment.id, studentId, false)}
            >
              {t.createDraft}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              color="default"
              href={`/students/${appointment.student_id}/appointments/${appointment.id}`}
            >
              {t.cancel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewSessionNote;
