import React, { FunctionComponent, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../shared/components/DataTable';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Appointment } from '../model';
import AppointmentFormModal from './AppointmentFormModal';
import AppointmentRowMenu from './AppointmentRowMenu';
import RescheduleAppointmentModal from './RescheduleAppointmentModal';
import AppointmentStatusChangeModal from './AppointmentStatusChangeModal';
import { appointmentStore } from '../../shared/singletons';
import SearchBar, { FieldProps, FilterProps } from '../../shared/components/SearchBar';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../appointment.store';

interface AppointmentsListProps {
  records: Appointment[];
  recordsLoading: boolean;
  studentId?: string;
  showCreateButton?: boolean;
  showFilterBar?: boolean;
}

const AppointmentsList: FunctionComponent<AppointmentsListProps> = (props: AppointmentsListProps) => {
  const {
    records = [],
    recordsLoading = false,
    showCreateButton = false,
    studentId = null,
    showFilterBar = false,
  } = props;
  const { statusColors, statusChoices, ownerChoices, locationChoices } = useStoreObservable<AppointmentState>(
    appointmentStore,
  );
  const history = useHistory();

  const fields: FieldProps[] = [
    {
      selector: 'student_name',
      label: 'Student last Name',
      type: 'text',
    },
    {
      selector: 'service_type',
      label: 'Service Type',
      type: 'text',
    },
    {
      selector: 'status',
      label: 'Status',
      type: 'text',
      options: statusChoices,
    },
  ];

  const filters: FilterProps[] = [
    {
      selector: 'ransack[start_time_gteq]',
      label: 'Date From',
      type: 'date',
    },
    {
      selector: 'ransack[start_time_lteq]',
      label: 'Date To',
      type: 'date',
    },
    {
      selector: 'ransack[user_id_eq]',
      label: 'Owner',
      type: 'text',
      options: ownerChoices,
    },
    {
      selector: 'ransack[location_id_eq]',
      label: 'Location',
      type: 'text',
      options: locationChoices,
    },
  ];

  const columns: IDataTableColumn<Appointment>[] = [
    {
      name: 'STUDENT NAME',
      selector: 'student_name',
      sortable: true,
    },
    {
      name: 'SERVICE TYPE',
      selector: 'service_type',
      sortable: true,
    },
    {
      name: 'LOCATION',
      selector: 'location',
      sortable: true,
    },
    {
      name: 'SCHEDULED DATE',
      selector: 'formatted_schedule_date',
      sortable: true,
    },
    {
      name: 'START TIME',
      selector: 'formatted_start_time',
      sortable: true,
    },
    {
      name: 'END TIME',
      selector: 'formatted_end_time',
      sortable: true,
    },
    {
      name: 'OWNER',
      selector: 'owner',
      sortable: true,
    },
    {
      name: 'ORIGINAL DATE',
      selector: 'formatted_original_date',
      sortable: true,
    },
    {
      name: 'UNITS',
      selector: 'units',
      sortable: true,
    },
    {
      name: 'STATUS',
      selector: 'status',
      sortable: true,
      grow: 1.5,
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.status],
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      cell: (appointment) => <AppointmentRowMenu appointment={appointment} />,
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  useEffect(() => {
    if (studentId) {
      appointmentStore.setModalOpenWithStudentId(true, studentId);
    }
    appointmentStore.fetchChoices(studentId);
  }, [studentId]);

  return recordsLoading ? (
    <Box>
      <Typography variant="h1" component="h1">
        Appointments
      </Typography>
      <br />
      <LoadingSpinner />
    </Box>
  ) : (
    <Box>
      <AppointmentFormModal />
      <RescheduleAppointmentModal />
      <AppointmentStatusChangeModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Appointments
          </Typography>
        </Grid>
        <Grid item>
          {showCreateButton && (
            <Button variant="contained" color="primary" onClick={() => appointmentStore.setModalOpen(true)}>
              Create New Appointment +
            </Button>
          )}
        </Grid>
        {showFilterBar && (
          <Grid item xs={12}>
            <SearchBar fields={fields} filters={filters} store={appointmentStore} />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            columns={columns}
            noHeader
            data={records}
            striped
            onRowClicked={(row: Appointment) => history.push(`/students/${row.student_id}/appointments/${row.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AppointmentsList;
