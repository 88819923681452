import React, { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../../../config/env';
import TextInput from '../../../shared/components/form/TextInput';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { GradeLevelData } from '../model';

interface EditGradeLevelFormProps {
  gradeLevelId: number;
  formValues: Dispatch<SetStateAction<boolean>>;
  onFormValueChanged: Dispatch<Record<string, string | number>>;
}

const EditGradeLevelForm: FunctionComponent<EditGradeLevelFormProps> = (props) => {
  const { gradeLevelId, formValues, onFormValueChanged } = props;
  const [gradeLevel, setGradeLevel] = useState<GradeLevelData>();

  const handleFormValueChange = (key: string, value: string) => {
    onFormValueChanged({ ...formValues, [key]: value });
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axios(`${API_ENDPOINT}/grade_levels/${gradeLevelId}.json`);
      setGradeLevel(result.data);
    }
    fetchData();
  }, [gradeLevelId]);

  return !gradeLevel ? (
    <LoadingSpinner />
  ) : (
    <form noValidate autoComplete="off">
      <TextInput
        label="Grade Level Name"
        value={gradeLevel?.result?.name}
        valueChanged={(value: string) => handleFormValueChange('name', value)}
      />
    </form>
  );
};

export default EditGradeLevelForm;
