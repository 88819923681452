import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { userStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserState } from '../user.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const UserForm: FunctionComponent = () => {
  const classes = useStyles();

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formValue: formValues,
    formUserId,
    supervisorChoices,
  } = useStoreObservable<UserState>(userStore);

  const handleFormValueChange = (key: string, value: string) => {
    userStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    userStore.fetchSupervisors();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => userStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">User Details</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        label="Email"
        errorMessage={errorValues?.email}
        value={formValues?.email}
        valueChanged={(value: string) => handleFormValueChange('email', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Username"
        errorMessage={errorValues?.username}
        value={formValues?.username}
        valueChanged={(value: string) => handleFormValueChange('username', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="First Name"
        errorMessage={errorValues?.first_name}
        value={formValues?.first_name}
        valueChanged={(value: string) => handleFormValueChange('first_name', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Last Name"
        errorMessage={errorValues?.last_name}
        value={formValues?.last_name}
        valueChanged={(value: string) => handleFormValueChange('last_name', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Supervisor"
        errorMessage={errorValues?.supervisor}
        value={formValues?.supervisor_id}
        valueChanged={(value: string) => handleFormValueChange('supervisor_id', value)}
        choices={supervisorChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      {!formUserId && (
        <TextInput
          styleOverrides={classes.textInput}
          type="password"
          label="Password"
          errorMessage={errorValues?.password}
          value={formValues?.password}
          valueChanged={(value: string) => handleFormValueChange('password', value)}
        />
      )}
      {!formUserId && (
        <TextInput
          styleOverrides={classes.textInput}
          type="password"
          label="Password Confirmation"
          errorMessage={errorValues?.password_confirmation}
          value={formValues?.password_confirmation}
          valueChanged={(value: string) => handleFormValueChange('password_confirmation', value)}
        />
      )}
    </form>
  );
};

export default UserForm;
