import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { User, UserChoicesResponse, UserFormValues, UserFormErrors, UserResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';
import { alertStore } from '../shared/singletons';

export class UserState {
  records: User[];
  user: User;
  recordsLoading: boolean;
  userLoading: boolean;
  filteredRecords: User[];

  formValue: UserFormValues;
  formErrors: UserFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  accountEditModal: boolean;
  formUserId: string;

  supervisorChoices: SelectOption[];
  locationChoices: SelectOption[];
  roleChoices: SelectOption[];
  filterValues: Record<string, string>;

  static create(props: Partial<UserState>): UserState {
    const defaults: UserState = {
      records: [],
      user: {},
      recordsLoading: false,
      userLoading: false,
      filteredRecords: null,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      accountEditModal: false,
      formUserId: '',

      supervisorChoices: [],
      locationChoices: [],
      roleChoices: [],
      filterValues: {},
    };
    return Object.assign(new UserState(), defaults, props || {});
  }
}

export class UserStore extends BaseStore<UserState> {
  constructor() {
    super(UserState.create({}));
  }

  public fetchRecords(params = ''): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/users.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchUser(userId: string): void {
    this.setState({ userLoading: true });

    axios(`${API_ENDPOINT}/users/${userId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((user) => {
        this.setState({ user, userLoading: false });
      })
      .catch(() => {
        this.setState({ userLoading: false });
      });
  }

  public async createOrUpdateUser(formValue: UserFormValues, userId: string): Promise<void> {
    try {
      const response = await axios[userId ? 'put' : 'post']<string, UserResponse>(
        `${API_ENDPOINT}/users${userId ? `/${userId}` : ''}.json`,
        {
          user: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      this.fetchRecords(); // TODO optimize?
      this.setState({ formStepperIndex: 1, formUserId: response.data.result.id });
    } catch (error) {
      this.setState({
        formStepperIndex: 0, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateLocations(formValue: UserFormValues, userId: string): void {
    axios
      .put<string, UserResponse>(`${API_ENDPOINT}/users/${userId}.json`, {
        user: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public updateRoles(formValue: UserFormValues, userId: string): void {
    axios
      .put<string, UserResponse>(`${API_ENDPOINT}/users/${userId}.json`, {
        user: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 3 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteUser(userId: string): void {
    axios
      .delete<string, UserResponse>(`${API_ENDPOINT}/users/${userId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('users.alert.delete.error');
      });
  }

  public setFormValue(formValue: UserFormValues): void {
    this.setState({ formValue });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen });
  }

  public setEditModalOpen(isOpen: boolean, userId: string): void {
    const user = this.getState().records.find((record) => record.id === userId);

    if (user) {
      const formValue = {
        email: user.email,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        supervisor_id: user.supervisor_id,
        password: user.password,
        password_confirmation: user.password_confirmation,
        location_ids: user.location_ids,
        role_ids: user.role_ids,
      };

      this.setState({ formModalOpen: isOpen, formUserId: userId, formValue });
    }
  }

  public setUserId(studentId: string): void {
    this.setState({ formUserId: studentId });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public fetchSupervisors(): void {
    axios.get<string, UserChoicesResponse>(`${API_ENDPOINT}/users/new.json`).then((r) => {
      this.setState({ supervisorChoices: r?.data?.result?.supervisors });
    });
  }

  public fetchLocations(): void {
    axios.get<string, UserChoicesResponse>(`${API_ENDPOINT}/users/new.json`).then((r) => {
      this.setState({ locationChoices: r?.data?.result?.locations });
    });
  }

  public fetchRoles(): void {
    axios.get<string, UserChoicesResponse>(`${API_ENDPOINT}/users/new.json`).then((r) => {
      this.setState({ roleChoices: r?.data?.result?.roles });
    });
  }

  public setAccountEditModalOpen(isOpen: boolean, user: User): void {
    if (user) {
      const formValue = {
        email: user.email,
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        supervisor_id: user.supervisor_id,
        password: user.password,
        password_confirmation: user.password_confirmation,
        location_ids: user.location_ids,
        role_ids: user.role_ids,
      };

      this.setState({
        accountEditModal: isOpen,
        formUserId: user.id,
        formValue,
        user,
      });
    }
  }

  public liveSearch(field: string, value: string): void {
    this.setState({
      filteredRecords: this.getState().records.filter((record) => {
        if (typeof record[field] === 'string') {
          return record[field].toLowerCase().includes(value.toLowerCase());
        }

        if (typeof record[field] === 'object' && Array.isArray(record[field])) {
          return record[field].find((x: string) => x.toLowerCase().includes(value.toLowerCase()));
        }

        // Field not on record, return false for now
        return false;
      }),
    });
  }
}
