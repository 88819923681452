import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { appointmentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../appointment.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const AppointmentForm: FunctionComponent = () => {
  const classes = useStyles();

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formValue: formValues,
    formStudentId: studentId,
    ownerChoices,
    locationChoices,
    serviceTypeChoices,
    recurrenceChoices,
  } = useStoreObservable<AppointmentState>(appointmentStore);

  const handleFormValueChange = (key: string, value: string) => {
    appointmentStore.setFormValue({ ...formValues, [key]: value });
  };

  React.useEffect(() => {
    appointmentStore.fetchChoices(studentId);
  }, [studentId]);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => appointmentStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Service Type"
        errorMessage={errorValues?.referral_service}
        value={formValues?.referral_service_id}
        valueChanged={(value: string) => handleFormValueChange('referral_service_id', value)}
        choices={serviceTypeChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        value={formValues?.recurrence}
        styleOverrides={classes.textInput}
        type="text"
        label="Appointment Recurrence"
        errorMessage={errorValues?.recurrence}
        valueChanged={(value: string) => handleFormValueChange('recurrence', value)}
        choices={recurrenceChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <TextInput
        value={formValues?.schedule_date}
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label="Start Date"
        errorMessage={errorValues?.schedule_date}
        valueChanged={(value: string) => handleFormValueChange('schedule_date', value)}
      />
      <TextInput
        value={formValues?.end_recurrence}
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label="End Date"
        errorMessage={errorValues?.end_recurrence}
        valueChanged={(value: string) => handleFormValueChange('end_recurrence', value)}
      />
      <TextInput
        value={formValues?.start_time}
        styleOverrides={classes.textInput}
        type="time"
        shrinkLabel
        label="Start Time"
        errorMessage={errorValues?.start_hour}
        valueChanged={(value: string) => handleFormValueChange('start_time', value)}
      />
      <TextInput
        value={formValues?.end_time}
        styleOverrides={classes.textInput}
        type="time"
        shrinkLabel
        label="End Time"
        errorMessage={errorValues?.end_hour}
        valueChanged={(value: string) => handleFormValueChange('end_time', value)}
      />
      <SelectInput
        value={formValues?.location_id || locationChoices[0]?.value}
        styleOverrides={classes.textInput}
        type="text"
        label="Location"
        errorMessage={errorValues?.location_id}
        valueChanged={(value: string) => handleFormValueChange('location_id', value)}
        choices={locationChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        value={formValues?.owner_id}
        styleOverrides={classes.textInput}
        type="text"
        label="Owner"
        errorMessage={errorValues?.owner_id}
        valueChanged={(value: string) => handleFormValueChange('owner_id', value)}
        choices={ownerChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
    </form>
  );
};

export default AppointmentForm;
