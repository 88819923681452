import React, { FunctionComponent } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import InformationField from '../../shared/components/InformationField';
import SelectInput from '../../shared/components/form/SelectInput';
import { renewalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { RenewalState } from '../renewal.store';

import { _translateEach } from '../../shared/utils/translation.utils';

const useStyles = makeStyles({
  textInput: {
    height: 'auto',
    width: '98%',
    margin: '14px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const StageChangeModal: FunctionComponent = () => {
  const state = useStoreObservable<RenewalState>(renewalStore);
  const { editStageModalOpen, formValue, formErrors, formStudentId, renewal, renewalStageChoices } = state;
  const classes = useStyles();

  const t = _translateEach({
    title: 'renewals.stageChangeModal.title',
    update: 'renewals.stageChangeModal.update',
  });

  const handleFormValueChange = (key: string, value: string) => {
    renewalStore.setFormValue({ ...formValue, [key]: value });
  };

  React.useEffect(() => {
    if (formStudentId) {
      renewalStore.fetchChoices(formStudentId);
    }
  }, [formStudentId]);

  const handleFormSubmit = () => {
    renewalStore.createOrUpdateRenewal(formValue, formStudentId, renewal.id);
  };

  return (
    <ConfirmCancelModal
      isOpen={editStageModalOpen}
      title={t.title}
      onConfirm={handleFormSubmit}
      confirmLabel={t.update}
      openStatusChanged={(isOpen) => renewalStore.setRescheduleModalOpen(isOpen, renewal.id)}
    >
      <Box p={2}>
        <Grid container spacing={6}>
          <Grid item>
            <InformationField label="Student" value={renewal?.student_name} />
          </Grid>
          <Grid item>
            <InformationField label="ID" value={renewal?.student_id} />
          </Grid>
          <Grid item>
            <InformationField label="Service Type" value={renewal?.type} />
          </Grid>
        </Grid>
        <br />
        <Typography variant="h2">Renewal Details</Typography>
        <Grid container>
          <Grid item xs={10}>
            <SelectInput
              value={formValue?.renewal_stage_id}
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Stage"
              errorMessage={formErrors?.renewal_stage_id}
              valueChanged={(value: string) => handleFormValueChange('renewal_stage_id', value)}
              choices={renewalStageChoices}
            />
          </Grid>
          <br />
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default StageChangeModal;
