import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NewSessionNote from '../NewSessionNote';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { SessionNoteState } from '../../sessionNote.store';
import { sessionNoteStore } from '../../../shared/singletons';
import { SessionNoteRouteParams } from '../../model';

export type NewSessionNotePageProps = Record<string, unknown>;

const NewSessionNotePage: FunctionComponent<NewSessionNotePageProps> = () => {
  const sessionNoteState = useStoreObservable<SessionNoteState>(sessionNoteStore);
  const { appointment, appointmentLoading } = sessionNoteState;
  const { studentId, appointmentId } = useParams<SessionNoteRouteParams>();

  useEffect(() => {
    sessionNoteStore.fetchAppointment(studentId, appointmentId);
  }, [studentId, appointmentId]);

  return <NewSessionNote appointment={appointment} studentId={studentId} appointmentLoading={appointmentLoading} />;
};

export default NewSessionNotePage;
