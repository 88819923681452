import React from 'react';
import Typography from '@material-ui/core/Typography';

interface InformationFieldProps {
  label: string;
  value?: string | number | null;
}

export default function InformationField(props: InformationFieldProps) {
  const { label, value } = props;
  return (
    <div>
      <Typography variant="subtitle1" component="h6">
        {label}
      </Typography>
      <Typography variant="body1" component="p">
        {value}
      </Typography>
    </div>
  );
}
