import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { goalStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface GoalServiceFormProps {
  studentId: string;
}

const GoalServiceForm: FunctionComponent<GoalServiceFormProps> = (props) => {
  const { studentId } = props;

  const {
    formErrorAlertVisible: showErrorAlert,
    formErrors: errorValues,
    formServiceId,
    serviceChoices,
  } = useStoreObservable(goalStore);

  const classes = useStyles();

  React.useEffect(() => {
    goalStore.fetchServiceChoices(studentId);
  }, [studentId]);

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => goalStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <SelectInput
        value={formServiceId}
        styleOverrides={classes.textInput}
        label="Service ID"
        errorMessage={errorValues?.service}
        choices={serviceChoices}
        defaultChoice={{ value: '', label: 'None' }}
        valueChanged={(value: string) => goalStore.setServiceId(value)}
      />
    </form>
  );
};

export default GoalServiceForm;
