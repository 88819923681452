import React, { FunctionComponent, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import ServiceDetailsTab from './ServiceDetailsTab';
import GoalsList from '../../goals/components/GoalsList';
import AppointmentsList from '../../appointments/components/AppointmentsList';
import { Service } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../shared/contexts/userContext';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ServiceDetailProps {
  service: Service;
  serviceLoading: boolean;
  tab?: string;
}

const ServiceDetail: FunctionComponent<ServiceDetailProps> = (props) => {
  const { service, serviceLoading, tab } = props;
  const history = useHistory();
  const [value, setValue] = React.useState<number>(0);
  const { currentUserHasRole } = React.useContext(UserContext);
  const links: BreadcrumbLink[] = [
    {
      href: '/services',
      text: 'SERVICES',
    },
    {
      href: null,
      text: service.service_type,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (tab) {
      setValue(['details', 'appointments', 'goals'].indexOf(tab));
    }
  }, [tab]);

  return (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {service.service_type}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/students/${service?.student_id}/appointments/create`)}
            >
              Add Appointments
            </Button>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels value={value} onChange={handleChange} aria-label="student tabs">
          <TabLabel label="Details" other={a11yProps(0)} />
          <TabLabel label="Appointments" other={a11yProps(1)} />
          <TabLabel label="Goals" other={a11yProps(2)} />
        </TabLabels>
        <TabPanel value={value} index={0}>
          <ServiceDetailsTab service={service} serviceLoading={serviceLoading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AppointmentsList records={service?.appointments} recordsLoading={serviceLoading} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GoalsList
            goals={service?.goals}
            serviceId={service.id}
            isLoading={serviceLoading}
            showCreateButton={false}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default ServiceDetail;
