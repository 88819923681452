import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppointmentDetail from '../AppointmentDetail';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { AppointmentState } from '../../appointment.store';
import { appointmentStore } from '../../../shared/singletons';
import { AppointmentRouteParams } from '../../model';

export type AppointmentShowPageProps = Record<string, unknown>;

const AppointmentShowPage: FunctionComponent<AppointmentShowPageProps> = () => {
  const appointmentState = useStoreObservable<AppointmentState>(appointmentStore);
  const { appointment, appointmentLoading } = appointmentState;
  const { studentId, appointmentId, tab } = useParams<AppointmentRouteParams>();

  useEffect(() => {
    appointmentStore.fetchAppointment(studentId, appointmentId);
  }, [studentId, appointmentId]);

  return <AppointmentDetail appointment={appointment} tab={tab} appointmentLoading={appointmentLoading} />;
};

export default AppointmentShowPage;
