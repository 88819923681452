import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { API_ENDPOINT } from '../../config/env';
import { alertStore } from '../shared/singletons';

export class CommentState {
  createModalOpen: boolean;
  isCreating: boolean;

  static create(props: Partial<CommentState>): CommentState {
    const defaults: CommentState = {
      createModalOpen: false,
      isCreating: false,
    };
    return Object.assign(new CommentState(), defaults, props || {});
  }
}

export class CommentStore extends BaseStore<CommentState> {
  constructor() {
    super(CommentState.create({}));
  }

  public setModalOpen = (isOpen: boolean): void => this.setState({ createModalOpen: isOpen });

  public createComment(content: string, parentType: string, parentId: string): void {
    if (!content) return;

    this.setState({ isCreating: true });

    axios({
      method: 'post',
      url: `${API_ENDPOINT}/${parentType}/${parentId}/comments.json`,
      data: { comment: { content } },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        alertStore.alertSuccess('comments.alert.create.success');
        this.setState({ createModalOpen: false, isCreating: false });
      })
      .catch(() => {
        alertStore.alertSuccess('comments.alert.create.error');
        this.setState({ createModalOpen: false, isCreating: false });
      });
  }
}
