import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { API_ENDPOINT } from '../../config/env';
import { alertStore } from '../shared/singletons';
import { SessionNoteFormValue, SessionNoteErrorValue, SessionNoteResponse, SessionNoteChoicesResponse } from './model';
import { Appointment } from '../appointments/model';
import { SelectOption } from '../shared/common.model';

export class SessionNoteState {
  isCreating: boolean;
  appointment: Appointment;
  appointmentLoading: boolean;
  formValue: SessionNoteFormValue;
  formValues: SessionNoteFormValue[];
  errorValues: SessionNoteErrorValue;

  percentageChoices: SelectOption[];
  assistanceChoices: SelectOption[];
  participationAttentionChoices: SelectOption[];
  transitionChoices: SelectOption[];
  prompts: SelectOption[];
  methodChoices: SelectOption[];
  sdiChoices: SelectOption[];

  static create(props: Partial<SessionNoteState>): SessionNoteState {
    const defaults: SessionNoteState = {
      isCreating: false,
      appointment: {},
      appointmentLoading: false,
      formValue: null,
      formValues: [],
      errorValues: null,

      percentageChoices: [
        { label: '0%', value: '0%' },
        { label: '5%', value: '5%' },
        { label: '10%', value: '10%' },
        { label: '15%', value: '15%' },
        { label: '20%', value: '20%' },
        { label: '25%', value: '25%' },
        { label: '30%', value: '30%' },
        { label: '40%', value: '40%' },
        { label: '45%', value: '45%' },
        { label: '50%', value: '50%' },
        { label: '55%', value: '55%' },
        { label: '60%', value: '60%' },
        { label: '65%', value: '65%' },
        { label: '70%', value: '70%' },
        { label: '75%', value: '75%' },
        { label: '80%', value: '80%' },
        { label: '85%', value: '85%' },
        { label: '90%', value: '90%' },
        { label: '95%', value: '95%' },
        { label: '100%', value: '100%' },
        { label: 'Achieved', value: 'Achieved' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      assistanceChoices: [
        { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
        { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
        { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
        { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
        { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
        { label: 'Total Assistance (100%)', value: 'Total Assistance (100%)' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      participationAttentionChoices: [
        { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
        { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
        { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
        { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
        { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      transitionChoices: [
        { label: 'Complete Independence (0%)', value: 'Complete Independence (0%)' },
        { label: 'Modified Independence (0%)', value: 'Modified Independence (0%)' },
        { label: 'Minimal Assistance (25%)', value: 'Minimal Assistance (25%)' },
        { label: 'Moderate Assistance (50%)', value: 'Moderate Assistance (50%)' },
        { label: 'Maximum Assistance (75%)', value: 'Maximum Assistance (75%)' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      prompts: [
        { label: 'No Prompts Required', value: 'No Prompts Required' },
        { label: 'Proximity Prompt', value: 'Proximity Prompt' },
        { label: 'Visual Prompt', value: 'Visual Prompt' },
        { label: 'Gestural Prompt', value: 'Gestural Prompt' },
        { label: 'Model Prompt', value: 'Model Prompt' },
        { label: 'Verbal (Partial) Prompt', value: 'Verbal (Partial) Prompt' },
        { label: 'Verbal (Full) Prompt', value: 'Verbal (Full) Prompt' },
        { label: 'Partial Physical Prompt', value: 'Partial Physical Prompt' },
        { label: 'Full Physical Prompt', value: 'Full Physical Prompt' },
        { label: 'Not Addressed', value: 'Not Addressed' },
      ],

      methodChoices: [
        { label: 'Phone', value: 'Phone' },
        { label: 'Email', value: 'Email' },
        { label: 'In Person', value: 'In Person' },
        { label: 'Virtual Meeting', value: 'Virtual Meeting' },
        { label: 'Compliance Activity', value: 'Compliance Activity' },
      ],

      sdiChoices: [
        { label: 'Improved independence', value: 'Improved independence' },
        { label: 'Utilized given assistance', value: 'Utilized given assistance' },
        { label: 'Not targeted this session', value: 'Not targeted this session' },
      ],
    };
    return Object.assign(new SessionNoteState(), defaults, props || {});
  }
}

export class SessionNoteStore extends BaseStore<SessionNoteState> {
  constructor() {
    super(SessionNoteState.create({}));
  }

  public fetchAppointment(studentId: string, appointmentId: string): void {
    this.setState({ appointmentLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}/appointments/${appointmentId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((appointment) => {
        this.setState({ appointment, appointmentLoading: false });
      })
      .catch(() => {
        this.setState({ appointmentLoading: false });
      });
  }

  public fetchChoices(studentId: string): void {
    axios
      .get<string, SessionNoteChoicesResponse>(`${API_ENDPOINT}/patients/${studentId}/session_notes/new.json`)
      .then((r: SessionNoteChoicesResponse) => {
        const { prompts } = r.data?.result;

        this.setState({
          prompts,
        });
      });
  }

  public createSessionNote(formValue: SessionNoteFormValue, appointmentId: string, studentId: string): void {
    this.setState({ isCreating: true });

    axios
      .post<string, SessionNoteResponse>(`${API_ENDPOINT}/appointments/${appointmentId}/goal_notes.json`, {
        goal_note: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        alertStore.alertSuccess('comments.alert.create.success');
        window.location.href = `/students/${studentId}/appointments/${appointmentId}/session_notes`;
        this.setState({ isCreating: false });
      })
      .catch(() => {
        alertStore.alertSuccess('comments.alert.create.error');
        this.setState({ isCreating: false });
      });
  }

  public createSessionNotes(
    formValues: SessionNoteFormValue[],
    appointmentId: string,
    studentId: string,
    published: boolean,
  ): void {
    this.setState({ isCreating: true });

    axios
      .post<string, SessionNoteResponse>(`${API_ENDPOINT}/appointments/${appointmentId}/goal_notes.json`, {
        data: { session_notes: formValues, published: published },
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        alertStore.alertSuccess('comments.alert.create.success');
        window.location.href = `/students/${studentId}/appointments/${appointmentId}/session_notes`;
        this.setState({ isCreating: false });
      })
      .catch(() => {
        alertStore.alertSuccess('comments.alert.create.error');
        this.setState({ isCreating: false });
      });
  }

  public setFormValue(formValue: SessionNoteFormValue): void {
    this.setState({ formValue });
  }

  public setFormValues(formValues: SessionNoteFormValue[]): void {
    this.setState({ formValues });
  }

  public getFormValues(appointment: Appointment): void {
    if (appointment) {
      let formValues = [];

      appointment?.session_notes?.map((session_note) =>
        formValues.push({
          percent_string: session_note?.percent_string,
          assistance: session_note?.assistance,
          prompts: session_note?.prompts,
          content: session_note?.content,
          participation: session_note?.participation,
          transitions: session_note?.transitions,
          subjective_comment: session_note?.subjective_comment,
          goal_id: session_note?.goal_id,
        }),
      );

      this.setState({ formValues });
    }
  }
}
