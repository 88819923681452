import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import StudentDetailsTab from './StudentDetailsTab';
import GoalsList from '../../goals/components/GoalsList';
import RenewalsList from '../../renewals/components/RenewalsList';
import ServicesList from '../../services/components/ServicesList';
import AppointmentsList from '../../appointments/components/AppointmentsList';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../shared/contexts/userContext';

import { Student } from '../model';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface StudentDetailProps {
  student: Student;
  studentLoading: boolean;
  tab?: string;
}

const StudentDetail: FunctionComponent<StudentDetailProps> = (props) => {
  const { student, studentLoading, tab = null } = props;
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const { currentUserHasRole } = React.useContext(UserContext);

  const links: BreadcrumbLink[] = [
    {
      href: '/students',
      text: 'STUDENTS',
    },
    {
      href: null,
      text: `${student.first_name} ${student.last_name}`,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tab) {
      setValue(['services', 'profile', 'appointments', 'renewals', 'goals'].indexOf(tab));
    }
  }, [tab]);

  return studentLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {`${student.first_name} ${student.last_name}`}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/students/${student?.id}/services/create`)}
            >
              Create New Service
            </Button>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels value={value} onChange={handleChange} aria-label="student tabs">
          <TabLabel label="Services" other={a11yProps(0)} />
          <TabLabel label="Profile" other={a11yProps(1)} />
          <TabLabel label="Appointments" other={a11yProps(2)} />
          <TabLabel label="Renewals" other={a11yProps(3)} />
          <TabLabel label="Goals" other={a11yProps(4)} />
        </TabLabels>
        <TabPanel value={value} index={0}>
          <ServicesList records={student?.services} recordsLoading={studentLoading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StudentDetailsTab student={student} studentLoading={studentLoading} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AppointmentsList records={student?.appointments} recordsLoading={studentLoading} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RenewalsList records={student?.renewals} recordsLoading={studentLoading} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <GoalsList
            goals={student?.goals}
            studentId={student.id}
            showCreateButton={false}
            isLoading={studentLoading}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default StudentDetail;
