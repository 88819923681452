import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomMaterialMenu from '../../../shared/components/CustomMaterialMenu';

interface RoleRowMenuProps {
  row: { id?: number };
}

const RoleRowMenu: FunctionComponent<RoleRowMenuProps> = (props: RoleRowMenuProps) => {
  const { row } = props;

  return (
    <CustomMaterialMenu size="small">
      <MenuItem onClick={() => console.log(row)}>Edit Role</MenuItem>
      <MenuItem onClick={() => null}>Destroy Role</MenuItem>
    </CustomMaterialMenu>
  );
};

export default RoleRowMenu;
