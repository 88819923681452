import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { Goal, GoalFormValues, GoalFormErrors, ServiceChoicesResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';

export class GoalState {
  records: Goal[];
  goal: Goal;
  recordsLoading: boolean;
  goalLoading: boolean;

  formValue: GoalFormValues;
  formErrors: GoalFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  createModalOpen: boolean;
  formServiceId: string;

  serviceChoices: SelectOption[];

  static create(props: Partial<GoalState>): GoalState {
    const defaults: GoalState = {
      records: [],
      goal: {},
      recordsLoading: false,
      goalLoading: false,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      createModalOpen: false,
      formServiceId: undefined,

      serviceChoices: [],
    };
    return Object.assign(new GoalState(), defaults, props || {});
  }
}

export class GoalStore extends BaseStore<GoalState> {
  constructor() {
    super(GoalState.create({}));
  }

  public fetchGoal(serviceId: string, goalId: string): void {
    this.setState({ goalLoading: true });

    axios(`${API_ENDPOINT}/referral_services/${serviceId}/goals/${goalId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((goal) => {
        this.setState({ goal, goalLoading: false });
      })
      .catch(() => {
        this.setState({ goalLoading: false });
      });
  }

  public createGoal(formValue: GoalFormValues, serviceId: string): void {
    axios({
      method: 'post',
      url: `${API_ENDPOINT}/referral_services/${serviceId}/goals.json`,
      data: { goal: formValue },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        this.setState({ createModalOpen: false, formStepperIndex: 2 });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 1, // Don't progress stepper if submit errored
          formErrors: error.response.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }
  public setFormValue(formValue: GoalFormValues): void {
    this.setState({ formValue });
  }

  public setServiceId(serviceId: string): void {
    this.setState({ formServiceId: serviceId });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ createModalOpen: isOpen });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public fetchServiceChoices(patientId: string): void {
    axios.get<string, ServiceChoicesResponse>(`${API_ENDPOINT}/patients/${patientId}/goals/new.json`).then((r) => {
      console.log('r', r);
      this.setState({ serviceChoices: r?.data?.result?.service_options });
    });
  }
}
