import React, { FunctionComponent, useEffect } from 'react';
import SchoolsList from '../SchoolsList';
import { useStoreObservable } from '../../../shared/state/useStoreObservable.hook';
import { SchoolState } from '../../school.store';
import { schoolStore } from '../../../shared/singletons';

export type SchoolsListPageProps = Record<string, unknown>;

const SchoolsListPage: FunctionComponent<SchoolsListPageProps> = () => {
  const schoolState = useStoreObservable<SchoolState>(schoolStore);
  const { filteredRecords, records, recordsLoading } = schoolState;

  useEffect(() => {
    schoolStore.fetchRecords();
  }, []);

  return <SchoolsList records={filteredRecords || records} recordsLoading={recordsLoading} showFilterBar />;
};

export default SchoolsListPage;
