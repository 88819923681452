import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LayersIcon from '@material-ui/icons/Layers';
import DescriptionIcon from '@material-ui/icons/Description';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import ForumIcon from '@material-ui/icons/Forum';
import ChatIcon from '@material-ui/icons/Chat';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { serviceStore } from '../../shared/singletons';
import { Service } from '../model';
import { UserContext } from '../../shared/contexts/userContext';

interface ServiceRowMenuProps {
  service: Service;
}

const useStyles = makeStyles({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
  listItemIcon: {
    minWidth: '24px',
  },
});

const ServiceRowMenu: FunctionComponent<ServiceRowMenuProps> = (props: ServiceRowMenuProps) => {
  const { service } = props;
  const history = useHistory();
  const classes = useStyles();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const t = _translateEach({
    viewService: 'services.rowMenu.viewService',
    editService: 'services.rowMenu.editService',
    deleteService: 'services.rowMenu.deleteService',
    deleteConfirm: 'services.rowMenu.deleteConfirm',
    viewAppointments: 'services.rowMenu.viewAppointments',
    changeStage: 'services.rowMenu.changeStage',
    addGoal: 'services.rowMenu.addGoal',
    addComment: 'services.rowMenu.addComment',
    addCommunication: 'services.rowMenu.addCommunication',
    addAppointment: 'services.rowMenu.addAppointment',
    viewGoals: 'services.rowMenu.viewGoals',
  });

  const handleDestroyFormSubmit = () => {
    serviceStore.deleteService(service.id);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>

      <MenuItem onClick={() => history.push(`/students/${service.student_id}/services/${service.id}`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <LayersIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewService}</Typography>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/students/${service.student_id}/services/${service.id}/appointments`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DateRangeIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewAppointments}</Typography>
      </MenuItem>
      <MenuItem onClick={() => history.push(`/students/${service.student_id}/services/${service.id}/goals`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewGoals}</Typography>
      </MenuItem>

      <Divider />

      <Box>
        {currentUserHasRole('admin') && (
          <MenuItem onClick={() => serviceStore.setEditModalOpen(true, service.id)}>
            <ListItemIcon className={classes.listItemIcon}>
              <EditIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">{t.editService}</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => serviceStore.setRescheduleModalOpen(true, service.id)}>
          <ListItemIcon className={classes.listItemIcon}>
            <SignalCellularAltIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">{t.changeStage}</Typography>
        </MenuItem>

        {currentUserHasRole('admin') && (
          <Box>
            <Divider />
            <MenuItem onClick={() => history.push(`students/${service?.student_id}/services/${service?.id}/goals/new`)}>
              <ListItemIcon className={classes.listItemIcon}>
                <DescriptionIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addGoal}</Typography>
            </MenuItem>
            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <ChatIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addComment}</Typography>
            </MenuItem>
            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <ForumIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addCommunication}</Typography>
            </MenuItem>
            <MenuItem onClick={() => history.push(`/students/${service?.student_id}/appointments/create`)}>
              <ListItemIcon className={classes.listItemIcon}>
                <DateRangeIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addAppointment}</Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => setDestroyModalOpen(true)}>
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.deleteService}</Typography>
            </MenuItem>
          </Box>
        )}
      </Box>
    </CustomMaterialMenu>
  );
};

export default ServiceRowMenu;
