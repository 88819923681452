import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { serviceStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../service.store';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

const NewServiceForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible: showErrorAlert, formErrors: errorValues, formValue: formValues } = useStoreObservable<
    ServiceState
  >(serviceStore);

  const handleFormValueChange = (key: string, value: string) => {
    serviceStore.setFormValue({ ...formValues, [key]: value });
  };

  return (
    <form noValidate autoComplete="off">
      {errorValues?.base && (
        <ErrorAlert display={showErrorAlert} onClose={() => serviceStore.setFormErrorAlertVisibility(false)}>
          {errorValues?.base}
        </ErrorAlert>
      )}
      <TextInput
        styleOverrides={classes.textInput}
        label="Initial Referral Date"
        type="date"
        shrinkLabel
        errorMessage={errorValues?.initial_referral_date}
        value={
          formValues?.initial_referral_date ||
          `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
        }
        valueChanged={(value: string) => handleFormValueChange('initial_referral_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="Start Date"
        type="date"
        shrinkLabel
        errorMessage={errorValues?.start_date}
        value={formValues?.start_date}
        valueChanged={(value: string) => handleFormValueChange('start_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        label="End Date"
        type="date"
        shrinkLabel
        errorMessage={errorValues?.end_date}
        value={formValues?.end_date}
        valueChanged={(value: string) => handleFormValueChange('end_date', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Frequency"
        errorMessage={errorValues?.frequency}
        value={formValues?.frequency}
        valueChanged={(value: string) => handleFormValueChange('frequency', value)}
      />
    </form>
  );
};

export default NewServiceForm;
