import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Grid, Card, CardContent, Button } from '@material-ui/core';
import DataTable from '../../shared/components/DataTable';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { IDataTableColumn } from '../../shared/components/DataTable/DataTable/model';
import { Student } from '../model';
import StudentFormModal from './StudentFormModal';
import StudentRowMenu from './StudentRowMenu';
import { studentStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { StudentState } from '../student.store';
import { _translateEach } from '../../shared/utils/translation.utils';
import SearchBar, { FieldProps, FilterProps } from '../../shared/components/SearchBar';
import { UserContext } from '../../shared/contexts/userContext';

const t = _translateEach({
  createButton: 'studentList.createButton',
  title: 'studentList.title',
  studentName: 'students.columnLabel.studentName',
  id: 'students.columnLabel.id',
  dateCreated: 'students.columnLabel.dateCreated',
  serviceType: 'students.columnLabel.serviceType',
  school: 'students.columnLabel.school',
  owner: 'students.columnLabel.owner',
  createdBy: 'students.columnLabel.createdBy',
  status: 'students.columnLabel.status',
  actions: 'students.columnLabel.actions',
});

interface StudentsListProps {
  records: Student[];
  recordsLoading: boolean;
  showFilterBar?: boolean;
}

const StudentsList: FunctionComponent<StudentsListProps> = (props) => {
  const { records, recordsLoading, showFilterBar = false } = props;
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const { statusColors, schoolChoices, statusChoices, userChoices } = useStoreObservable<StudentState>(studentStore);

  React.useEffect(() => {
    studentStore.fetchChoices();
  }, []);

  const fields: FieldProps[] = [
    {
      selector: 'student_name',
      label: 'Student Name',
      type: 'text',
    },
    {
      selector: 'slug',
      label: 'Student ID',
      type: 'text',
    },
  ];

  const filters: FilterProps[] = [
    {
      selector: 'ransack[payer_id_eq]',
      label: 'School',
      type: 'select',
      options: schoolChoices,
    },
    {
      selector: 'ransack[created_by_eq]',
      label: 'Created By',
      type: 'select',
      options: userChoices,
    },
    {
      selector: 'ransack[status_eq]',
      label: 'Status',
      type: 'select',
      options: statusChoices,
    },
  ];

  const columns: IDataTableColumn<Student>[] = [
    {
      name: t.studentName,
      selector: 'student_name',
      sortable: true,
    },
    {
      name: t.id,
      selector: 'slug',
      sortable: true,
    },
    {
      name: t.dateCreated,
      selector: 'created_at',
      sortable: true,
    },
    {
      name: t.school,
      selector: 'school',
      sortable: true,
    },
    {
      name: t.createdBy,
      selector: 'created_by',
      sortable: true,
    },
    {
      name: t.status,
      selector: 'status',
      cell: (row) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            color: statusColors[row.status],
          }}
        >
          {row.status}
        </div>
      ),
      sortable: true,
    },
    {
      cell: (student) => <StudentRowMenu student={student} />,
      name: t.actions,
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];

  return recordsLoading ? (
    <LoadingSpinner />
  ) : (
    <Box>
      <StudentFormModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h1" component="h1">
            {t.title}
          </Typography>
        </Grid>
        {currentUserHasRole('admin') && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => studentStore.setModalOpen(true)}>
              {t.createButton}
            </Button>
          </Grid>
        )}
        {showFilterBar && (
          <Grid item xs={12}>
            <SearchBar fields={fields} filters={filters} store={studentStore} />
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <DataTable
            title=""
            columns={columns}
            noHeader
            data={records}
            striped
            onRowClicked={(student: Student) => history.push(`/students/${student.id}`)}
            highlightOnHover
            pointerOnHover
            pagination
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default StudentsList;
