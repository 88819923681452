import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import ServiceStudentForm from './ServiceStudentForm';
import ServiceForm from './ServiceForm';
import ServiceCategoryForm from './ServiceCategoryForm';
import ReviewServiceForm from './ReviewServiceForm';
import { serviceStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceState } from '../service.store';
import { _translateEach } from '../../shared/utils/translation.utils';

const NewServiceModal: FunctionComponent = () => {
  const state = useStoreObservable<ServiceState>(serviceStore);
  const { formStepperIndex, formModalOpen, formValue, formStudentId, formServiceId } = state;

  const t = _translateEach({
    createTitle: 'services.formModal.createTitle',
    updateTitle: 'services.formModal.updateTitle',
    cancel: 'services.formModal.cancel',
    confirmLabelStep1: 'services.formModal.confirmLabelStep1',
    confirmLabelStep2: 'services.formModal.confirmLabelStep2',
    confirmLabelStep3: 'services.formModal.confirmLabelStep3',
    confirmLabelStep4: 'services.formModal.confirmLabelStep4',
    updateLabelStep1: 'services.formModal.updateLabelStep1',
    updateLabelStep2: 'services.formModal.updateLabelStep2',
    updateLabelStep3: 'services.formModal.updateLabelStep3',
  });

  const handleNextClick = (currentIndex: number): void => {
    if (currentIndex === 0) {
      if (formStudentId) {
        serviceStore.setFormErrorAlertVisibility(false);
        serviceStore.setStepperIndex(1);
      } else {
        serviceStore.setFormErrors({ base: 'Please select a student before continuing.' });
        serviceStore.setFormErrorAlertVisibility(true);
        serviceStore.setStepperIndex(0);
      }
    } else if (currentIndex === 1) {
      serviceStore.createOrUpdateService(formValue, formStudentId, formServiceId);
    } else if (currentIndex === 2) {
      serviceStore.updateServiceDetails(formValue, formStudentId, formServiceId);
    } else {
      serviceStore.setModalOpen(false);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <ServiceStudentForm />;
      case 1:
        return <ServiceCategoryForm />;
      case 2:
        return <ServiceForm />;
      default:
        return <ReviewServiceForm />;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={4}
      isOpen={formModalOpen}
      title={formServiceId ? t.updateTitle : t.createTitle}
      openStatusChanged={(isOpen) => serviceStore.setModalOpen(isOpen)}
      buttonLabelsByIndex={[
        { cancel: 'Cancel', confirm: formServiceId ? t.updateLabelStep1 : t.confirmLabelStep1 },
        { cancel: 'Cancel', confirm: formServiceId ? t.updateLabelStep2 : t.confirmLabelStep2 },
        { cancel: 'Cancel', confirm: formServiceId ? t.updateLabelStep3 : t.confirmLabelStep3 },
        { cancel: 'Cancel', confirm: t.confirmLabelStep4 }, // TODO translation
      ]}
      previousClicked={() => serviceStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default NewServiceModal;
