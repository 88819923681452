import axios from 'axios';
import { BaseStore } from '../shared/state/base.store';
import { Service, ServiceChoicesResponse, ServiceFormValues, ServiceFormErrors, ServiceResponse } from './model';
import { API_ENDPOINT } from '../../config/env';
import { SelectOption } from '../shared/common.model';
import { alertStore } from '../shared/singletons';
import { Colors } from '../shared/style/colors';

export class ServiceState {
  records: Service[];
  service: Service;
  recordsLoading: boolean;
  serviceLoading: boolean;
  filteredRecords: Service[];

  formValue: ServiceFormValues;
  formErrors: ServiceFormErrors;
  formErrorAlertVisible: boolean;
  formStepperIndex: number;
  formModalOpen: boolean;
  editStageModalOpen: boolean;
  formServiceId: string;
  formStudentId: string;

  studentChoices: SelectOption[];
  phaseChoices: SelectOption[];
  serviceTypeChoices: SelectOption[];
  stageChoices: SelectOption[];
  ownerChoices: SelectOption[];
  frequencyChoices: SelectOption[];
  statusChoices: SelectOption[];

  statusColors: Record<string, string>;
  phaseColors: Record<string, string>;
  filterValues: Record<string, string>;

  static create(props: Partial<ServiceState>): ServiceState {
    const defaults: ServiceState = {
      records: [],
      service: {},
      recordsLoading: false,
      serviceLoading: false,
      filteredRecords: null,

      formValue: null,
      formErrors: null,
      formErrorAlertVisible: true,
      formStepperIndex: 0,
      formModalOpen: false,
      editStageModalOpen: false,
      formServiceId: '',
      formStudentId: '',

      studentChoices: [],
      phaseChoices: [],
      serviceTypeChoices: [],
      stageChoices: [],
      ownerChoices: [],
      frequencyChoices: [],
      statusChoices: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
      statusColors: {
        'On Target': Colors.Success,
      },
      phaseColors: {
        referral: Colors.PurpleThree,
        patient: Colors.Success,
        cancelled: Colors.Red,
        on_hold: Colors.Orange,
      },
      filterValues: {},
    };
    return Object.assign(new ServiceState(), defaults, props || {});
  }
}

export class ServiceStore extends BaseStore<ServiceState> {
  constructor() {
    super(ServiceState.create({}));
  }

  public fetchRecords(params = ''): void {
    this.setState({ recordsLoading: true });

    axios(`${API_ENDPOINT}/referral_services.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchService(studentId: string, serviceId: string): void {
    this.setState({ serviceLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}/referral_services/${serviceId}.json`)
      .then((result) => result?.data?.result ?? [])
      .then((service) => {
        this.setState({ service, serviceLoading: false });
      })
      .catch(() => {
        this.setState({ serviceLoading: false });
      });
  }

  public async createOrUpdateService(
    formValue: ServiceFormValues,
    studentId: string,
    serviceId: string,
  ): Promise<void> {
    try {
      const response = await axios[serviceId ? 'put' : 'post']<string, ServiceResponse>(
        `${API_ENDPOINT}/patients/${studentId}/referral_services${serviceId ? `/${serviceId}` : ''}.json`,
        {
          referral_service: formValue,
          headers: { 'Content-Type': 'application/json' },
        },
      );

      this.fetchRecords(); // TODO optimize?
      this.setState({ formStepperIndex: 2, formServiceId: response.data.result.id });
    } catch (error) {
      this.setState({
        formStepperIndex: 1, // Don't progress stepper if submit errored
        formErrors: error.response?.data,
        formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
      });
    }
  }

  public updateServiceDetails(formValue: ServiceFormValues, studentId: string, serviceId: string): void {
    axios
      .put<string, ServiceResponse>(`${API_ENDPOINT}/patients/${studentId}/referral_services/${serviceId}.json`, {
        referral_service: formValue,
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        this.fetchRecords(); // TODO optimize?
        this.setState({ formStepperIndex: 3, formServiceId: response.data.result.id });
      })
      .catch((error) => {
        this.setState({
          formStepperIndex: 2, // Don't progress stepper if submit errored
          formErrors: error.response?.data,
          formErrorAlertVisible: !!error.response?.data?.base, // The base errors are not tied to a specific field and displayed at the top of the form
        });
      });
  }

  public deleteService(serviceId: string): void {
    axios
      .delete<string, ServiceResponse>(`${API_ENDPOINT}/referral_services/${serviceId}.json`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        this.fetchRecords(); // TODO optimize?
      })
      .catch(() => {
        alertStore.alertError('services.alert.delete.error');
      });
  }

  public setFormValue(formValue: ServiceFormValues): void {
    this.setState({ formValue });
  }

  public setStepperIndex(index: number): void {
    this.setState({ formStepperIndex: index });
  }

  public setFilterValues(filterValues: Record<string, string>): void {
    this.setState({ filterValues });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public setModalOpen(isOpen: boolean): void {
    this.setState({ formModalOpen: isOpen });
  }

  public setEditModalOpen(isOpen: boolean, serviceId: string): void {
    const service = this.getState().records.find((record) => record.id === serviceId);

    if (service) {
      const formValue = {
        frequency: service.frequency,
        phase: service.phase,
        service_id: service.service_id,
        referral_stage_id: service.referral_stage_id,
        owner_id: service.owner_id,
        user_id: service.user_id,
        initial_referral_date: service.initial_referral_date,
        start_date: service.start_date,
        end_date: service.end_date,
      };

      this.setState({
        formModalOpen: isOpen,
        formServiceId: serviceId,
        formStudentId: service?.student_id,
        formValue,
      });
    }
  }

  public setStudentId(studentId: string): void {
    if (studentId) {
      this.setState({ formStudentId: studentId });
    }
  }

  public setFormErrors(formErrors: ServiceFormErrors): void {
    this.setState({ formErrors });
  }

  public setModalOpenWithStudentId(isOpen: boolean, studentId: string): void {
    this.setState({ formModalOpen: isOpen, formStudentId: studentId });
  }

  public setFormErrorAlertVisibility(isVisible: boolean): void {
    this.setState({ formErrorAlertVisible: isVisible });
  }

  public fetchStudentChoices(): void {
    axios.get<string, ServiceChoicesResponse>(`${API_ENDPOINT}/referral_services/new.json`).then((r) => {
      console.log('r', r);
      this.setState({ studentChoices: r?.data?.result?.students });
    });
  }

  public fetchChoices(): void {
    axios
      .get<string, ServiceChoicesResponse>(`${API_ENDPOINT}/referral_services/new.json`)
      .then((r: ServiceChoicesResponse) => {
        const { phase_options, service_types, stage_options, owners, frequency_options } = r.data?.result;

        this.setState({
          phaseChoices: phase_options,
          serviceTypeChoices: service_types,
          stageChoices: stage_options,
          ownerChoices: owners,
          frequencyChoices: frequency_options,
        });
      });
  }

  public setRescheduleModalOpen(isOpen: boolean, serviceId: string): void {
    const service = this.getState().records.find((record) => record.id === serviceId);

    if (service) {
      const formValue = {
        frequency: service.frequency,
        phase: service.phase,
        service_id: service.service_id,
        referral_stage_id: service.referral_stage_id,
        owner_id: service.owner_id,
        user_id: service.user_id,
        initial_referral_date: service.initial_referral_date,
        start_date: service.start_date,
        end_date: service.end_date,
      };

      this.setState({
        editStageModalOpen: isOpen,
        formServiceId: serviceId,
        formStudentId: service?.student_id,
        formValue,
        service,
      });
    }
  }

  public liveSearch(field: string, value: string): void {
    this.setState({
      filteredRecords: this.getState().records.filter((record) => {
        if (typeof record[field] === 'string') {
          return record[field].toLowerCase().includes(value.toLowerCase());
        }

        if (typeof record[field] === 'object' && Array.isArray(record[field])) {
          return record[field].find((x: string) => x.toLowerCase().includes(value.toLowerCase()));
        }

        // Field not on record, return false for now
        return false;
      }),
    });
  }
}
