import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import ChatIcon from '@material-ui/icons/Chat';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { renewalStore } from '../../shared/singletons';
import { Renewal } from '../model';
import { UserContext } from '../../shared/contexts/userContext';

interface RenewalRowMenuProps {
  renewal: Renewal;
}

const useStyles = makeStyles({
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
  listItemIcon: {
    minWidth: '24px',
  },
});

const RenewalRowMenu: FunctionComponent<RenewalRowMenuProps> = (props: RenewalRowMenuProps) => {
  const { renewal } = props;
  const history = useHistory();
  const classes = useStyles();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);

  const t = _translateEach({
    viewRenewal: 'renewals.rowMenu.viewRenewal',
    editRenewal: 'renewals.rowMenu.editRenewal',
    deleteRenewal: 'renewals.rowMenu.deleteRenewal',
    deleteConfirm: 'renewals.rowMenu.deleteConfirm',
    changeStage: 'renewals.rowMenu.changeStage',
    addComment: 'renewals.rowMenu.addComment',
  });

  const handleDestroyFormSubmit = () => {
    renewalStore.deleteRenewal(renewal.id);
  };

  return (
    <CustomMaterialMenu size="small">
      <DeleteModal
        isOpen={isDestroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={handleDestroyFormSubmit}
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          {t.deleteConfirm}
        </Typography>
      </DeleteModal>
      <MenuItem onClick={() => history.push(`/students/${renewal.student_id}/renewals/${renewal.id}`)}>
        <ListItemIcon className={classes.listItemIcon}>
          <DescriptionIcon fontSize="small" color="secondary" />
        </ListItemIcon>
        <Typography variant="inherit">{t.viewRenewal}</Typography>
      </MenuItem>

      <Divider />

      <Box>
        {currentUserHasRole('admin') && (
          <Box>
            <MenuItem onClick={() => renewalStore.setEditModalOpen(true, renewal.id)}>
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.editRenewal}</Typography>
            </MenuItem>
          </Box>
        )}
        <MenuItem onClick={() => renewalStore.setRescheduleModalOpen(true, renewal.id)}>
          <ListItemIcon className={classes.listItemIcon}>
            <SignalCellularAltIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">{t.changeStage}</Typography>
        </MenuItem>

        {currentUserHasRole('admin') && (
          <Box>
            <Divider />

            <MenuItem onClick={() => console.log('click')}>
              <ListItemIcon className={classes.listItemIcon}>
                <ChatIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.addComment}</Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={() => setDestroyModalOpen(true)}>
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">{t.deleteRenewal}</Typography>
            </MenuItem>
          </Box>
        )}
      </Box>
    </CustomMaterialMenu>
  );
};

export default RenewalRowMenu;
