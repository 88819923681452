import axios from 'axios';
import { BaseStore } from '../../shared/state/base.store';
import { GradeLevel } from './model';
import { API_ENDPOINT } from '../../../config/env';

export class GradeLevelState {
  records: GradeLevel[];

  isLoading: boolean;

  static create(props: Partial<GradeLevelState>): GradeLevelState {
    const defaults: GradeLevelState = {
      records: [],
      isLoading: false,
    };
    return Object.assign(new GradeLevelState(), defaults, props || {});
  }
}

export class GradeLevelStore extends BaseStore<GradeLevelState> {
  constructor() {
    super(GradeLevelState.create({}));
  }

  public fetchRecords(): void {
    this.setState({ isLoading: true });

    axios(`${API_ENDPOINT}/grade_levels.json`)
      .then((result) => result?.data?.result ?? [])
      .then((gradeLevels) => {
        this.setState({ records: gradeLevels, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }
}
