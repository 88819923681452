import React, { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Student } from '../model';
import { _translateEach } from '../../shared/utils/translation.utils';

interface StudentEmergencyContactsProps {
  student: Student;
  studentLoading: boolean;
}

const StudentEmergencyContacts: FunctionComponent<StudentEmergencyContactsProps> = (props) => {
  const { student, studentLoading } = props;

  const t = _translateEach({
    title: 'students.emergencyContacts.title',
    primaryGuardian: 'students.emergencyContacts.primaryGuardian',
    secondaryGuardian: 'students.emergencyContacts.secondaryGuardian',
    primaryPhone: 'students.emergencyContacts.guardianContactPrimary',
    secondaryPhone: 'students.emergencyContacts.guardianContactSecondary',
    alternatePhone: 'students.emergencyContacts.guardianContactAlternate',
    email: 'students.emergencyContacts.email',
  });

  return studentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Typography variant="h2" component="h2">
          {t.title}
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label={t.primaryGuardian} value={student?.primary_guardian} />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InformationField label={t.primaryPhone} value={student?.primary_guardian_phone_contact1} />
              </Grid>
              <Grid item xs={12}>
                <InformationField label={t.secondaryPhone} value={student?.primary_guardian_phone_contact2} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InformationField label={t.alternatePhone} value={student?.primary_guardian_phone_contact3} />
              </Grid>
              <Grid item xs={12}>
                <InformationField label={t.email} value={student?.primary_guardian_email} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InformationField label={t.secondaryGuardian} value={student?.secondary_guardian} />
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InformationField label={t.primaryPhone} value={student?.secondary_guardian_phone_contact1} />
              </Grid>
              <Grid item xs={12}>
                <InformationField label={t.secondaryPhone} value={student?.secondary_guardian_phone_contact2} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InformationField label={t.alternatePhone} value={student?.secondary_guardian_phone_contact3} />
              </Grid>
              <Grid item xs={12}>
                <InformationField label={t.email} value={student?.secondary_guardian_email} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentEmergencyContacts;
